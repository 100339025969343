import { useState, useEffect } from 'react';
import {
    Button, IconButton, Dialog, DialogActions, DialogContent, DialogContentText,
    DialogTitle, TextField, Typography, Divider
} from '@mui/material';
import Box from '@mui/material/Box';
import appVars from '../../appVars.json';
import MaterialTable from 'material-table';
import { MdPreview } from 'react-icons/md';
import { EditOutlined, FileCopy } from '@mui/icons-material';
import LandingPageToolbar from './toolbar';
import Screenshot from './screenshot';


const LandingPages = (props) => {
    const [landingPages, setLandingPages] = useState([]);
    const [open, setOpen] = useState(false);
    const [clientId] = useState(sessionStorage.getItem('cid'));


    //console.log('ClientID:', clientId);




    async function fetchData() {
        try {
            const res = await fetch(`${appVars.api_url}/lp/${clientId}`);
            const response = await res.json();
            // console.log('Response', response);
            setLandingPages(response);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);



    const handleAdd = async () => {
        const newPage = {
            pkRecID: '1',
            client_id: clientId,
            landing_url: document.getElementById('landing_url').value,
            description: document.getElementById('description').value,
            settings: "{}",
        };
        try {
            await fetch(`${appVars.api_url}/lp/add`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(newPage),
            });
            fetchData();
            handleClose();
        } catch (error) {
            console.error('Error adding page:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);


    return (
        <Box>

            <LandingPageToolbar addNew={handleOpen} />

            <Box
                sx={{
                    p: {
                        xs: 2,
                        sm: 1
                    },
                    display: 'grid',
                    gap: 2, // Adjust the spacing between grid items
                    gridTemplateColumns: {
                        xs: 'repeat(1, 1fr)', // 1 column on extra small screens
                        sm: 'repeat(2, 1fr)', // 2 columns on small screens
                        md: 'repeat(3, 1fr)', // 3 columns on medium screens
                        lg: 'repeat(4, 1fr)', // 4 columns on large screens
                        xl: 'repeat(5, 1fr)'  // 5 columns on extra-large screens
                    }
                }}
            >
                {landingPages.map((entry, index) => (
                    <Box
                        key={index}
                        sx={{
                            width: '100%', // Ensures it takes up full space within the grid cell
                            //  display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <Screenshot {...entry} fetchdata={fetchData} />
                    </Box>
                ))}
            </Box>




            {landingPages.length === 0 ? (
                <Box sx={{
                    p: {
                        xs: 2,
                        sm: 1
                    },
                }} ><Typography>No Landing Pages</Typography>
                </Box>
            ) : (
                <Box> 
                    <MaterialTable
                        style={{ display: 'none', boxShadow: 'none', border: '1px solid #ccc' }}
                        options={{ showTitle: false, search: false, paging: false }}
                        data={landingPages}
                        columns={[
                            {
                                title: "", field: "description", editable: "never", render: rowData => (
                                    <Box>
                                        <IconButton color="primary" target="_blank" href={rowData.landing_url}>
                                            <MdPreview />
                                        </IconButton>
                                        <IconButton color="primary" onClick={async () => {
                                            const duplicateData = {
                                                pkRecID: '1',
                                                client_id: clientId,
                                                landing_url: rowData.landing_url,
                                                description: `${rowData.description} (Duplicate)`,
                                                settings: JSON.stringify(rowData.settings),
                                            };
                                            try {
                                                await fetch(`${appVars.api_url}/lp/add`, {
                                                    method: 'POST',
                                                    headers: { 'Content-Type': 'application/json' },
                                                    body: JSON.stringify(duplicateData),
                                                });
                                                fetchData();
                                            } catch (error) {
                                                console.error('Error duplicating page:', error);
                                            }
                                        }}>
                                            <FileCopy />
                                        </IconButton>
                                        <IconButton color="secondary" href={`${appVars.editor}index.php/?clid=${rowData.client_id}&lpid=${rowData.pkRecID}`}>
                                            <EditOutlined />
                                        </IconButton>
                                    </Box>
                                ),
                            },
                            { title: "Name", field: "description", editable: "always" },
                            { title: "Page URL", field: "landing_url", editable: "always" },
                            { title: "Create Date", field: "createdAt", type: "datetime", editable: "never" },
                            { title: "Last Update", field: "updatedAt", type: "datetime", editable: "never" }
                        ]}
                        cellEditable={{
                            onCellEditApproved: async (newValue, oldValue, rowData, columnDef) => {
                                rowData[columnDef.field] = newValue;
                                try {
                                    await fetch(`${appVars.api_url}/lp/add`, {
                                        method: 'POST',
                                        headers: { 'Content-Type': 'application/json' },
                                        body: JSON.stringify({
                                            pkRecID: rowData.pkRecID,
                                            [columnDef.field]: newValue,
                                        }),
                                    });
                                    fetchData();
                                } catch (error) {
                                    console.error('Error updating field:', error);
                                }
                            }
                        }}
                    />
                </Box>
            )}

            {/* Add Landing Page Dialog */}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Create New Landing Page</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{
                        pt: 3
                    }}>
                        Provide a name and URL for the new landing page.
                    </DialogContentText>
                    <TextField autoFocus margin="dense" id="description" label="Page Name" type="text" fullWidth />
                    <TextField margin="dense" id="landing_url" label="Page URL" type="text" variant="outlined" fullWidth />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" variant="outlined">Cancel</Button>
                    <Button onClick={handleAdd} color="primary" variant="contained">Add New</Button>
                </DialogActions>
            </Dialog>


        </Box>
    );
};

export default LandingPages;