import React, { useState, useEffect, useRef } from 'react';
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  CardHeader,

  IconButton,
  Menu,
  MenuItem,
  Typography,
  Divider,
  Badge,
  Link,
  Button,
  Dialog,
  AppBar,
  Toolbar,
  Slide,
  Box,
  LinearProgress,
} from '@mui/material';
import { styled, useTheme } from '@mui/system';
import Grid from '@mui/material/Grid2';
import {
  MoreVert as MoreVertIcon,
  Close as CloseIcon,
  CloudDownload,
  DeleteOutline,
  Edit,
  FileCopy,
} from '@mui/icons-material';
import moment from 'moment';
import _ from 'lodash';
import { lightenHexColor } from '../../dwi-functions';
import LightBoxSettings from './LightBoxSettings';
import appVars from '../../appVars.json';
import { useToken } from '../../Login/TokenProvider';
import Countdown from 'react-countdown';
import CurrencyFormat from 'react-currency-format';
import { NumericFormat } from 'react-number-format';


// Styled Components
const StyledCard = styled(Card)({
  background: '#fff',
});

const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  color: '#fff',
  background: 'linear-gradient(90deg, rgba(74,151,220,1) 0%, rgba(0,0,0,1) 97%)',
  padding: theme.spacing(2),
}));

const StyledCardMedia = styled(CardMedia)({
  height: 255,
  backgroundPosition: 'top center',
});

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.primary.main,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(1),
  border: '1px solid #e4e4e4',
  backgroundColor: "#f7f7f7",
  textAlign: 'center'
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const Completionist = (props) => <Box borderRadius={1} border="1px solid #cfcfcf" p={2} mb={3} textAlign="center" bgcolor="#f7f7f7" ><Typography variant="body2">{props.msg}</Typography></Box>;

// Renderer callback with condition
const rendererTimerStart = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <Completionist msg={'The lightbox has started!'} />;
  } else {
    // Render a countdown
    return <Box display="flex" mt={1} mb={3} ><Box borderRadius={1} border="1px solid #cfcfcf" textAlign="center" bgcolor="#f7f7f7" width="25%" mr={1} p={1}>{(days > 0 ? days : 0)}<br></br>Day(s)</Box><Box bgcolor="#f7f7f7" textAlign="center" width="25%" borderRadius={1} border="1px solid #cfcfcf" mr={1} p={1}>{(hours > 0 ? hours : 0)} <br></br>hour(s)</Box><Box bgcolor="#f7f7f7" textAlign="center" width="25%" borderRadius={1} border="1px solid #cfcfcf" mr={1} p={1}>{(minutes > 0 ? minutes : 0)}<br></br>minute(s)</Box><Box bgcolor="#f7f7f7" width="25%" textAlign="center" borderRadius={1} border="1px solid #cfcfcf" p={1}>{(seconds > 0 ? seconds : '')}<br></br>second(s)</Box></Box>;
  }
};


// Renderer callback with condition
const rendererTimerEnd = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <Completionist msg={'The lightbox has ended!'} />;
  } else {
    // Render a countdown
    return <Box display="flex" mt={1} mb={3}  ><Box borderRadius={1} border="1px solid #cfcfcf" textAlign="center" bgcolor="#f7f7f7" width="25%" mr={1} p={1}>{(days > 0 ? days : 0)}<br></br>Day(s)</Box><Box borderRadius={1} border="1px solid #cfcfcf" bgcolor="#f7f7f7" textAlign="center" width="25%" mr={1} p={1}>{(hours > 0 ? hours : 0)} <br></br>hour(s)</Box><Box borderRadius={1} border="1px solid #cfcfcf" bgcolor="#f7f7f7" textAlign="center" width="25%" mr={1} p={1}>{(minutes > 0 ? minutes : 0)}<br></br>minute(s)</Box><Box borderRadius={1} border="1px solid #cfcfcf" bgcolor="#f7f7f7" width="25%" textAlign="center" p={1}>{(seconds > 0 ? seconds : '')}<br></br>second(s)</Box></Box>;
  }
};

const LightCard = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [stats, setStats] = useState(false);
  const [convStats, setConvStats] = useState(false);
  const [openLBE, setOpenLBE] = useState(false);
  const [timer, setTimer] = useState(props.endTime.toString());
  const theme = useTheme();
  const token = useToken();
  const admintoken = token.token;
  const intervalRef = useRef();

  useEffect(() => {
    const fetchStats = async () => {
      const a = new Date(props.startTime);
      const b = new Date(props.endTime);
      const data = {
        module: 'API',
        format: 'JSON',
        idSite: props.beaconId,
        period: 'range',
        date: `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()},${b.getFullYear()}-${b.getMonth() + 1
          }-${b.getDate()}`,
        method: 'Events.getName',
        expanded: 1,
        pivotBy: 'Events.EventAction',
        pivotByColumnLimit: 20,
        pivotByColumn: 'nb_events',
        token_auth: appVars.matomo_token,
        filter_limit: 50,
      };


      const RevenueData = {
        module: 'API',
        format: 'JSON',
        idSite: props.beaconId,
        period: 'range',
        date: `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()},${b.getFullYear()}-${b.getMonth() + 1
          }-${b.getDate()}`,
        method: 'Goals.get',
        segment: "eventAction==clicked;eventName==" + props.lightboxId,
        IdGoal: "ecommerceOrder",
        token_auth: appVars.matomo_token,
      };






      const res = await fetch(`${appVars.matomo_url}/index.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(data).toString(),
      });



      const res2 = await fetch(`${appVars.matomo_url}/index.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(RevenueData).toString(),
      });




      const result = await res.json();
      const result2 = await res2.json();
      setConvStats(result2);
   //   console.log('Results', result2);
      if (result.length > 0) {
        const k = _.find(result, (o) => o.label === props.lightboxId);

        if (k) {
          const st = {
            label: result[0].label,
            impressions: Object.values(k)[1],
            closed: Object.values(k)[2],
            clicked: Object.values(k)[3],
          };
          setStats(st);
        }
      }
    };





    fetchStats();
  }, [props]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenLBE(false);
  };

  const handleClickOpenLBE = () => {
    setOpenLBE(true);
  };

  return (
    <Grid item size={{ lg: 3, md: 4, sm: 6 }} key={props.lightboxId}>
      <StyledCard id={props.lightboxId}>
        <StyledCardHeader
          action={
            <IconButton aria-label="settings" onClick={handleClick}>
              <MoreVertIcon style={{ color: '#fff' }} />
            </IconButton>
          }
          title={props.description}
          disableTypography
        />
        <Menu
          id={props.lightboxId}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {admintoken.token >= 10 && (
            <>
              <MenuItem onClick={handleClickOpenLBE}>
                <Edit />
                Settings
              </MenuItem>
              <MenuItem>
                <FileCopy />
                Copy
              </MenuItem>
              <MenuItem>
                <CloudDownload />
                <Link
                  color="textPrimary"
                  target="_blank"
                  href={`${appVars.api_url}/generate-gtm-lightbox/${props.lightboxId}`}
                >
                  Download
                </Link>
              </MenuItem>
              <MenuItem>
                <DeleteOutline />
                Delete
              </MenuItem>
            </>
          )}
          <Divider />
          <MenuItem>
            <Link color="textPrimary" href={appVars.lightbox_url + `/a/${btoa(props.lightboxId)}`} target="_BLANK">
              Preview Lightbox
            </Link>
          </MenuItem>
        </Menu>
        <CardActionArea onClick={handleClickOpenLBE}>
          <StyledCardMedia image={props.image.imageSrc} title={props.image.imageAlt} />
        </CardActionArea>
        <CardContent>

          <Typography variant="h6" color="textPrimary" gutterBottom>Starts In</Typography>
          <Countdown date={props.startTime} renderer={rendererTimerStart}>
          </Countdown>
          <Typography variant="h6" color="textPrimary" gutterBottom>End In</Typography>
          <Countdown date={props.endTime} renderer={rendererTimerEnd}>
          </Countdown>

          {(stats ?
            <Box display="flex" gap={1} p={0} pt={2}>
              <StyledBox >
                <Typography align="center">Impressions: {stats.impressions}</Typography>
              </StyledBox>

              <StyledBox>
                <Typography align="center">Clicked: {stats.clicked}</Typography>
              </StyledBox>
            </Box>
            : '')}

          {(convStats ?
            <div>
              <Box display="flex" gap={1} p={0} pt={2}>
                <StyledBox >
                  <Typography align="center">Conversions (<NumericFormat value={convStats.nb_conversions} thousandSeparator="," displayType="text" ></NumericFormat>) </Typography><NumericFormat style={{ color: "blue" }} value={convStats.nb_visits_converted_new_visit} thousandSeparator="," displayType="text" ></NumericFormat>|<NumericFormat style={{ color: "green" }} value={convStats.nb_conversions_returning_visit} thousandSeparator="," displayType="text" ></NumericFormat>
                </StyledBox>



                <StyledBox >
                  <Typography align="center">Rate (<NumericFormat value={convStats.conversion_rate} suffix='%' fixedDecimalScale={true} decimalScale={2} displayType="text" ></NumericFormat>)</Typography><NumericFormat style={{ color: "blue" }} value={convStats.conversion_rate_new_visit} suffix='%' fixedDecimalScale={true} decimalScale={2} displayType="text" ></NumericFormat> | <NumericFormat style={{ color: "green" }} value={convStats.conversion_rate_returning_visit} suffix='%' fixedDecimalScale={true} decimalScale={2} displayType="text" ></NumericFormat>
                </StyledBox>
              </Box>
              <Box display="flex" gap={1} p={0} pt={2}>
                <StyledBox >
                  <Typography align="center">Revenue (<CurrencyFormat fixedDecimalScale={true} value={convStats.revenue} thousandSeparator="," prefix='$' decimalScale={2} displayType="text" ></CurrencyFormat>)</Typography> <CurrencyFormat style={{ color: "blue" }} fixedDecimalScale={true} value={convStats.revenue_new_visit} thousandSeparator="," prefix='$' decimalScale={2} displayType="text" ></CurrencyFormat> | <CurrencyFormat style={{ color: "green" }} fixedDecimalScale={true} value={convStats.revenue_returning_visit} thousandSeparator="," prefix='$' decimalScale={2} displayType="text" ></CurrencyFormat>
                </StyledBox>
              </Box>
            </div>

            : <LinearProgress />)}

          <Box fontSize=".7rem" textAlign="center" pt={1}>
            <span style={{ color: "blue" }}>New</span> / <span style={{ color: "green" }}>Returning</span></Box>
        </CardContent>
        <Dialog fullScreen open={openLBE} onClose={handleClose} TransitionComponent={Transition}>
          <StyledAppBar>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6">{props.description}</Typography>
            </Toolbar>
          </StyledAppBar>
          <LightBoxSettings {...props} admintoken={admintoken} />
        </Dialog>
      </StyledCard>
    </Grid>
  );
};

export default LightCard;
