// Import necessary React and Material-UI components
import { useState, useRef } from 'react';
import { styled, ThemeProvider, useTheme } from "@mui/material/styles";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";

import { CloudUpload } from '@mui/icons-material';
import appVars from "../../appVars.json"; // Import application variables (e.g., API URL)
import { lightenHexColor } from '../../dwi-functions';

// Styled components for styles previously in `makeStyles`
const Root = styled('div')`
  width: 100%;
`;

const UploadButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
}));

const StyledDialogTitle = styled(DialogTitle)(({ themeColor }) => ({
    background: themeColor.primary,
    color: themeColor.secondary,
}));

const StyledDialogContent = styled(DialogContent)(({ theme, themeColor }) => ({
    background: lightenHexColor(themeColor.primary, 100),
    margin: theme.spacing(1),
    padding: theme.spacing(3)
}));

const PreviewImageContainer = styled(Box)(({ theme, themeColor }) => ({
    background: lightenHexColor(themeColor.secondary, 90),
    border: '1px solid #c7c7c7',
    borderRadius: theme.shape.borderRadius,
    width: '50%',
    padding: theme.spacing(1),
    boxShadow: '10px 10px 24px -10px #000000'
}));

const StyledCancelButton = styled(Button)(({ themeColor }) => ({
    background: lightenHexColor(themeColor.secondary, 0),
    color: themeColor.primary,
}));

const StyledUploadButton = styled(Button)(({ themeColor }) => ({
    background: lightenHexColor(themeColor.primary, 0),
    color: themeColor.secondary,
}));

// Define the FileUploads component
const FileUploads = (props) => {
    const [imagePreviewUrl, setImagePreviewUrl] = useState(appVars.api_url + '/imageFiles/placeholder.png');
    const [selectedFile, setSelectedFile] = useState(null);
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [open, setOpen] = useState(false);
    const [admintoken] = useState(props.admintoken ? props.admintoken : 0);
    const theme = useTheme();
   // console.error('props:', props);
    // Ref for hidden file input
    const fileInputRef = useRef(null);

    const handleClose = () => {
        setOpen(false);

        setImagePreviewUrl(appVars.api_url + '/imageFiles/placeholder.png');
    };

    const handleButtonClick = () => {
        // Trigger click event on hidden file input
        //  if (fileInputRef.current) {
        setOpen(true);
        //      fileInputRef.current.click();
        //}
    };

    const changeFileHandler = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            setIsFilePicked(true);
            setImagePreviewUrl(URL.createObjectURL(file));
        }
    };

    const handleUpload = async () => {
        const formData = new FormData();
        formData.append('filesToUpload', selectedFile);
        formData.append('pkRecID', props.newid);

        try {
            const response = await fetch(appVars.api_url + "/upload", {
                method: "POST",
                mode: "no-cors",
                body: formData,
                redirect: 'follow',
            });
            const result = await response.text();

            (props.open ? props.handleClose() : handleClose())
            props.childToParent(result);
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };




    return (
        <Root>
            <ThemeProvider theme={theme}>
                {admintoken.token >= 8 && (
                    <Box >
                        {(props.showButton ?
                            <Box textAlign="center" bgcolor={"#f7f7f7"} p={2} mb={1} borderRadius={1} >
                                <UploadButton
                                    size="small"
                                    fullWidth
                                    variant="contained"
                                    color="success"
                                    onClick={handleButtonClick}
                                    startIcon={<CloudUpload />}
                                >
                                    {(props.text ? props.text : 'Upload Image')}
                                </UploadButton>
                                <Typography align='center' variant="caption">Recommended Size/Type: {props.suggest}</Typography>
                            </Box>
                            : '')}



                    </Box>
                )}
                <Dialog open={(props.open ? props.open : open)} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="md">
                    <StyledDialogTitle themeColor={props.themeColor}>Select image to upload</StyledDialogTitle>
                    <StyledDialogContent themeColor={props.themeColor}>
                        {isFilePicked ? (
                            <Box display='flex' gap={1} alignItems="center" justifyContent="center">
                                <Box width="50%" >
                                    <div>

                                        <p>Filetype: {selectedFile?.type}</p>
                                        <p>Size in bytes: {selectedFile?.size}</p>
                                        <p>LastModifiedDate: {selectedFile?.lastModifiedDate.toLocaleDateString()}</p>
                                    </div>
                                </Box>
                                <PreviewImageContainer themeColor={props.themeColor}>
                                    <img src={imagePreviewUrl} alt="Preview" style={{ maxWidth: '100%', maxHeight: '300px' }} />
                                </PreviewImageContainer>
                            </Box>
                        ) : (
                            <Box>
                                <p>Select a file to show details</p>
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: 'block' }}
                                    onChange={changeFileHandler}
                                />
                            </Box>
                        )}

                    </StyledDialogContent>
                    <DialogActions>
                        <StyledCancelButton onClick={(props.open ? props.handleClose : handleClose)} variant="outlined" themeColor={props.themeColor}>Cancel</StyledCancelButton>
                        <StyledUploadButton onClick={handleUpload} variant="contained" themeColor={props.themeColor}>Upload</StyledUploadButton>
                    </DialogActions>
                </Dialog>
            </ThemeProvider>
        </Root>
    );
};

export default FileUploads;
