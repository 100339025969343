import { useEffect, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Box, MenuItem, Button, Toolbar, ButtonGroup, TextField, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import appVars from '../../../appVars.json';
import { FcPrint } from 'react-icons/fc';
import { generatePDF } from '../../../dwi-functions';
import _ from "lodash";


const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
  background: linear-gradient(90deg, rgba(74,151,220,1) 0%, rgba(0,0,0,1) 97%);
`;

const TagsToolbar = (pageprops) => {
  const [tagProfiles, setTagProfiles] = useState(false);
  const theme = useTheme();
  const TagTypes = ({ field, form, ...props }) => {
    return (
      <TextField
        select
        variant='standard'
        size="small"
        {...field}
        {...props}
        onChange={(e) => {
          const selectedValue = e.target.value; // The full object set in MenuItem's value
          console.log('Selected', selectedValue)
          pageprops.setTag((prev) => ({
            ...prev,
            tagProfile: selectedValue.pkRecID,
            type: selectedValue.name,
            settings: JSON.parse(selectedValue.params),
          }));
          pageprops.setOpen(true);
        }}
      >
        {tagProfiles.map((entry) => (
          <MenuItem
            key={entry.pkRecID}
            value={{
              pkRecID: entry.pkRecID,
              name: entry.name,
              params: entry.params,
            }}
          >
            {entry.name}
          </MenuItem>
        ))}
      </TextField>
    );
  };

  const fetchData = async () => {
    const requestOptions = { method: "GET", redirect: "follow" };

    try {
      const res1 = await fetch(appVars.api_url + "/tag_profiles", requestOptions);
      const tagProfilesData = await res1.json();
      setTagProfiles(_.sortBy(tagProfilesData, "name"));

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };



  useEffect(() => {
    if (!tagProfiles) fetchData();

  }, [tagProfiles]);


  return (
    <div>
      <ThemeProvider theme={theme}>


        <StyledToolbar >

          <Box flex={1} >
            {tagProfiles ? (
              <Box width={'100%'} sx={{
                maxWidth: { md: 400 },
                p: 0,
                mt: 1,
                mb: 1,
                borderRadius: 1,
              }} >
                <TagTypes InputLabelProps={{ shrink: true }} fullWidth label="Add New Tag" color="primary" />
              </Box>
            ) : (
              <CircularProgress />
            )}
          </Box>


          <ButtonGroup size="small" color="secondary">

            <Button title="Print List of Tags" onClick={generatePDF}>
              <FcPrint size={30} />
            </Button>


          </ButtonGroup>
        </StyledToolbar>


      </ThemeProvider>
    </div>
  );
};

TagsToolbar.propTypes = {};

export default TagsToolbar;
