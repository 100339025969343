import { useEffect, useState } from 'react';
import { Box, LinearProgress, Card, CardActionArea, CardContent, CardHeader, CardMedia, Link, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import styled from 'styled-components';
import Skeleton from '@mui/lab/Skeleton';
import { lightenHexColor } from '../../dwi-functions';
import appVars from '../../appVars.json';
import ClientViewer from '../client-viewer';
import CurrencyFormat from 'react-currency-format';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import themeFile from '../../theme.json';
import ErrorDetail from '../../views/errors';

// Create the Material-UI theme
const theme = createTheme(themeFile);

// Styled components based on JSS styles
const ContentWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(0)};
`;

const Media = styled.img`
  height: auto;
  max-width: 100px;
  font-size: 12px;
  padding: ${({ theme }) => theme.spacing(0)};
`;





const StatBox = styled(Box)`
  border-color: #d9d9d9;
  border: 1px solid;
  border-radius: 7px;
  background-color: #d4d4d4;
  padding: ${({ theme }) => theme.spacing(1)};
`;

const PrimaryClient = (props) => {
  const [clients, setClients] = useState();



  async function fetchData() {
    try {
      const clientResponse = await fetch(appVars.api_url + '/clients');
      const clientData = await clientResponse.json();
      setClients(clientData);

    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ContentWrapper>




        <Grid container spacing={1} sx={{ padding: theme.spacing(1) }}>
          <Grid size={{  md: 9, sm: 12 }} >
            {clients ? (
              <Box boxShadow={'0px 0px 25px -15px #000000'}>
              <ClientViewer clients={clients} />
            
              </Box>
            ) : (
              <div>
                <Typography color="primary" align='center'>Loading....</Typography>
                <LinearProgress />
              </div>
            )}
          </Grid>

          <Grid size={{  md: 3, sm: 12 }}>
      
            <ErrorDetail />
            
        
          </Grid>
        </Grid>
      </ContentWrapper>
    </ThemeProvider>
  );
};

PrimaryClient.propTypes = {};

export default PrimaryClient;
