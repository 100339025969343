import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const SetSessionInfo = () => {
    const location = useLocation();
    const id = location.pathname.split("/")[2];
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            sessionStorage.setItem('cid', id);
            window.location = '/scv'; // Use an absolute path for redirection
        }
    }, [id, navigate]);

    return null; // No need to render anything if this is purely for redirection
};

export default SetSessionInfo;
