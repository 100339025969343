import { useState } from "react";
import PropTypes from 'prop-types';
import { AppBar, Box, Breadcrumbs, Link, Tabs, Tab, TextField,  Typography } from '@mui/material';
import { styled } from '@mui/system';
import appVars from '../../appVars.json';
import UserTable from '../../UserTable';
import SiteTypes from "../controls/SiteTypes";
import { useSearchParams } from "react-router-dom";


const av = Object.entries(appVars);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
    width: '100%',
    padding:7,
    color:"white"
}));

const StyledAppBar = styled(AppBar)(({ theme }) => ({
    background: 'linear-gradient(90deg, rgba(74,151,220,1) 0%, rgba(0,0,0,1) 97%)',
}));

const StyledAppBar2 = styled(AppBar)(({ theme }) => ({
    background: theme.palette.secondary.light,
    padding:0,
    margin:0
}));

const MyAppVars = () => (
    <div>
        {av.map((entry) => (
            <TextField
                key={entry[0]}
                label={entry[0]}
                type="text"
                fullWidth
                value={entry[1]}
                InputLabelProps={{ shrink: true }}
                sx={{ mb: 2 }}
            />
        ))}
    </div>
);

const ControlPanel = (props) => {
    const [searchParams] = useSearchParams();
    const [value, setValue] = useState((searchParams.get('tab') ?  parseInt( searchParams.get('tab')) : 0));
    console.log('Query Params',searchParams.get('tab'));
        

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };



  
    return (
        <Box display="flex">


            <Box spacing={0} sx={{width:'100%', m: 0 }}>
                <Box size={{ xs: 12 }}>
                    <StyledAppBar position="static"  >
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="control panel tabs"
                            indicatorColor="secondary"
                            textColor="inherit"
                            variant="standard"
                        >
                            <Tab label="Global Settings" {...a11yProps(0)} />
                            <Tab label="ECommerce Platforms" {...a11yProps(1)} />
                            <Tab label="Web Site Platforms" {...a11yProps(2)} />
                            <Tab label="Users" {...a11yProps(3)} />
                        </Tabs>
                    </StyledAppBar>
                </Box>

                <Box size={{ xs: 12 }} >
                    <TabPanel value={value} index={0}>
                            <StyledAppBar2 position="static">
                            
                                    <StyledBreadcrumbs aria-label="breadcrumb">
                                        <Link color="inherit" href="/">Dashboard</Link>
                                        <Link color="inherit" href="/control/">Settings</Link>
                                        <Typography color="textPrimary" variant="subtitle1">Global Settings</Typography>
                                    </StyledBreadcrumbs>
                            
                            </StyledAppBar2>
                         <Box sx={{ p: 2 }}>
                                <MyAppVars />

                        </Box>
                    </TabPanel>

                    <TabPanel value={value} index={1}>
                        <StyledAppBar2 position="static">
                         
                                <StyledBreadcrumbs aria-label="breadcrumb">
                                    <Link color="inherit" href="/">Dashboard</Link>
                                    <Link color="inherit" href="/control/">Settings</Link>
                                    <Typography color="textPrimary" variant="subtitle1">eCommerce Platforms</Typography>
                                </StyledBreadcrumbs>
                           
                        </StyledAppBar2>
                            <SiteTypes type="ecommerce" />
                        
                    </TabPanel>

                    <TabPanel value={value} index={2}>
                        <StyledAppBar2 position="static">
                           
                                <StyledBreadcrumbs aria-label="breadcrumb">
                                    <Link color="inherit" href="/">Dashboard</Link>
                                    <Link color="inherit" href="/control/">Settings</Link>
                                    <Typography color="textPrimary" variant="subtitle1">Website Platforms</Typography>
                                </StyledBreadcrumbs>
                        
                        </StyledAppBar2>
                        <Box sx={{ p: 2 }}>
                            <SiteTypes />
                        </Box>
                    </TabPanel>

                    <TabPanel value={value} index={3}>
                        <UserTable />
                    </TabPanel>
                </Box>
            </Box>
        </Box>
    );
};

ControlPanel.propTypes = {};

export default ControlPanel;
