import React, { useState } from "react";
import { Box, Slider, TextField, Typography, Button, Paper, MenuItem, Select, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, InputBase, IconButton, InputAdornment } from "@mui/material";
import { Field } from "formik";
import appVars from "../../../appVars.json";
import { Delete, Image, Save } from '@material-ui/icons';
import { OpenInBrowserRounded, Settings, UploadFile, UploadFileOutlined } from "@mui/icons-material";


const ImageOverlayControl = (props) => {
  // States for primary and secondary background images, overlay positions, and sizes
  const [bgImageUrl, setBgImageUrl] = useState(props.imageData.desktopImage.imageUrl);
  const [closeButton, setCloseButton] = useState(props.imageData.desktopImage.closeImage);
  const [secondaryBgImageUrl, setMobileBgImageUrl] = useState(props.imageData.mobileImage.imageUrl);
  const [imageDimensions, setImageDimensions] = useState({ width: 400, height: 400 }); // Default image dimensions
  const [secondaryImageDimensions, setMobileImageDimensions] = useState({ width: 400, height: 400 }); // Mobile image dimensions
  const [position, setPosition] = useState({ x: props.imageData.desktopImage.close.position.x, y: props.imageData.desktopImage.close.position.y }); // Default position at 15px from top and right
  const [secondaryPosition, setMobilePosition] = useState({ x: props.imageData.mobileImage.close.position.x, y: props.imageData.mobileImage.close.position.y });
  const [size, setSize] = useState({ width: props.imageData.desktopImage.close.size.width, height: props.imageData.desktopImage.close.size.height });
  const [secondarySize, setMobileSize] = useState({ width: props.imageData.desktopImage.close.size.width, height: props.imageData.desktopImage.close.size.height });
  const [finalValues, setFinalValues] = useState({});
  const [selectedImage, setSelectedImage] = useState("default");
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openM, setOpenM] = React.useState(false);
  const [openDDSettings, setOpenDDSettings] = React.useState(false);
  const [openMDSettings, setOpenMDSettings] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickOpenM = () => {
    setOpenM(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenM(false);
    setOpenDDSettings(false)
    setOpenMDSettings(false);
    setIsFilePicked(false);
    setSelectedFile("")
  };
  const changeFileHandler = (event) => {

    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);

  };


  const MyInput = ({ field, form, ...props }) => {
    return <TextField style={{ marginBottom: 25 }} fullWidth size='small' {...field} {...props} />;
  };

  //console.log("imageDetails", props.imageData);



  const predefinedImages = [
    { label: "X with Circle - Black", value: "https://api.digitalwerksautomation.com/lightbox/close-buttons/a4a8b707-877c-4206-a4de-de93131a0c6f.png", url: "https://api.digitalwerksautomation.com/lightbox/close-buttons/a4a8b707-877c-4206-a4de-de93131a0c6f.png" },
    { label: "X with Circle - White", value: "https://api.digitalwerksautomation.com/lightbox/close-buttons/6124fd35-4676-4df2-9758-7ca4ebc2b583.png", url: "https://api.digitalwerksautomation.com/lightbox/close-buttons/6124fd35-4676-4df2-9758-7ca4ebc2b583.png" },
  ];

  // Handle slider changes for position
  const handlePositionChange = (axis, value, isMobile = false) => {
    if (isMobile) {
      setMobilePosition((prev) => {
        const maxPosition =
          axis === "x"
            ? secondaryImageDimensions.width - secondarySize.width
            : secondaryImageDimensions.height - secondarySize.height;
        return { ...prev, [axis]: Math.min(Math.max(value, 0), maxPosition) };
      });
    } else {
      setPosition((prev) => {
        const maxPosition =
          axis === "x"
            ? imageDimensions.width - size.width
            : imageDimensions.height - size.height;
        return { ...prev, [axis]: Math.min(Math.max(value, 0), maxPosition) };
      });
    }
  };

  // Handle numeric input changes for size
  const handleSizeChange = (dim, value, isMobile = false) => {
    if (isMobile) {
      setMobileSize((prev) => ({ ...prev, [dim]: parseInt(value) || 0 }));
    } else {
      setSize((prev) => ({ ...prev, [dim]: parseInt(value) || 0 }));
    }
  };

  // Handle image load to get dimensions
  const handleImageLoad = (e, isMobile = false) => {
    const { naturalWidth, naturalHeight } = e.target;
    if (isMobile) {
      setMobileImageDimensions({ width: (naturalWidth > props.maxWidth ? props.maxWidth : naturalWidth), height: (naturalWidth > props.maxWidth ? 'auto' : naturalHeight) });
    } else {
      setImageDimensions({ width: (naturalWidth >= props.maxWidth ? props.maxWidth : naturalWidth), height: (naturalWidth > props.maxWidth ? 'auto' : naturalHeight) });
    }
  };

  // Save current values to JSON
  const handleSave = () => {
    const jsonOutput = {
      desktopImage: {
        imageUrl: bgImageUrl,
        altText: imageAlt.desktop,
        closeImage: closeButton,
        close: {
          position: { x: position.x, y: position.y },
          size: { width: size.width, height: size.height },
        },
      },
      mobileImage: {
        imageUrl: secondaryBgImageUrl,
        altText: imageAlt.mobile,
        close: {
          position: { x: secondaryPosition.x, y: secondaryPosition.y },
          size: { width: secondarySize.width, height: secondarySize.height },
        },
      },
    };
    setFinalValues(jsonOutput);
    props.updateImages(jsonOutput);

    handleClose();


    //    console.log("Saved Values:", jsonOutput);
  };


  const [imageAlt, setImageAlt] = useState({
    desktop: props.imageData.desktopImage.altText,
    mobile: props.imageData.mobileImage.altText
  });



  // Handle image selection change
  const handleImageSelectionChange = (event) => {
    const selected = predefinedImages.find((img) => img.value === event.target.value);
    setCloseButton(event.target.value);
    //setBgImageUrl(selected?.url || "");
  };

  return (

    <Box>
      {/* Desktop Section */}
      <Box mb={5} display="flex" flexDirection="row" gap={2}>
       
        <Box p={3} width="50%" bgcolor="#c7c7c7" borderRadius={2}>

          <Box display="flex" alignItems="flex-start" gap={2}>

            <Box>
              <Typography gutterBottom>Y-Axis</Typography>
              <Slider
                orientation="vertical"
                value={imageDimensions.height - position.y - size.height}
                min={0}
                max={imageDimensions.height - size.height}
                onChange={(_, value) => handlePositionChange("y", imageDimensions.height - value - size.height)}
                sx={{ height: `${imageDimensions.height}px` }}
              />
            </Box>
            <Paper
              elevation={3}
              sx={{
                position: "relative",
                width: `${imageDimensions.width}px`,
                height: `${imageDimensions.height}px`,
                backgroundImage: `url(${bgImageUrl})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                border: "1px solid #ccc",
                overflow: "hidden",
              }}
            >
              {bgImageUrl && (
                <img
                  src={bgImageUrl}
                  alt="hidden"
                  style={{ display: "none" }}
                  onLoad={(e) => handleImageLoad(e)}
                />
              )}
              <Box
                sx={{
                  position: "absolute",
                  top: `${position.y}px`,
                  left: `${position.x}px`,
                  width: `${size.width}px`,
                  height: `${size.height}px`,
                  backgroundImage: `url(${closeButton})`,
                  backgroundSize: "cover",
                }}
              />
            </Paper>
            <Box display='flex' flexDirection="column" >
              <Button sx={{ mb: 2 }} startIcon={<UploadFileOutlined color="primary" />} variant="outlined" onClick={handleClickOpen}>
                Image
              </Button>
              <Button startIcon={<Settings />} variant="outlined" onClick={() => setOpenDDSettings(true)}>
                Settings
              </Button>

            </Box>
          </Box>
          <Typography gutterBottom>X-Axis</Typography>
          <Slider
            value={position.x}
            color="secondary"
            min={0}
            max={imageDimensions.width - size.width}
            onChange={(_, value) => handlePositionChange("x", value)}
          />
        </Box>

        <Box p={3} width="50%" bgcolor="#c7c7c7" borderRadius={2}>
          <Box display="flex" alignItems="flex-start" gap={2}>



            <Box>
              <Typography gutterBottom>Y-Axis</Typography>
              <Slider
                orientation="vertical"
                value={secondaryImageDimensions.height - secondaryPosition.y - secondarySize.height}
                min={0}
                max={secondaryImageDimensions.height - secondarySize.height}
                onChange={(_, value) =>
                  handlePositionChange("y", secondaryImageDimensions.height - value - secondarySize.height, true)
                }
                sx={{ height: `${secondaryImageDimensions.height}px` }}
              />
            </Box>
            <Paper
              elevation={3}
              sx={{
                position: "relative",
                width: `${secondaryImageDimensions.width}px`,
                height: `${secondaryImageDimensions.height}px`,
                backgroundImage: `url(${secondaryBgImageUrl})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                border: "1px solid #ccc",
                overflow: "hidden",
              }}
            >
              {secondaryBgImageUrl && (
                <img
                  src={secondaryBgImageUrl}
                  alt="hidden"
                  style={{ display: "none" }}
                  onLoad={(e) => handleImageLoad(e, true)}
                />
              )}
              <Box
                sx={{
                  position: "absolute",
                  top: `${secondaryPosition.y}px`,
                  left: `${secondaryPosition.x}px`,
                  width: `${secondarySize.width}px`,
                  height: `${secondarySize.height}px`,
                  backgroundImage: `url(${closeButton})`,
                  backgroundSize: "cover",
                }}
              />
            </Paper>
            <Box display='flex' flexDirection="column" >
              <Button sx={{ mb: 2 }} startIcon={<UploadFileOutlined color="primary" />} variant="outlined" onClick={handleClickOpenM}>
                Image
              </Button>
              <Button startIcon={<Settings />} variant="outlined" onClick={() => setOpenMDSettings(true)}>
                Settings
              </Button>

            </Box>
          </Box>
          <Typography gutterBottom>X-Axis</Typography>
          <Slider
            value={secondaryPosition.x}
            color="secondary"
            min={0}
            max={secondaryImageDimensions.width - secondarySize.width}
            onChange={(_, value) => handlePositionChange("x", value, true)}
          />
        </Box>
      </Box>




      <form noValidate autoComplete="off" action={appVars.api_url + '/upload'} method="POST">
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Image Uploader</DialogTitle>
          <DialogContent >
            <DialogContentText sx={{ pt: 3 }}>
              <input component={MyInput} id="fileToUpload" type="file" onChange={changeFileHandler} color="secondary" />
              {isFilePicked ? (
                <Box display="flex" >
                  <Box>
                    <Typography sx={{ mt: 2 }} gutterBottom>Filename: {selectedFile.name}</Typography>
                    <Typography sx={{ mt: 2 }} gutterBottom>Filetype: {selectedFile.type}</Typography>
                    <Typography sx={{ mt: 2 }} gutterBottom>Size in bytes: {parseFloat(selectedFile.size / (1024 * 1024)).toFixed(2)} MB</Typography>
                    <Typography sx={{ mt: 2 }} gutterBottom>
                      Modified Date:{' '}
                      {selectedFile.lastModifiedDate.toLocaleDateString()}
                    </Typography>
                  </Box>
                  <Box>
                    <img src={appVars.api_url + "/imageFiles/" + selectedFile.name} alt="" />
                  </Box>
                </Box>
              ) : (
                <Typography sx={{ mt: 2 }} gutterBottom>Select a file to show details</Typography>
              )}
            </DialogContentText>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary"
              onClick={async (e) => {
                const formData = new FormData();

                formData.append('filesToUpload', selectedFile);
                formData.append('pkRecID', props.lightboxId);

                const res = await fetch(appVars.api_url + "/upload", {
                  method: "POST",
                  mode: "no-cors",
                  body: formData,
                  headers: {
                    'Content-Type': 'application/json',
                  },
                });

                setBgImageUrl(appVars.api_url + "/imageFiles/" + props.lightboxId + '.' + selectedFile.name.split('.')[1]);
                handleClose();
              }}
            >
              Upload
            </Button>
          </DialogActions>
        </Dialog>
      </form>

      {/* Mobile Upload */}
      <form noValidate autoComplete="off" action={appVars.api_url + '/upload'} method="POST">
        <Dialog open={openM} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Mobile Image Uploader</DialogTitle>
          <DialogContent >
            <DialogContentText sx={{ pt: 3 }}>
              <input component={MyInput} id="fileToUpload" type="file" onChange={changeFileHandler} color="secondary" />
              {isFilePicked ? (

                <Box display="flex" >
                  <Box width="50%">
                    <Typography sx={{ mt: 2 }} gutterBottom>Filename: {selectedFile.name}</Typography>
                    <Typography sx={{ mt: 2 }} gutterBottom>Filetype: {selectedFile.type}</Typography>
                    <Typography sx={{ mt: 2 }} gutterBottom>Size in bytes: {selectedFile.size}</Typography>
                    <Typography sx={{ mt: 2 }} gutterBottom>
                      lastModifiedDate:{' '}
                      {selectedFile.lastModifiedDate.toLocaleDateString()}
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <Typography sx={{ mt: 2 }} gutterBottom>Select a file to show details</Typography>
              )}
            </DialogContentText>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary"
              onClick={async (e) => {
                const formData = new FormData();

                formData.append('filesToUpload', selectedFile);
                formData.append('pkRecID', props.lightboxId + "-m");

                const res = await fetch(appVars.api_url + "/upload", {
                  method: "POST",
                  mode: "no-cors",
                  body: formData,
                  headers: {
                    'Content-Type': 'application/json',
                  },
                });

                setMobileBgImageUrl(appVars.api_url + "/imageFiles/" + props.lightboxId + '-m.' + selectedFile.name.split('.')[1]);
                handleClose();
              }}
            >
              Upload
            </Button>
          </DialogActions>

        </Dialog>

        <Dialog open={openMDSettings} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Mobile Image Settings</DialogTitle>
          <DialogContent >
            <DialogContentText sx={{ pt: 3 }}>

              <Box p={2} width="100%" bgcolor="#f7f7f7" borderRadius={2} border="1px solid #c7c7c7">
                <Typography variant="h6">Enter Mobile Background Image URL:</Typography>
                <Box gap={2} >
                  <Field
                    slotProps={{
                      input: {
                        startAdornment: (
                          <InputAdornment onClick={handleClickOpenM} position="start">
                            <UploadFile color="primary" />
                          </InputAdornment>
                        ),
                      },
                    }}
                    fullWidth
                    variant="outlined"
                    placeholder="Enter Mobile Image URL"
                    value={secondaryBgImageUrl}
                    onChange={(e) => setMobileBgImageUrl(e.target.value)}
                    sx={{ mb: 2 }}
                    component={MyInput}
                  />
                  <Field
                    type="text"
                    label="Mobile Image ALT Text"
                    variant="outlined"
                    component={MyInput}
                    onChange={(e) => {
                      setImageAlt({ mobile: e.target.value, desktop: document.getElementById("imgalt") });
                    }}
                    name="imgaltM"
                    id="imgaltM"
                    value={imageAlt.mobile}
                  />
                </Box>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Close Button Size:
                </Typography>
                <Box display="flex" gap={2}>
                  <TextField
                    label="Width (Mobile)"
                    type="number"
                    value={secondarySize.width}
                    onChange={(e) => handleSizeChange("width", e.target.value, true)}
                  />
                  <TextField
                    label="Height (Mobile)"
                    type="number"
                    value={secondarySize.height}
                    onChange={(e) => handleSizeChange("height", e.target.value, true)}
                  />
                </Box>
              </Box>
              <Box mt={3}>
                <Button variant="contained" color="primary" onClick={handleSave}>
                  Save Settings
                </Button>
              </Box>
            </DialogContentText>
          </DialogContent>
        </Dialog>







        <Dialog open={openDDSettings} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Desktop Image Settings</DialogTitle>
          <DialogContent >
            <DialogContentText sx={{ pt: 3 }}>
              <Box p={2} width="100%" bgcolor="#f7f7f7" borderRadius={2} border="1px solid #c7c7c7">
                <Typography variant="h6">Enter Custom Background Image URL:</Typography>
                <Box gap={2} display="flex">
                  <Field
                    slotProps={{
                      input: {
                        startAdornment: (
                          <InputAdornment onClick={handleClickOpen} position="start">
                            <UploadFile color="primary" />
                          </InputAdornment>
                        ),
                      },
                    }}
                    fullWidth
                    variant="outlined"
                    placeholder="Enter Image URL"
                    value={bgImageUrl}
                    onChange={(e) => setBgImageUrl(e.target.value)}
                    sx={{ mb: 2 }}
                    component={MyInput}
                  />
                  <Field
                    type="text"
                    label="Desktop Image ALT Text"
                    variant="outlined"
                    component={MyInput}
                    onChange={(e) => {
                      setImageAlt({ desktop: e.target.value, mobile: document.getElementById("imgaltM") });
                    }}
                    name="imgalt"
                    id="imgalt"
                    value={imageAlt.desktop}
                  />
                </Box>
                <Select fullWidth value={selectedImage} onChange={handleImageSelectionChange} sx={{ mb: 2 }}>
                  {predefinedImages.map((img) => (
                    <MenuItem selected={closeButton} key={img.value} value={img.value}>
                      {img.label}
                    </MenuItem>
                  ))}
                </Select>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Close Button Size:
                </Typography>
                <Box display="flex" gap={2}>
                  <TextField
                    label="Width (Desktop)"
                    type="number"
                    value={size.width}
                    onChange={(e) => handleSizeChange("width", e.target.value)}
                  />
                  <TextField
                    label="Height (Desktop)"
                    type="number"
                    value={size.height}
                    onChange={(e) => handleSizeChange("height", e.target.value)}
                  />
                </Box>
              </Box>
              <Box mt={3}>
                <Button variant="contained" color="primary" onClick={handleSave}>
                  Save Settings
                </Button>
              </Box>
            </DialogContentText>
          </DialogContent>
        </Dialog>

      </form>
    </Box>

  );
};

export default ImageOverlayControl;
