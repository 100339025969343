import { useState, useEffect } from 'react';
import appVars from '../../../appVars.json';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField, LinearProgress, Box, ListItemIcon, ListItemText, Avatar, createTheme,Typography } from '@mui/material';


const ClientSearch = (props) => {
    const [domains, setDomains] = useState([]); // Initialized as an empty array
    const theme = createTheme();

    async function fetchData() {
        try {
            const res = await fetch(`${appVars.api_url}/tagmanagers`);
            const data = await res.json();
            setDomains(data); // Ensure state is updated
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div>
            {domains.length > 0 ? (
                <Autocomplete
                    id="client-id"
                    onChange={(e, newValue) => {
                        if (newValue) {
                            window.location = `/ss/${newValue.id}`;
                        }
                    }}
                    renderGroup={(params) => (
                        <div key={params.key}>
                          <div style={{ color:"#ffffff" , backgroundColor: theme.palette.primary.dark, padding: '8px' }}>{params.group}</div>
                          {params.children}
                        </div>
                      )}
                    style={{ width: 500 }}
                    groupBy={(option) => option.clientname || 'Unknown'} // Ensure grouping key
                    options={domains.sort((a, b) => -b.clientname?.localeCompare(a.clientname || ''))}
                    getOptionLabel={(option) => `${option.name} ${option.siteUrl}`}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            color="secondary"
                            label="Domains Search"
                            size="small"
                            fullWidth
                            variant="outlined"
                        />
                    )}
                    renderOption={(props, option) => (
                        <Box {...props} sx={{ display: 'flex' }}>
                            <ListItemIcon>
                                <Avatar
                                       sx={{ background:'lightgrey',padding:1 , width: '100%', marginRight:2 }}
                                    variant="rounded"
                                    alt={option.name}
                                    src={`${appVars.api_url}/imageFiles/${option.id}.png`}
                                />
                            </ListItemIcon>
                            <ListItemText><Typography marginLeft={2} variant="body2">{option.name}</Typography></ListItemText>
                        </Box>
                    )}
                    {...props}
                />
            ) : (
                <LinearProgress />
            )}


        </div>
    );
};

export default ClientSearch;
