import styled, { ThemeProvider } from 'styled-components';

import { useState, useEffect } from 'react';
import { Typography, Button, AppBar, Toolbar, ButtonGroup, Box, Avatar, ListItemIcon, ListItemText, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Badge, Snackbar, Alert, Breadcrumbs, Link } from '@mui/material';
import Grid from '@mui/material/Grid2';
import MaterialTable from 'material-table';
import { FcBullish, FcSearch, FcGoogle, FcTemplate, FcDocument, FcCurrencyExchange } from "react-icons/fc";
import { SiIbeacon } from "react-icons/si";
import { SaveOutlined, CancelOutlined } from "@material-ui/icons";
import { TbApi } from "react-icons/tb";
import { MdOpenInNew, MdError } from "react-icons/md";
import { useTheme } from '@mui/material/styles';
import appVars from '../../appVars.json';
import FileUploads from '../../system/file-uploads';
import { NumericFormat } from 'react-number-format';
import { useToken } from '../../Login/TokenProvider';
import { lightenHexColor } from '../../dwi-functions';
import _ from "lodash";
import ClipboardJS from 'clipboard';



const StyledToolbar = styled(Toolbar)`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  background: linear-gradient(90deg,rgba(0,0,0,1) 0%, rgba(255,87,34,1) 97% );
  border: none;
`;

const StyledButtonGroup = styled(ButtonGroup)`
  margin-left: auto;
  background: lightgray;
`;



const SaveButton = styled(Button)`
  color: white !important;
  background: limegreen !important;
`;

const CancelButton = styled(Button)`
  color: white !important;
  background: red !important;
  margin-right: ${(props) => props.spacing} !important;
`;

const StyledButton = styled(Button)`
 margin-right: ${(props) => props.spacing} !important;
`;

const ClientToolbar = ({ field, form, ...props }) => {
  const [copySuccessShow, setCopySuccessShow] = useState(false);
  const [copySuccess, setCopySuccess] = useState("");
  const [uploadOpen, setUploadOpen] = useState(false);
  const [errors, setErrorLog] = useState([]);
  const [errorCounts, setErrorCounts] = useState();
  const [openErrors, setOpenErrors] = useState();
  const [openAPI, setOpenAPIS] = useState();
  const token = useToken();
  const admintoken = token.token;
  const theme = useTheme();
  const _d = new Date();
  _d.setDate(_d.getDate() - 7);

  //console.log("Client Toolbar Properties", props);

  const updateLogoFile = (childdata) => {
    var f = childdata;
    const img = document.getElementById('logoFile');
    img.src = f;
    // console.log(f);
  };

  var clipboard = new ClipboardJS("#gtmcopy, #fbcopy,#copy-script,#copy-event");
  clipboard.on("success", function (e) {
    setCopySuccess(e.action + ": " + e.text);
    setCopySuccessShow(true);
    e.clearSelection();
  });

  async function fetchData() {
    try {

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");


      const [errorsRes] = await Promise.all([
        fetch(`${appVars.api_url}/client/errors/`, { method: "POST", headers: myHeaders, body: JSON.stringify({ "clientid": props.client.id }), redirect: "follow" })
      ]);

      const [errorsData] = await Promise.all([
        errorsRes.json()
      ]);



      setErrorLog(errorsData);

      const groupedAndCounted = _(errorsData)
        .countBy('error_message') // Count occurrences of each error message
        .map((count, message) => ({ message, count })) // Convert to an array of objects
        .value();

      // console.log(groupedAndCounted);

      setErrorCounts(groupedAndCounted);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);









  const handleClose = () => {
    setUploadOpen(false);
    setOpenErrors(false);
    setCopySuccessShow(false);
  }

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Grid item sm={12}>
          <Box
            sx={{
              background: 'linear-gradient(90deg, rgba(74,151,220,1) 0%, rgba(0,0,0,1) 97%)',
              pl:1
            }}

          >
            <Breadcrumbs style={{ flex: 1, color: "#fff", width: '100%', padding: theme.spacing(.5) }} aria-label="breadcrumb">
              <Link color="#ffffff" href="/">Dashboard</Link>
              <Link color="#ffffff" href={`/agency/${props.client.client}`}>{props.client.clientname}</Link>
              <Typography style={{ flex: 1 }} color="primary">{props.client.name}</Typography>

            </Breadcrumbs>
          </Box>

          <StyledToolbar disableGutters>

            <Box {...props} sx={{ display: 'flex' }} marginLeft={2}>
              <ListItemIcon>
                <Avatar
                  id="logoFile"
                  sx={{ background: 'lightgrey', padding: 1, width: '100%', marginRight: 2, boxShadow: '10px 10px 24px -10px #000000' }}
                  variant="rounded"
                  alt={props.client.name}
                  src={`${appVars.api_url}/imageFiles/${props.client.id}.png`}
                  onClick={() => {
                    setUploadOpen(true);
                  }}
                />
              </ListItemIcon>
              <ListItemText><Typography sx={{ textShadow: '4px 2px 2px #000000' }} marginLeft={2} variant='h6' color='white'>{props.client.name}</Typography></ListItemText>
            </Box>

            <StyledButtonGroup size="small" variant="text" color="secondary">

              <StyledButton title="Open Client Site" href={`${props.client.siteUrl}`} target="_BLANK">
                <MdOpenInNew size={30} />
              </StyledButton>
              <StyledButton title="Open Platform" href={`${props.client.donationUrl}`} target="_BLANK">
                <FcCurrencyExchange size={30} />
              </StyledButton>
              <StyledButton title="Client Link" href={`https://beacon.digitalwerksautomation.com/index.php?module=CoreHome&action=index&idSite=${props.client.beaconId}&category=General_Visitors&subcategory=Live_VisitorLog&period=month&date=${new Date().toISOString().slice(0, 7).replace('-', '-01-')}`} target="_BLANK">
                <SiIbeacon size={30} />
              </StyledButton>
              <StyledButton id="gtmcopy" title={`Download Google Tag Manager Install Instructions for ${props.client.name}`} data-clipboard-text={`${appVars.platformsUrl}/gtm-install/${props.client.id}`}>
                <FcDocument size={30} />
              </StyledButton>
              <StyledButton href={`${appVars.api_url}/generate-ecom-settings/${props.client.donationtype}`}>
                <FcTemplate size={30} />
              </StyledButton>
              <StyledButton href={`${appVars.api_url}/generate-gtm-container/defaultnew/${props.client.id}`}>
                <FcGoogle size={30} />
              </StyledButton>
              <StyledButton title="Print audit report" href={`/audit/${props.client.id}`} target="_BLANK">
                <FcSearch size={30} />
              </StyledButton>
              <StyledButton title="Client Link" href={`/mr/${props.client.id}`} target="_BLANK">
                <FcBullish size={30} />
              </StyledButton>
              <StyledButton title="View API Stats" >
                <TbApi size={30} onClick={(e) => {
                  e.preventDefault();
                  setOpenAPIS(true);
                }}></TbApi>
              </StyledButton>
              <Badge
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                badgeContent={<NumericFormat fixedDecimalScale={false} decimalScale={0} displayType="text" value={(errors.length ?? 0)} ></NumericFormat>}
                color={(errors.length > 0 ? "error" : "success")}>
                <StyledButton title="View Errors" onClick={(e) => {
                  e.preventDefault();
                  setOpenErrors(true);
                }}>
                  <MdError size={30} color={(errors.length === 0 ? "green" : "red")} ></MdError>
                </StyledButton></Badge>

            </StyledButtonGroup>
            <SaveButton
              disabled={admintoken.token >= 6 ? false : true}
              startIcon={<SaveOutlined />}
              type="submit"
              spacing={theme.spacing(0)}
            >
              Save
            </SaveButton>
            <CancelButton startIcon={<CancelOutlined />} href="/" spacing={theme.spacing(1)}>
              Cancel
            </CancelButton>
          </StyledToolbar>

        </Grid>
        <FileUploads handleClose={handleClose} open={uploadOpen} showButton={false} text="Upload Logo" suggest="200px x 100px PNG ONLY" admintoken={admintoken} themeColor={{ primary: "#399cdb", secondary: "#000000" }} newid={props.client.id} childToParent={(e) => {
          //    console.log(e);
          updateLogoFile(appVars.api_url + '/imageFiles/' + props.client.id + '.png');
        }} ></FileUploads>
        <Dialog fullWidth maxWidth="md" open={openErrors} onClose={handleClose}>
          <DialogTitle >{"Errors"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Box pt={1}>

                <MaterialTable
                  title="Errors"
                  totalCount={5}

                  columns={[
                    {
                      title: 'Error', field: 'num', render: (rowData) => (
                        <Typography variant="body2">{rowData.message}</Typography>
                      )
                    },
                    {
                      title: 'Errors', field: 'count', render: (rowData) => (
                        <Typography variant="body2"><NumericFormat fixedDecimalScale decimalScale={0} displayType="text" value={(rowData.count ?? 0)} /></Typography>
                      )
                    }
                  ]}
                  data={errorCounts}
                  options={{
                    headerStyle: {
                      background: theme.palette.secondary.light,
                    },
                    rowStyle: (rowData, index) => ({
                      backgroundColor: index % 2 === 1 ? lightenHexColor('#000000', 90) : '#fff',
                    }),
                    search: false,
                                     paging: false,
                    sorting: false,
                    toolbar: false,
                    header:false
                  }}

                />
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {(admintoken.token >= 10 ?
              <Button color="primary" variant='contained' onClick={(e) => {

                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                var raw = JSON.stringify({
                  "clientid": props.client.id
                });

                var requestOptions = {
                  method: 'POST',
                  headers: myHeaders,
                  body: raw,
                  redirect: 'follow'
                };

                fetch(appVars.api_url + "/errors", requestOptions)
                  .then(response => fetchData())
                  .then(response => handleClose())
                  .catch(error => console.log({ show: true, message: error, severity: 'error' }))

              }}>Clear Errors</Button>
              : '')}
            <Button onClick={handleClose} variant="outlined" color="primary" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={copySuccessShow}
          autoHideDuration={2500}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            {copySuccess}
          </Alert>
        </Snackbar>
      </ThemeProvider>
    </div>
  );
};

ClientToolbar.propTypes = {};

export default ClientToolbar;
