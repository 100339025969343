import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { Card, CardHeader, CardMedia, CardContent, Button, Snackbar, Skeleton, Alert, Typography, Divider } from '@mui/material';
import Grid from '@mui/material/Grid2';
import LightCard from '../LightCard';
import appVars from '../../appVars.json';

// Styled components
const StyledGrid = styled('Div')(({ theme }) => ({
    padding: theme.spacing(1),
 
}));

const StyledMedia = styled(CardMedia)(({ theme }) => ({
    height: 145,
    backgroundPosition: 'top center',
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
}));


const LightGrid = (props) => {
    const [hasError, setErrors] = useState(false);
    const [cards, setCards] = useState([]);
    const [loading, setLoading] = useState(true);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrors(false);
    };

    async function fetchData(id) {
        let url = `${appVars.api_url}/lightboxes/${id}`;
        if (id === '') {
            url = `${appVars.api_url}/lightboxes/all`;
        }

        try {
            const res = await fetch(url);
            const data = await res.json();
            setCards(data);
            setLoading(false);
        } catch (err) {
            setErrors(true);
        }
    }

    useEffect(() => {
        const id = sessionStorage.getItem('cid');

        if (props.cards === undefined) {
            fetchData(id);
        } else {
            setCards(props.cards);
            setLoading(false);
        }
    }, [props]);

    // Convert cards into an array of objects
    const arr = Object.values(cards);

    return (
        <div>
            {loading ? (
                <StyledGrid container spacing={0}>
                    {[1, 2, 3, 4].map((_, index) => (
                        <Grid item size={{ lg: 3, md: 4, sm: 6 }}>
                            <Card>
                                <CardHeader
                                    title={<Skeleton animation="wave" width="40%" style={{ marginBottom: 6 }} />}
                                />
                                <StyledMedia>
                                    <Skeleton animation="wave" variant="rectangular" height={145} />
                                </StyledMedia>
                                <CardContent>
                                    <Skeleton animation="wave" width="65%" style={{ marginBottom: 6 }} />
                                    <Skeleton animation="wave" style={{ marginBottom: 6 }} />
                                    <Skeleton animation="wave" style={{ marginBottom: 6 }} />
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </StyledGrid>
            ) : (
                <StyledGrid>
                    <Grid container spacing={1}  >
                        <Grid size={{xs:12}}>
                        <Typography variant="h6">
                            {props.title}
                        </Typography>
                        <StyledDivider />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                    {arr.map((card, i) => {
                        try {
                            const embed = JSON.parse(card.embed || '{}');
                            const image = JSON.parse(card.image || '{}');
                            const set = { ...card, embed, image };
                            return <LightCard key={i} {...set} siteID={props.beaconId} />;
                        } catch (error) {
                            console.error('Error parsing JSON:', error);
                            return null;
                        }
                    })}
                    </Grid>
                </StyledGrid>
            )}
            <Snackbar open={hasError} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    The data cannot be pulled at this time.
                    <Button color="primary" onClick={window.location.reload}>
                        Refresh
                    </Button>
                </Alert>
            </Snackbar>
        </div>
    );
};

export default LightGrid;
