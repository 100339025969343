import React, { useState, useEffect } from 'react';
import appVars from '../../../appVars.json';
import TextField from '@mui/material/TextField';
import { Avatar, MenuItem, Box, ListItemIcon, ListItemText, Typography } from '@mui/material';



const PlatformSearch = (props) => {
    const [ecoms, setEcoms] = useState([
        { siteUrl: "Search" }
    ]);



    useEffect(() => {
        fetchData();
    }, []);


    async function fetchData() {
        const donationSites = await fetch(appVars.lightwerks + "/sites/donation/");
        donationSites
            .json()
            .then((sites) => {
                setEcoms(sites);
                //console.log(sites);
                //  setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });

    }


    return (
        <Box className="hidden-xs-down" pb={2} >
            <TextField size="small" variant='outlined' label={props.title ? props.title : 'Platform Search'} shrink select {...props} {...props.field}>
                {ecoms.map((entry) => (
                    <MenuItem key={entry.userid} value={entry.pkRecID}>

                        <Box {...props} sx={{ display: 'flex', alignItems: 'center' }}>
                            <ListItemIcon>
                                <Avatar
                                    sx={{ padding: 1, width: '100%', marginRight: 0 }}
                                    variant="rounded"
                                    alt={entry.site}
                                    src={appVars.api_url + '/imageFiles/' + entry.pkRecID + '.png'}
                                />
                            </ListItemIcon>
                            <ListItemText><Typography marginLeft={2} variant="body2">{entry.site}</Typography></ListItemText>
                        </Box>

                    </MenuItem>

                ))}
            </TextField>
        </Box>
    );
};

PlatformSearch.propTypes = {

};

export default PlatformSearch;
