import { useState, useEffect } from 'react';
import { Tab, Tabs, Box, Snackbar, Badge, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import MuiAlert from '@mui/lab/Alert';
import styled, { ThemeProvider as StyledThemeProvider } from 'styled-components';
import themeFile from '../theme.json';
import PropTypes from 'prop-types';
import PrimaryClient from './primary-client';
import { Check, Error, Money, ViewAgendaOutlined } from '@mui/icons-material';
import { createTheme } from '@mui/material/styles';
import appVars from '../appVars.json';


// Create the Material-UI theme
const theme = createTheme(themeFile);

// Custom styled components
const Root = styled.div`
  display: flex;

`;
const CardGridItem = styled(Grid)`
  padding: ${({ theme }) => theme.spacing(1)}px;
  background: ${({ theme }) => theme.palette.secondary.light};
  flex: grow;
  width: 100%;
  margin: 0;
`;

const AppBarStyled = styled(Box)`
    background: linear-gradient(90deg,rgba(0,0,0,1) 0%, rgba(255,87,34,1) 97% );
  color: #fff;
  padding:  ${({ theme }) => theme.spacing(0)};
    width:100%;
`;

const ToolbarStyled = styled.div`
  display: flex;
  align-items: center;
  color: #ffffff;
  padding: ${({ theme }) => theme.spacing(0)};
`;

const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

// Reusable TabPanel component
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

// Main DashBoard component
const DashBoard = (props) => {
  const [error, setErrors] = useState({ status: false, msg: 'No Error!', severity: 'error' });
  const [errorcount, setErrorCount] = useState(0);
  const [status, setStatus] = useState();

  const [refreshInterval] = useState(10000); // Interval in milliseconds
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  }; 

  async function fetchData() {
    const statusResponse = await fetch(appVars.api_url + '/status', { method: "GET", redirect: "follow" });
    const statusData = await statusResponse.json();
    setStatus(statusData);
  }

  useEffect(() => {
    fetchData();
    document.title = 'Lightwerks | Main Dashboard';
  }, [refreshInterval]);

  return (


    <StyledThemeProvider theme={theme}>
      <ToolbarStyled />
      <Root>
        <Grid sx={{ width: '100%' }} container spacing={0} direction="row-reverse">
          <Grid size={{ xl: 12, md: 12 }}>
            <AppBarStyled sx={{ marginTop: '0em', marginBottom: theme.spacing(0), borderBottom: 1, borderColor: 'divider', width: '100%' }}>
              <Tabs indicatorColor="secondary" textColor="#ffffff" value={value} onChange={handleChange} variant="standard" aria-label="basic tabs example">
                <Tab label="Overview" {...a11yProps(0)} />
                <Tab  label="Revenue" {...a11yProps(1)} />
                <Tab  label="Conversions" {...a11yProps(2)} />
            
              </Tabs>
            </AppBarStyled>
            <CardGridItem>
              {status && (
                <Grid container spacing={0} sx={{ m: 0 }}>
                  <Grid sx={{ p: 1 }} size={{ xl: 3, md: 2 }}>
                    <Typography align='center' variant="body2" color="textPrimary" gutterBottom>Engine Status: {status.status}...</Typography>
                  </Grid>
                  <Grid sx={{ p: 1 }} size={{ xl: 3, md: 2 }}>
                    <Typography align='center' variant="body2" color="textPrimary" gutterBottom>Version: {status.version}</Typography>
                  </Grid>
                  <Grid sx={{ p: 1 }} size={{ xl: 3, md: 2 }}>
                    <Typography align='center' variant="body2" color="textPrimary" gutterBottom>Secure: {status.use_ssl}</Typography>
                  </Grid>
                  <Grid sx={{ p: 1 }} size={{ xl: 3, md: 2 }}>
                    <Typography align='center' variant="body2" color="textPrimary" gutterBottom>RunID: {status.run_id}</Typography>
                  </Grid>
                </Grid>
              )}
            </CardGridItem>
            <TabPanel value={value} index={0}>
              <PrimaryClient />
              
            </TabPanel>

            <TabPanel value={value} index={1}>
              <Grid item xs={12}></Grid>
            </TabPanel>

            <TabPanel value={value} index={2}>
              <Grid container xs={12} spacing={1}>
                <Grid item xs={12}></Grid>
              </Grid>
            </TabPanel>


          </Grid>
        </Grid>
        <Snackbar open={error.status} autoHideDuration={3500}>
          <Alert severity={error.severity}>{error.msg}</Alert>
        </Snackbar>
      </Root>
    </StyledThemeProvider>


  );
};

DashBoard.propTypes = {};

export default DashBoard;
