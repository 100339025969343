import { useState, useEffect } from 'react';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import appVars from '../../../appVars.json';
import { styled } from '@mui/material/styles';
import { Box, LinearProgress, Typography } from '@mui/material';
import _ from 'lodash';

const FormControl = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(1),
}));

const StyledChip = styled(Chip)(({ theme }) => ({
    margin: 2,
    overflow: 'hidden',
}));

const GoogleAccounts = (props) => {
    console.log("Google Account Properties", props);
   
    const [selectedOptions, setSelectedOptions] = useState(() => {
        if (typeof props.defaultValue === 'string' && props.defaultValue.includes(',')) {
            return props.defaultValue.split(',').map(value => value.trim());
        } else if (props.defaultValue || typeof props.defaultValue === 'number') {
            return [`${props.defaultValue}`];
        }
        return [];
    });


    
    const [options, setOptions] = useState();
    const [adoptions, setAdOptions] = useState();
    const [token, setToken] = useState([props.token]);

    async function fetchData() {


        const res = await fetch(appVars.api_url + "/clients/" + props.clientid);
        res
          .json()
          .then(res => {

            console.log("Client Results", res[0]);
          })
          .catch(err => console.log('Error',err));











        let url =  props.clientid;
        let port = props.port;

        if (url === "") {
            return false;
        }

        try {
            const r = await fetch(`https://${url}.autogateapi.com:${port}/google/api/tokens`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                redirect: 'follow'
            });
            const rData = await r.json();
            setAdOptions(rData);

            if (props.token !== '') {
                let type = '';
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    redirect: 'follow',
                    body: JSON.stringify({ token: props.token })
                };

                switch (props.name) {
                    case 'google_paid':
                        url += "/google/accountids/get";
                        type = 'Paid';
                        break;
                    case 'google_grant':
                        url += "/google/accountids/get";
                        type = 'Grant';
                        break;
                    default:
                        url = "";
                        break;
                }

                const response = await fetch(url, requestOptions);
                const data = await response.json();
                const filteredData = type ? _.filter(data, (row) => _.includes(row.ACCOUNT_NAME, type)) : data;
                setOptions(filteredData);
            }
        } catch (error) {
            console.error('error', error);
        }
    }

    useEffect(() => {
        if (!adoptions) {
            fetchData();
        }
    }, [props, options]);

    return (
        <FormControl>
 <Typography variant='h5' color='textPrimary' gutterBottom sx={{mb:3}}>{props.label} </Typography>
            <Box gap={2} display="flex">
                {adoptions ? (
                    <Box width="50%">
                        <Autocomplete
                            multiple
                            id="tags-standard"
                            disabled={props.admintoken < 6}
                            options={adoptions}
                            getOptionLabel={(option) => `${option.name} - ${option.token}`}
                            defaultValue={token.map((option) => {
                                const found = _.find(adoptions, { 'token': option[0] });
                                return found;
                            })}
                            sx={{ mb: 2 }}
                            onChange={async (e, newValue) => {
                                setToken(newValue[0]?.token || '');

                                let type = '';
                                let url = appVars.api_url;
                                const requestOptions = {
                                    method: 'POST',
                                    headers: {
                                        'Accept': 'application/json',
                                        'Content-Type': 'application/json'
                                    },
                                    redirect: 'follow',
                                    body: JSON.stringify({ token: newValue[0]?.token })
                                };

                                switch (props.name) {
                                    case 'google_paid':
                                        url += "/google/accountids/get";
                                        type = 'Paid';
                                        break;
                                    case 'google_grant':
                                        url += "/google/accountids/get";
                                        type = 'Grant';
                                        break;
                                    default:
                                        url = "";
                                        break;
                                }

                                const response = await fetch(url, requestOptions);
                                const data = await response.json();
                                const filteredData = type ? _.filter(data, (row) => _.includes(row.ACCOUNT_NAME, type)) : data;
                                setOptions(filteredData);
                            }}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <StyledChip
                                        label={option.name}
                                        {...getTagProps({ index })}
                                        color='secondary'
                                        key={option.token}
                                    />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                fullWidth
                                    {...params}
                                    variant="outlined"
                                    label={`Select ${props.label} Management Account`}
                                />
                            )}
                        />
                    </Box>
                ) : (
                    <LinearProgress />
                )}


                {options ? (
                    <Box width="50%">
                        <Autocomplete
                            multiple
                            id="tags-standard"
                            disabled={props.admintoken < 6}
                            options={options}
                            getOptionLabel={(option) => `${option.ACCOUNT_NAME} - ${option.ACCOUNT_ID}`}
                            defaultValue={selectedOptions.map((option) => _.find(options, { ACCOUNT_ID: option }))}
                            onChange={(e, newValue) => {
                                const reportReturn = newValue.map(item => item.ACCOUNT_ID);
                                setSelectedOptions(reportReturn);
                                if (props.onSelectionChange) {
                                    props.onSelectionChange(reportReturn, props.tab, props.label, token[0]);
                                }
                            }}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    option && (
                                        <StyledChip
                                            label={option.ACCOUNT_NAME}
                                            {...getTagProps({ index })}

                                            key={option.ACCOUNT_ID}
                                        />
                                    )
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                fullWidth
                                    {...params}
                                    variant="outlined"
                                    label={`Select ${props.label} Customer Account`}
                                />
                            )}
                        />
                    </Box>
                ) : (
                    <Typography>Select Customer Account</Typography>
                )}
            </Box>

        </FormControl>
    );
};

export default GoogleAccounts;
