import { Box, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import appVars from '../../appVars.json';
import { LockRounded } from '@mui/icons-material';
import { BsLock, BsUnlock } from 'react-icons/bs';

const ApigateStatus = (props) => {
    //console.log('API Status Props',props)
    const [status, setStatus] = useState();
    const [client] = useState(props.client[0]);

   // console.log('API Status Props', client)


    async function fetchData() {
        const url = `https://${client.clientId}.autogateapi.com:${client.apiPort}/status`;
      //  console.log('API',url);
        const statusResponse = await fetch(url, { method: "GET", redirect: "follow" });
        const statusData = await statusResponse.json();
        setStatus(statusData);
    }

    useEffect(() => {
        if (client.apiPort) {
        fetchData();   
        }
        return () => {

        };
    }, []);

    return (
        <Box>
            {status && (
                <Box spacing={0} sx={{ m: 0 }}>
                    <Box sx={{ pb: 1 }}>
                        <Typography align='left' variant="body2" color="textPrimary" gutterBottom>Engine Status: {status.status}...</Typography>
                    </Box>
                    <Box sx={{ pb: 1 }}>
                        <Typography align='left' variant="body2" color="textPrimary" gutterBottom>Version: {status.version}</Typography>
                    </Box>
                    <Box sx={{ pb: 1 }}>
                        <Typography align='left' variant="body2" color="textPrimary" gutterBottom>Secure: {(status.use_ssl ? <BsLock size={15} /> : <BsUnlock size={15}/>)}</Typography>
                    </Box>
                    <Box sx={{ pb: 1 }}>
                        <Typography align='left' variant="body2" color="textPrimary" gutterBottom>RunID: {status.run_id}</Typography>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

ApigateStatus.propTypes = {

};

export default ApigateStatus;
