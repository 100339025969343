import  { useState } from "react";
import { Box } from "@mui/material";
import { styled } from "styled-components";
import GtmMain from "./gtm-main";




const ContentBox = styled(Box)`
  flex-grow: 1;
`;

const GtmInstall = () => {
  
  return (
    <Box display="flex">
      <ContentBox>
        <GtmMain />
      </ContentBox>
    </Box>
  );
};

export default GtmInstall;
