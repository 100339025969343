import { useEffect, useState } from 'react';
import {
  IconButton, Button, CardHeader, Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle, TextField, Card, CardActionArea, CardMedia, Typography, Box, Menu, MenuItem, Divider, ListItemIcon, ListItemText
} from '@mui/material';
import styled, { ThemeProvider } from 'styled-components';
import { createTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Loading from '../../../no-screenshot.png';
import { Edit, FileCopy, Delete, Preview, Settings, ScreenshotMonitor } from '@mui/icons-material';
import { formatDate } from "../../../dwi-functions";
import themeFile from '../../../theme.json';
import appVars from '../../../appVars.json';
import { useToken } from '../../../Login/TokenProvider';
import {  MdMoreVert } from 'react-icons/md';


// Styled components
const StyledCard = styled(Card)`
  max-width: 100%;
`;

const StyledCardHeader = styled(CardHeader)`
  background: ${({ theme }) => theme.palette.secondary.light};
  color: #ffffff;
  display: ${({ hideHeader }) => (hideHeader ? 'none' : 'flex')};
`;

const MediaContainer = styled(CardMedia)`
  width: 100%;
  background-size:contain;
  object-fit:contain;
`;


const Screenshot = (props) => {
  const [imageName, setImageName] = useState(`${appVars.api_url}/imageFiles/${props.pkRecID}.jpeg`);
  const [pageName, setPageName] = useState(props.description);
  const [pageURL, setPageURL] = useState(props.landing_url);
  const [hideHeader, setHideHeader] = useState(props.hideHeader);
  const [openEdit, setEditOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const admintoken = useToken();
  const token = admintoken.token;
  //console.log('props:', props);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  // Update Edited Values
  const handleEditChange = (event) => {
    const { id, value } = event.target;
    if (id === "description") setPageName(value);
    if (id === "landing_url") setPageURL(value);
  };

  // Save Edited Data
  const handleEdit = async () => {
    try {
      const updatedData = {
        pkRecID: props.pkRecID,
        description: pageName,
        landing_url: pageURL,
      };

      await fetch(`${appVars.api_url}/lp/update`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(updatedData),
      });

      setEditOpen(false);
      props.FetchData(); // Refresh Data After Editing
    } catch (error) {
      console.error("Error updating page:", error);
    }
  };
  const handleDelete = async () => {
    try {
      await fetch(`${appVars.api_url}/lp/delete`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ pkRecID: props.pkRecID }),
      });
      setOpenDelete(false);
      props.fetchdata();
      // FetchData()
    } catch (error) {
      console.error('Error deleting page:', error);
    }
  };
  const handleImageError = () => {
    setImageName(Loading);
  };

  const theme = createTheme(
    props.themeColor
      ? {
        palette: {
          primary: { main: props.themeColor.primary },
          secondary: { main: props.themeColor.secondary },
        },
      }
      : themeFile
  );

  const FetchData = () => {
    handleClose();
    setImageName(`${appVars.api_url3}/imageFiles/loading.gif`)
    fetch(`${appVars.api_url3}/screenshot?d=${props.landing_url}&id=${props.pkRecID}`)
      .then((response) => response.json())
      .then((data) => setImageName(data.file))
      .catch(() => handleImageError);
  };

  useEffect(() => {
    setHideHeader(props.hideHeader);
  }, [props.hideHeader, props.landing_url]);

  return (
    <ThemeProvider theme={theme}>
      <StyledCard variant="outlined">
        <StyledCardHeader

          action={
            <div>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}>
                <MdMoreVert />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >

                <MenuItem
                  onClick={(e) => {
                    if (props.landing_url !== '#' && props.landing_url !== "") {
                      FetchData();
                    } else {
                      handleClose();
                    }
                  }}>

                  <ListItemIcon>
                    <ScreenshotMonitor fontSize="small" />
                  </ListItemIcon><ListItemText>

                    Capture Screenshot</ListItemText></MenuItem>
                <MenuItem onClick={(e) => {
                  handleClose();
                  setEditOpen(true);
                }}>   <ListItemIcon>
                    <Settings fontSize="small" />
                  </ListItemIcon><ListItemText>Edit Settings</ListItemText></MenuItem>
                <MenuItem onClick={(e) => {
                  window.open(appVars.editor + 'index.php/?clid=' + props.client_id + '&lpid=' + props.pkRecID, "_BLANK");
                  handleClose();

                }}>                   <ListItemIcon>
                    <Edit fontSize="small" />
                  </ListItemIcon><ListItemText>  Edit Content</ListItemText></MenuItem>

                <MenuItem onClick={async () => {
                  const duplicateData = {
                    pkRecID: '1',
                    client_id: props.clientId,
                    landing_url: props.landing_url,
                    description: `${props.description} (Duplicate)`,
                    settings: JSON.stringify(props.settings),
                  };
                  try {
                    await fetch(`${appVars.api_url}/lp/add`, {
                      method: 'POST',
                      headers: { 'Content-Type': 'application/json' },
                      body: JSON.stringify(duplicateData),
                    });
                    props.FetchData();
                  } catch (error) {
                    console.error('Error duplicating page:', error);
                  }
                }}>
                  <ListItemIcon>
                    <FileCopy fontSize="small" />
                  </ListItemIcon><ListItemText>Duplicate Page</ListItemText></MenuItem>

                <MenuItem onClick={(e) => {
                  window.open(props.landing_url, "_BLANK");
                  handleClose();

                }}>  <ListItemIcon>
                    <Preview fontSize='small' />
                  </ListItemIcon><ListItemText>Preview</ListItemText></MenuItem>

                {(token.token >= 8 ? <> <Divider /> <MenuItem onClick={(e) => {
                  handleClose();
                  setOpenDelete(true);
                }}>  <ListItemIcon>
                    <Delete fontSize="small" />
                  </ListItemIcon><ListItemText>Delete</ListItemText></MenuItem></> : '')}
              </Menu>
            </div>
          }
          hideHeader={hideHeader}
          title={
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }} >
              <Typography variant="body1">{props.description}</Typography>

            </Box>
          }
        />
        <CardActionArea>
          <MediaContainer
            title={props.clientname}

            component="img"
            alt={props.landing_url}
            image={imageName}
            onError={handleImageError}
          />
        </CardActionArea>
        <Box
          sx={{
            background: theme.palette.secondary.dark,
            p: 1,
            display: 'flex', flexDirection: 'column'
          }}
        >
          <Typography variant="body2" color="#ffffff">Created: {formatDate(props.createdAt)}</Typography>
          <Typography variant="body2" color="#ffffff" >Updated: {formatDate(props.updatedAt)}</Typography>
        </Box>
      </StyledCard>
      <Dialog open={openEdit} onClose={(e) => {
        setEditOpen(false);
      }}>
        <DialogTitle>Edit Landing Page</DialogTitle>
        <DialogContent >
          <DialogContentText sx={{
            pt: 3
          }}>
            Provide a name and URL for the new landing page.
          </DialogContentText>
          <TextField autoFocus margin="dense" id="description" label="Page Name" onChange={handleEditChange} slotProps={{ inputLabel: { shrink: true } }} type="text" variant="outlined" fullWidth value={pageName} />
          <TextField margin="dense" id="landing_url" label="Page URL" type="text" onChange={handleEditChange} slotProps={{ inputLabel: { shrink: true } }} variant="outlined" fullWidth value={pageURL} />
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => {
            setEditOpen(false);
          }} color="primary" variant="outlined">Cancel</Button>
          <Button onClick={handleEdit} color="primary" variant="contained">Save Settings</Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog maxWidth="xs" open={openDelete}>
        <DialogTitle>Delete Landing Page?</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{
            pt: 3
          }}>
            <Typography>{props.description}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDelete(false)} color="primary">Cancel</Button>
          <Button onClick={handleDelete} color="primary">Ok</Button>
        </DialogActions>
      </Dialog>



    </ThemeProvider >
  );
};

Screenshot.propTypes = {
  data: PropTypes.array,
};

export default Screenshot;
