import { useState, useEffect } from "react";
import MaterialTable from "material-table";
import styled from 'styled-components';
import {
    Typography,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Button,
    Box,
    Snackbar,
    Toolbar,
    IconButton,
    LinearProgress,
    Avatar,
    Link,
    Switch
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import Alert from "@mui/lab/Alert";
import ClipboardJS from "clipboard";
import appVars from '../../appVars.json';
import Screenshot from '../../system/controls/screenshot';
import { Add, Edit, GridOffRounded, List } from "@mui/icons-material";
import _ from "lodash";
import { useTheme } from '@mui/material/styles';
import { BsMeta, BsDownload } from "react-icons/bs";
import Newclient from "../../clients/newclient";
import { useToken } from "../../Login/TokenProvider";
import { AvatarGroup } from "@mui/lab";

// Styled components
const Root = styled.div`
    margin: 0;
    padding:${({ theme }) => theme.spacing(1)};
`;

const StyledToolbar = styled(Toolbar)`
    margin: ${({ theme }) => theme.spacing(0)};
    padding: 0;
`;


const TableContainer = styled(MaterialTable)`
    display: ${({ hideList }) => (hideList ? 'none' : 'block')};
`;

// Constants
const getLastItem = (thePath) => thePath.substring(thePath.lastIndexOf('/') + 1);
const id = getLastItem(window.location.href);

const tableOptions = {
    grouping: true,
    pageSize: 25,
    pageSizeOptions: [10, 25, 50, 75, 100],
    showTitle: false,
    search: false
};

const SubClientList = (props) => {
    const [open, setOpen] = useState(props.open);
    const [hasError, setErrors] = useState(false);
    const [copySuccessShow, setCopySuccessShow] = useState(false);
    const [clientUsers, setClientUsers] = useState(false);
    const [loading, setLoading] = useState(false);
    const [copySuccess, setCopySuccess] = useState("");
    const [hideList, setHideList] = useState(true);
    const [hideGrid, setHideGrid] = useState(false);
    const token = useToken();
    const admintoken = token.token;
    const theme = useTheme();

    const toggleDialog = () => setOpen(!open);



    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") return;
        setErrors(false);
        setCopySuccessShow(false);
    };

    async function fetchData() {
        try {
            const res = await fetch(`${appVars.api_url}/tagmanager_detail/${id}`);
            const data = await res.json();
            let sortedData = _.sortBy(data, ['name']);
            sortedData = props.status ? _.filter(sortedData, 'status') : _.filter(sortedData, (o) => !o.status);
            setClientUsers(sortedData);
            setLoading(true);
        } catch (error) {
            console.error(error);
            setErrors(true);
        }
    }

    useEffect(() => {
        if (!clientUsers) fetchData();

        document.title = "Client Dashboard View";

        const clipboard = new ClipboardJS("#gtminstall");
        clipboard.on("success", (e) => {
            setCopySuccess(`${e.action}: ${e.text}`);
            setCopySuccessShow(true);
            e.clearSelection();
        });

        const fbVerification = new ClipboardJS("#fbverification");
        fbVerification.on("success", (e) => {
            setCopySuccess(`${e.action}: ${e.text}`);
            setCopySuccessShow(true);
            e.clearSelection();
        });
    }, [clientUsers]);

    return (
        <Root theme={theme}>
            {clientUsers ? (
                <div>
                    <LinearProgress sx={{ display: (loading ? 'initial' : 'none') }} color="secondary" />
                    {(props.hideGrid === false ?
                        <Grid container spacing={1} hideGrid={hideGrid}>

                            {clientUsers.map((item, index) => (
                                <Grid size={{ xl: 3, md: 4, sm: 4, xs: 12 }} key={index}>
                                    <Screenshot admintoken={admintoken} item={item} url={item.siteUrl} clientname={item.name} />
                                </Grid>
                            ))}
                        </Grid>
                        : '')}

                    {(props.hideList === false ?
                        <TableContainer
                            data={clientUsers}
                            title="Google Tag Managers"
                            isLoading={!loading}
                            options={tableOptions}
                            hideList={hideList}
                            columns={[
                                { title: "pkRecId", field: "pkRecId", hidden: true },
                                { title: "siteUrl", field: "siteUrl", hidden: true },
                                {
                                    title: "", field: "name",
                                    render: (rowData) => (
                                        <IconButton size="small" color="secondary" href={`/ss/${rowData.id}`}>
                                            <Edit />
                                        </IconButton>
                                    ),
                                },
                                 {
                                    title: "", field: "site",
                                    render: (rowData) => (
                                        <Box sx={{
                                            p:2,
                                            maxWidth:200,
                                            background:"#c4c4c4",
                                            borderRadius:2,
                                        }}  >
                                        <img onError={(e)=>{e.currentTarget.src=appVars.api_url+'/imageFiles/no-image-icon.png'}} style={{width:'100%'}} src={appVars.api_url+'/imageFiles/'+rowData.id+'.png'} alt="" />
                                        </Box>
                                    ),
                                },
                                {
                                    title: "Account Team", field: "owner",
                                    render: (rowData) => (
                                        <AvatarGroup spacing="small" max={3}>
                                            <Avatar variant="circular" src={`${appVars.api_url}/imageFiles/${rowData.owner}.png`} title={rowData.owner} />
                                            <Avatar variant="circular" src={`${appVars.api_url}/imageFiles/${rowData.support}.png`} title={rowData.support} />

                                        </AvatarGroup>
                                    ),
                                },
                                {
                                    title: "Platforms", field: "owner",
                                    render: (rowData) => (
                                        <AvatarGroup spacing="small" max={3}>
                                          
                                                <Avatar variant="circular" src={`${appVars.api_url}/imageFiles/${rowData.sitetype}.png`} />
                                                <Avatar variant="circular" src={`${appVars.api_url}/imageFiles/${rowData.donationtype}.png`} />
                                    
                                        </AvatarGroup>
                                    ),
                                },

                                {
                                    title: "Client Name", field: "name",
                                    render: (rowData) => (
                                        <Link target="_BLANK" href={rowData.siteUrl}>{rowData.name}</Link>
                                    ),
                                },
                                {
                                    title: "Conversions", field: "fbconversions", render: (rowData) => (
                                        <Typography variant="body2">{rowData.fbconversions}</Typography>
                                    )
                                },
                                {
                                    title: "Revenue", field: "siteUrl", render: (rowData) => (
                                        <Typography variant="body2">{parseFloat(rowData.revenue).toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                        })}</Typography>
                                    )
                                },
                                {
                                    title: "Meta API?", field: "siteUrl", render: (rowData) => (
                                        rowData.fbapitoken ? <IconButton color="primary"><BsMeta /></IconButton> : null
                                    )
                                },
                                {
                                    title: "Facebook Validation", field: "site",
                                    render: (rowData) => (
                                        <Button variant="outlined" startIcon={<BsMeta />} color="secondary" id="fbverification" size="small" data-clipboard-text={`${appVars.facebookVerifyUrl}?id=${rowData.id}`}>
                                            Copy
                                        </Button>
                                    ),
                                },
                                {
                                    title: "GTM Download", field: "tag", render: (rowData) => (
                                        <IconButton color="secondary" href={`https://api.digitalwerks.com:7325/generate-gtm-container/default/${rowData.id}`} target="_BLANK"><BsDownload /></IconButton>
                                    )
                                },
                                {
                                    title: "GTM Instructions", field: "site",
                                    render: (rowData) => (
                                        <Button variant="outlined" color="secondary" id="gtminstall" size="small" data-clipboard-text={`${appVars.lightwerksUrl}gtm-install/${rowData.id}`}>
                                            Copy
                                        </Button>
                                    ),
                                },
                            ]}
                        /> : '')}
                </div>
            ) : (
                <LinearProgress style={{ margin: '20% 25%' }} color="secondary" />
            )}

            <Dialog maxWidth="lg" fullWidth open={open}>
                <DialogTitle>Add New Client</DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={3}>
                        <Grid item size={{ md: 12 }}>
                            <Newclient handleClose={toggleDialog} admintoken={admintoken} themeColor={theme.palette} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button size="small" variant="outlined" color="primary" onClick={toggleDialog}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={hasError} autoHideDuration={3000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity="error">
                    The data cannot be pulled at this time.
                    <Button color="primary" onClick={window.location.reload}>Refresh</Button>
                </Alert>
            </Snackbar>

            <Snackbar open={copySuccessShow} autoHideDuration={3000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity="success">
                    {copySuccess}
                </Alert>
            </Snackbar>
        </Root>
    );
};

SubClientList.propTypes = {};

export default SubClientList;
