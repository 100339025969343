import React, { useState, useEffect } from "react";
import { Box, Stepper, Step, StepLabel, Button, Typography, TextField, Divider } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useLocation } from 'react-router-dom';
import appVars from "../../appVars.json";
import MatomoSites from '../../system/controls/matomo-sites';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment-timezone';
import ImageOverlayControl from "../../system/controls/image-overlay";

const LightBoxWizard = (props) => {
    const location = useLocation();
    var id = location.pathname.split("/")[3];
    //console.log('id',id);
    const [lightbox, setLightbox] = useState({
        description: ''
    });
    const [cdesktop, setCDesktop] = useState();
    const [cmobile, setCMobile] = useState();
    const [status, setstatus] = useState(true);
    const [images, setImages] = useState("");
    const [hasError, setErrors] = useState(false);

    const [activeStep, setActiveStep] = useState(0);
    const [settings, setSettings] = useState({
        display: "",
        imageDetails: "",
    });

    const steps = ["Display Settings", "Image Details", "Confirm Settings", "Save/Confirmation"];

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };






    const handleReset = () => {
        setActiveStep(0);
        setSettings({ display: "", imageDetails: "" });
    };

    const handleInputChange = (key, value) => {
        setSettings((prevSettings) => ({ ...prevSettings, [key]: value }));
    };

    const handleImage = (props) => {

        setImages(props);
        console.log(images);
    };


    const handleEffectiveDateChange = (date) => {
        var momentDate = date;
        if (date) {
            setEffectiveStartDate(momentDate);

        } else {
            setEffectiveStartDate(null);
        }
        console.log(date);
    };

    const handleEffectiveEndDateChange = (date) => {


        var momentDate = date;


        if (date) {
            setEffectiveEndDate(momentDate);
        } else {
            setEffectiveEndDate(null);
        }
        console.log(date);
    };


    // eslint-disable-next-line
    const MyInput = ({ field, form, ...props }) => {
        return <TextField style={{ marginBottom: 25 }} fullWidth size='small' {...field} {...props} />;
    };
    const MyMamoto = ({ field, form, ...props }) => {
        return <MatomoSites {...field} {...props} ></MatomoSites>;
    };


    const [effectiveStartDate, setEffectiveStartDate] = React.useState(new Date());
    const [effectiveEndDate, setEffectiveEndDate] = React.useState(new Date());






    async function fetchData() {
        const requestOptions = {
            method: "GET",
            redirect: "follow"
        };

        const res = await fetch(appVars.api_url + "/lightbox/" + id, requestOptions);
        res.json()
            .then((response) => {
                var st = moment(response.startTime).tz('UTC');
                var et = moment(response.endTime);

                setEffectiveStartDate(new Date(st));
                setEffectiveEndDate(new Date(et));
                /*
                   console.log('Start', st);
                   console.log('End', et);
               */
                setLightbox(response);
                setImages(JSON.parse(response.image));
            })
            .catch((error) => console.error(error));
    }



    useEffect(() => {



        fetchData();


        return () => {

        };
    }, []);



    const renderStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <Box display="flex" gap={2} minHeight="calc(60vh)" >
                        <Box borderRadius={2} border="1px solid #c7c7c7" p={3} bgcolor="#f7f7f7" width="70%" >
                            <Typography style={{ marginBottom: 25 }} gutterBottom variant="h5">Display Settings</Typography>
                            <Field
                                type="text"
                                component={MyInput}
                                variant="outlined"
                                label="Name of Lightbox"
                                name="description"
                                id="description"
                            />

                            <Field
                                type="text"
                                label="Destination URL"
                                variant="outlined"
                                component={MyInput}
                                name="url"
                                id="url"
                            />
                            <Field
                                type="text"

                                label="Beacon ID"
                                variant="outlined"
                                name="beaconId"
                                id="beaconId"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                client={{ client: { name: '' } }}
                                component={MyMamoto}
                            />
                        </Box>
                        <Box width="30%" borderRadius={2} border="1px solid #c7c7c7" p={3} bgcolor="#f7f7f7" >
                            <Typography gutterBottom variant="h5">Run Settings</Typography>

                            <Field
                                label="Start Date"
                                fullWidth
                                style={{ marginBottom: 16 }}

                                onChange={handleEffectiveDateChange}
                                inputVariant="outlined"
                                component={DatePicker}
                                value={effectiveStartDate}
                                name="startTime"
                                id="startTime"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <ErrorMessage name="End Time" component="div" />
                            <Field
                                label="End Date"
                                fullWidth
                                style={{ marginBottom: 16 }}

                                onChange={handleEffectiveEndDateChange}
                                inputVariant="outlined"
                                component={DatePicker}
                                value={effectiveEndDate}
                                name="endTime"
                                id="endTime"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <Field

                                type="number"
                                label="# of days to show"
                                variant="outlined"
                                component={MyInput}
                                name="cookieExpire"
                                id="cookieExpire"
                            />

                            <Field

                                type="text"
                                label="Show delay (ms)"
                                variant="outlined"
                                component={MyInput}
                                name="delay"
                                id="delay"
                            />
                        </Box>
                    </Box>
                );
            case 1:
                return (
                    <Box>
                       <ImageOverlayControl lightboxId={id} imageData={images} updateImages={handleImage} nextStep={handleNext} />

                    </Box>
                );
            case 2:
                return (
                    <Box>
                        <Typography variant="h6">Confirm Settings</Typography>

                        {/* Display Saved Values */}
                        {Object.keys(images).length > 0 && (
                            <Box mt={3}>
                                <Typography variant="h6">Saved JSON Configuration:</Typography>
                                <pre>{JSON.stringify(images, null, 2)}</pre>
                            </Box>
                        )}

                    </Box>
                );
            case 3:
                return (
                    <Box>
                        <Typography variant="h6">Save/Confirmation</Typography>
                        <Typography>Your settings have been saved!</Typography>
                    </Box>
                );
            default:
                return <Typography>Unknown step</Typography>;
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Formik
                enableReinitialize
                initialValues={lightbox}
                onSubmit={(values) => {
                    var k = JSON.stringify(values);
                    k = JSON.parse(k);
                    const apiUrl =
                        (k.lightboxId === "NEW"
                            ? "https://api.digitalwerks.com:7325/lightbox/add"
                            : "https://api.digitalwerks.com:7325/lightbox/update/" +
                            k.lightboxId);

                    k.embed.close.mobile = cmobile;
                    k.embed.close.desktop = cdesktop;
                    k.embed.cssFile = k.cssFile;


                    k.startTime = effectiveStartDate;
                    k.endTime = effectiveEndDate;
                    k.image = (typeof images === 'string' ? images : JSON.stringify(images));
                    k.status = status;
                    k.embed = JSON.stringify(k.embed);
                    k.profile_id = props.admintoken.user;

                    /*
                    console.log(k);
                    return;
                    */

                    // eslint-disable-next-line
                    const res = fetch(apiUrl, {
                        method: (k.lightboxId === "NEW" ? "POST" : "PUT"),
                        body: JSON.stringify(k),
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }).then(res => {
                        console.log(res);
                        window.location.reload();
                        // window.location.href = '/';
                    }).catch(err => setErrors(true));
                    //console.log(res);

                    //  window.location.reload()
                }}
            >

                <Form>
                    <Box sx={{ width: "100%", p: 0 }} >
                        <Stepper activeStep={activeStep} style={{ background: 'linear-gradient(90deg, rgba(74,151,220,1) 0%, rgba(0,0,0,1) 97%)', padding: '8px', color: '#ffffff' }} alternativeLabel >
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel ><Typography color="primary">{label}</Typography></StepLabel>
                                </Step>
                            ))}
                        </Stepper>

                        <Box sx={{ p: 3 }}>
                            {activeStep === steps.length ? (
                                <>
                                    <Typography sx={{ mt: 2, mb: 1 }}>
                                        All steps completed - you&apos;re finished
                                    </Typography>
                                    <Button onClick={handleReset}>Reset</Button>
                                </>
                            ) : (
                                <>
                                    {renderStepContent(activeStep)}

                                    <Box sx={{ mt: 2 }}>
                                        <Divider style={{ marginBottom: 8 }} />
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            sx={{ mr: 1 }}
                                        >
                                            Back
                                        </Button>

                                        <Button
                                            variant="contained"
                                            onClick={handleNext}
                                        >
                                            {activeStep === steps.length - 1 ? "Finish" : "Next"}
                                        </Button>
                                    </Box>
                                </>
                            )}
                        </Box>
                    </Box>
                </Form>
            </Formik>
        </LocalizationProvider>
    );
};

export default LightBoxWizard;
