import React, { useState, useEffect } from 'react';
import appVars from '../../../appVars.json';
import Autocomplete from '@mui/lab/Autocomplete';
import { TextField, LinearProgress, Box, ListItemIcon, ListItemText, Avatar, MenuItem } from '@mui/material';
import { ThemeProvider } from '@mui/material';
import { useTheme } from '@mui/material';

const VerticalSearch = (props) => {
    const [domains, setDomains] = useState();
    const theme = useTheme();
    //

    async function fetchData() {
        const res = await fetch(appVars.api_url + "/verticals");
        res
            .json()
            .then((res) => {
                setDomains(res);
                //console.log(res);
                //  setLoading(false);
            })
            .catch((err) => console.log(err.msg));

    }


    useEffect(() => {
        fetchData();
    }, []);





    return (

        <div>
            {(domains ?
                <Box className="hidden-xs-down" pb={2} >
                    <TextField size="small" variant='outlined' label={props.title} shrink select {...props} {...props.field}>
                        {domains.map((entry) => (
                            <MenuItem key={entry.userid} value={entry.pkRecID}>
                                <Box display="flex" alignItems="center">

                                    <ListItemText primary={entry.vertical} />
                                </Box>
                            </MenuItem>

                        ))}
                    </TextField>
                </Box>
                : <LinearProgress />)}
        </div>


    );
};

VerticalSearch.propTypes = {

};

export default VerticalSearch;
