import { Box, Typography, Link, useTheme, LinearProgress, Divider } from '@mui/material';
import  { useState, useEffect } from 'react';
import appVars from '../../appVars.json';
import _ from "lodash";
import { lightenHexColor } from '../../dwi-functions';

const ErrorDetail = (props) => {

    const theme = useTheme();
    const [errorlog, setErrorLog] = useState();
    const [ setError] = useState({ show: false, message: 'Test', severity: 'error' });
  //  console.log('props',props);



    async function fetchData() {
        try {

            const response = await fetch(appVars.api_url + '/errors');
            const data = await response.json();


            const groupedAndCounted = _(data)
                .sortBy(['name', 'ASC'])
                .groupBy('name')
                .map((items, name) => {
                    // console.log("Item", items);
                    return ({
                        name,
                        count: items.length,
                        clientid: items[0].clientid, // Pick the first pkrecID for this group
                        agency: items[0].agency // Pick the first pkrecID for this group
                    })
                })
                .value();

            var filtered;
            if (props.agency) {
                filtered = _.filter(groupedAndCounted ,{ "agency": props.agency })
            }else{
                filtered = groupedAndCounted;
            }



           // console.log("Errors", filtered);
            setErrorLog(filtered);

        } catch (err) {
            setError({ show: true, message: err.message, severity: 'error' });
        }
    }



    useEffect(() => {
        fetchData();
        return () => {

        };
    }, []);



    return (

        (errorlog ?

            <Box boxShadow={'0px 0px 25px -15px ' + theme.palette.secondary.main} borderRadius={2} border={"1px solid #c7c7c7"} sx={{
                m: 0,
                p: { md: 2, xs: 1 },
                display: 'flex',
                flexDirection: 'column',

            }}>
                <Typography variant="h6" sx={{ p: 1, }}>Client Errors</Typography>
                <Divider></Divider>
                {errorlog.map((entry, index) => {
                    //  console.log('Entry', entry)
                    return (
                        <Box sx={{ p: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', background: index % 2 === 0 ? '#fff' : lightenHexColor(theme.palette.secondary.main, 30) }}>
                            <Link sx={{
                                textDecoration: 'none'
                            }} href={"/ss/" + entry.clientid} >
                                <Typography color={(index % 2 === 0 ? "textDisabled" : "textDisabled")}  >{entry.name}</Typography>
                            </Link>
                            <Typography color={(entry.status ? "primary" : "textDisabled")}  >{entry.count}</Typography>
                        </Box>
                    );

                })}
            </Box>
            : <Box boxShadow={'0px 0px 25px -15px ' + theme.palette.secondary.main} borderRadius={2} border={"1px solid #c7c7c7"}><LinearProgress /></Box>)
    );
};

ErrorDetail.propTypes = {

};

export default ErrorDetail;
