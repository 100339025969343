import React, { useState, useEffect } from 'react';
import * as ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import DashBoard from './DashBoard';
import { AppProvider, DashboardLayout } from '@toolpad/core';
import appVars from './appVars.json';
import { TokenProvider } from './Login/TokenProvider';
import ClientSearch from './system/controls/client-search';
import GtmInstall from './helper-forms/gtm-install';
import ClientViewer from './views/single_client';
import { Box, CircularProgress } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import themeFile from './theme.json';
import logo from './logo.png';
import SigninSide from './Login/SignInSide';
import LogOut from './Login/LogOut';
import Invoice from './clients/clientaudit';
import GrapeEditor from './settings//grape-editor';
import ClientSettings from './clients/ClientSettings';
import SetSessionInfo from './clients/set-session-info';
import SidebarFooterAccount, { ToolbarAccountOverride } from './Login/account-menu';
import ControlPanel from './system/ControlPanel';
import Ecommerce from './system/controls/SiteTypes/ecommerce';
import ErrorView from './ErrorView';
import ErrorBoundary from './views/error-boundry';
import LightDashBoard from './views/light-dashboard';
import LightBoxWizard from './views/lightbox-wizard';
import Registrations from './views/registrations';
import LandingPages from './views/landing-pages';
import { MdSpaceDashboard } from "react-icons/md";
import { TbReportAnalytics, TbTags, TbHtml } from "react-icons/tb";
import { FaRegImage } from "react-icons/fa6";
import { GrValidate } from "react-icons/gr";
import { MdOutlineSettingsInputComponent } from "react-icons/md";
import Tags from './views/tags';
import { getCookie } from './dwi-functions';
import ProfilePage from './UserTable/ProfilePage';


const BRANDING = {
  title: '',
  logo: <img src={logo} alt="Lightwerks" />
};

const App = () => {
  const [menu, setMenu] = useState(null);
  const [loading, setLoading] = useState(true);
  const sess = JSON.parse((getCookie('_aup') ? getCookie('_aup') : "{}"));
  const [session, setSession] = useState(sess);
  const theme = createTheme(themeFile);

  const authentication = React.useMemo(() => ({
    signIn: () => setSession(getCookie('_aup')),
    signOut: () => {
      setSession(null);
      window.location = '/logout';
    },
  }), []);



  function getPathExcludingUUID() {
    const pathSegments = window.location.pathname.split('/');
    const lastSegment = pathSegments[pathSegments.length - 1];

    // Regular expression to match a UUID
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

    // If the last segment is a UUID, remove it and return the rest of the path
    if (uuidRegex.test(lastSegment)) {
      return pathSegments.slice(0, -1).join('/');
    }

    // Otherwise, return the full path
    return window.location.pathname;
  }



  function injectIconsIntoMenu(menuItems, iconMap) {
    return menuItems.map(item => ({
      ...item,
      icon: iconMap[item.icon] || item.icon,  // replace with JSX if found
    }));
  }



  const router = createBrowserRouter([
    {
      path: '*',
      element:<ErrorBoundary />,
      errorElement: <ErrorBoundary />
    },
    { path: '/', element: <DashBoard />, },
    { path: '/ss/:id', element: <SetSessionInfo /> },
    { path: '/user/:id', element: <ProfilePage /> },
    { path: '/errors', element: <ErrorView /> },
    { path: '/scv', element: <Invoice elevation={0} /> },
    { path: '/scv/settings', element: <ClientSettings /> },
    { path: '/scv/tags', element: <Tags /> },
    { path: '/scv/conversions', element: <Registrations pageSize={10} /> },
    { path: '/scv/landing-pages', element: <LandingPages pageSize={10} /> },
    { path: '/agency/:id', element: <ClientViewer /> },
    { path: '/editor/:pid/:cid', element: <GrapeEditor /> },
    { path: '/control', element: <ControlPanel /> },
    { path: '/scv/lightboxes', element: <LightDashBoard /> },
    { path: '/control/sites/:id', element: <Ecommerce /> },
    { path: '/audit/:id', element: <Invoice /> },
    { path: '/login', element: <SigninSide /> },
    { path: '/lightbox/edit/:id', element: <LightBoxWizard type="edit" /> },
    { path: '/gtm-install/:id', element: <GtmInstall /> },
    { path: '/logout', element: <LogOut /> },
  ]);

  const seg = getPathExcludingUUID();
  const lastSegment = (seg !== '/' ? seg : 'main');





  const icons = {
    dashboard: <MdSpaceDashboard size={25} color={theme.palette.secondary.dark} />,
    reports: <TbReportAnalytics size={25}  color={theme.palette.secondary.dark}  />,
    settings: <MdOutlineSettingsInputComponent size={25}  color={theme.palette.secondary.dark}  />,
    lightbox: <FaRegImage size={25}  color={theme.palette.secondary.dark}  />,
    link: <GrValidate size={25}  color={theme.palette.secondary.dark}  />,
    tags: <TbTags size={25}  color={theme.palette.secondary.dark}  />,
    conversion: <GrValidate size={25}  color={theme.palette.secondary.dark}  />,
    landing: <TbHtml size={25}  color={theme.palette.secondary.dark}  />

  }



  console.log('Last Seg', lastSegment.replace('/',''));
  useEffect(() => {


    //console.log('_au',localStorage.getItem('_au'));
    const fetchData = async () => {
      try {

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
          "cid": lastSegment
        });

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow"
        };

        //console.log('Post', requestOptions);

        const response = await fetch(`${appVars.api_url}/menus/`, requestOptions);
        const menuData = await response.json();
        var k = JSON.parse(menuData[0]?.menu || '[]');
        const enrichedMenu = injectIconsIntoMenu(k, icons);
        console.log('Menu with icons:', enrichedMenu);
        setMenu(enrichedMenu);

      } catch (error) {
        console.error('Error fetching menu data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);



  if (loading) {
    return (
      <Box height={1000} display="flex" alignItems="center" justifyContent="center">
        <CircularProgress variant="indeterminate" color="primary" size={100} />+
      </Box>
    );
  }




  return (
    <React.StrictMode>

      <ThemeProvider theme={theme}>
        <TokenProvider>
          <AppProvider authentication={authentication} session={session} branding={BRANDING} navigation={menu} router={router} theme={theme}>
            {getCookie('_au') ? (
              <DashboardLayout
                defaultSidebarCollapsed
                slots={{
                  toolbarActions: ClientSearch,
                  toolbarAccount: ToolbarAccountOverride,
                  sidebarFooter: SidebarFooterAccount
                }}>

                <RouterProvider router={router}></RouterProvider>

              </DashboardLayout>
            ) : (
              <SigninSide />
            )}
          </AppProvider>
        </TokenProvider>
      </ThemeProvider>
 
    </React.StrictMode>
  );
};

// Render the root component
ReactDOM.createRoot(document.getElementById('root')).render(<App />);
