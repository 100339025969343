import React, { useState, useEffect } from 'react';
import { useToken } from '../../Login/TokenProvider';
import ClientToolbar from '../client-toolbar';
import appVars from '../../appVars.json';
import PropTypes from 'prop-types';
import { Box, Tabs, Tab, useTheme, Snackbar, Alert, TextField, IconButton, LinearProgress, MenuItem, Typography } from '@mui/material';
import { Formik, Field, Form } from 'formik';
import Grid from '@mui/material/Grid2';
import VerticalSearch from '../../system/controls/vertical-search';
import MatomoSites from '../../system/controls/matomo-sites';
import AgencyListing from '../../system/controls/agency-listing';
import UserSearch from '../../system/controls/user-search';
import PlatformSearch from '../../system/controls/platform-search';
import ColorThief from 'colorthief';
import { MuiColorInput } from 'mui-color-input';
import AdAccounts from '../../system/controls/ad-accounts';
import StackAdaptTokens from '../../system/controls/stackadapt-tokens';
import GoogleAccounts from '../../system/controls/google-accounts';

/***
 * 
 *  * Icons Import
 *  
*/
import { MdAddCircle } from "react-icons/md";
import { TbAdjustmentsCancel } from "react-icons/tb";
import { GrView } from "react-icons/gr";
import FileUploads from '../../system/file-uploads';
import { Settings } from '@mui/icons-material';
/***
 * 
 *  * Icons Import
 *  
*/

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            style={{ background: 'linear-gradient(315deg, ' + props.theme.palette.secondary.light + ' -5%, rgba(255,255,255,1) 15%)', minHeight: 'calc(100vh - 180px)' }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ pr: 1, pl: 1, mt: 1 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}





const ClientSettings = (props) => {
    const token = useToken();
    const admintoken = token.token;
    const id = sessionStorage.getItem('cid');
    const [loadingData, setLoading] = useState({ status: true, progress: 25, color: 'info' });
    const [saveMsg, setSaveMsg] = useState({ status: false, msg: '', severity: 'success' });
    const [bdisplay, setBeaconDisplay] = React.useState("inherit");

    const [settingsOpen, setSettingsOpen] = useState(false);
    const [settings, setSettings] = useState();
    const [profile, setProfile] = useState();


    const [color, setColor] = useState(props.themeColor);
    const [colors, setColors] = useState();
    // eslint-disable-next-line
    const [profileID, setProfileID] = useState((props.id ? props.id : id))
    const [showOverview, setShowOverview] = useState(false);
    const [showVirtuous, setShowVirtuous] = useState(false);
    const [showConversions, setShowConversions] = useState(false);
    const [value, setValue] = useState(0);
    // eslint-disable-next-line
    const [adType, setAdType] = useState([{ id: "google_paid", name: "Google Paid" }, { id: "google_grant", name: "Google Grant" }, { id: "meta", name: "Meta" }, { id: "bing", name: "Bing" }, { id: "stackadapt", name: "Display" }, { id: "overview", name: "Overview" }, { id: "virtuous", name: "Virtuous" }]);


    const setSelectedApiKey = (event, newValue, label, token) => {

        var v = event;

        if (settings.ad_sets[newValue]) {
            settings.ad_sets[newValue].id = v;
            settings.ad_sets[newValue].token = token;

        } else {
            settings.ad_sets[newValue] = { id: v, tablabel: label, token: token };
        }

        profile.saKey = token;
        profile.settings = settings;
        setProfile(profile);
        setSettings(settings);


        console.log(token);







    }



    const [owner, setOwner] = useState('Not Set');
    const [support, setSupport] = useState('Not Set');

    const [tab, setTab] = React.useState(0);
    const handleChange = (event, newValue) => {
        setTab(newValue);
    };
    const [client, setClient] = useState({
        id: '123',
        name: 'test',
    });

    const theme = useTheme();


    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSaveMsg({ status: false, msg: '', severity: 'info' })
        return;
    };


    const UpdateClient = (e) => {
        setOwner(e.target.value);
        //  console.log(e.target.value);
    }




    const MyInput = ({ field, form, ...props }) => {
        return <TextField style={{ marginBottom: 20 }} variant="outlined" shrink {...field} {...props} />;
    };

    const MySelectInput = ({ field, form, ...props }) => {
        return <TextField style={{ marginBottom: 0 }} select size="small" {...field} {...props} >
            <MenuItem value={1}>Yes</MenuItem>
            <MenuItem value={0}>No</MenuItem>
        </TextField>;
    };


    const MyMamoto = ({ field, form, ...props }) => {
        return <MatomoSites {...field} {...props} ></MatomoSites>;
    };



    const updateLogoFile = (childdata) => {
        var f = childdata;
        const img = document.getElementById('logoFile');
        img.src = f;
        // console.log(f);
    };







    async function snagTheDomain() {
        setLoading({ status: true, progress: 25 });
        // var url = appVars.api_url + "/check-domain/";
        var url = appVars.api_url + "/check-domain/";
        const data = {
            "domain": document.getElementById('siteUrl').value.replace('https://', '')
        };
        // console.log(document.getElementById('siteUrl').value.replace('https://',''));

        const res = await fetch(url,
            {
                method: 'post',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );
        res
            .json()
            .then((domainres) => {
                //domainres.name = document.getElementById('name').value;
                // domainres.client = owner;

                // client.registrar = domainres.registrar;
                var choices = {
                    ua: domainres.uaArray,
                    fb: domainres.fbpixelArray,
                    tag: domainres.tagArray,
                    ga4: domainres.ga4Array,
                    ad: domainres.ga_paid_idArray,
                    bing: domainres.bingIdArray
                };


                //    console.log(choices);

                //setClient(a);

                //  console.log(choices);
            }).catch((err) => {
                //setErrors({ status: true, msg: err.message });;
                //console.log(err);
            });

    }


    async function fetchData() {
        setLoading({ status: true, progress: 25 });
        const res = await fetch(appVars.api_url + "/tagmanager/" + id);
        res
            .json()
            .then(res => {
                res.pkRecId = id;
                setClient(res);
              //  console.log("ClientResult", res);
                document.title = res.clientname + ": " + res.name;
                setLoading({ status: true, progress: 75 });
                if (res.beaconId !== 0 && res.beaconId !== 9) {
                    setBeaconDisplay(false);
                }
            });

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");


        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };




        const rp = await fetch(appVars.api_url + "/reportprofile/" + id, requestOptions);
        rp
            .json()
            .then((res3) => {

                if (res3 !== null) {

                    var sett = (typeof res3.settings === 'object' && res3.settings !== null ? res3.settings : JSON.parse(res3.settings));

                    var colorsD = (typeof res3.color === 'object' && res3.color !== null ? res3.color : JSON.parse(res3.color));

                    res3.settings = sett;
                    res3.tabs = JSON.parse(res3.tabs);
                    res3.color = colorsD;
                    setProfile(res3);
                    setSettings(sett);


                    if (res3.settings.ad_sets['overview']) {
                        setShowOverview(true);
                    }

                    if (res3.settings.ad_sets['virtuous']) {
                        setShowVirtuous(true);
                    }

                    if (res3.settings.ad_sets['donations']) {
                        setShowConversions(true);
                    }

                    //(sett);






                } else {
                    profile.color = "#51704b";
                    profile.clientid = profileID;
                    profile.client = props.client
                    profile.settings = { "ad_sets": { "google_paid": { "id": "0" }, "google_grant": { "id": "0" }, "meta": { "id": "0" }, "bing": { "id": "0" }, "stackadapt": { "id": "0" } } }
                    profile.tabs = { "tabs": { "google_paid": { "label": "Google Paid", "charts": { "impressions": 1, "clicks": 1, "ctr": 1, "cpc": 1, "conversions": 1, "revenue": 1, "conv_rate": 1, "cost_conversion": 1, "searchTerms": 1, "region": 0, "comment": 1 } }, "google_grant": { "label": "Google Grant", "charts": { "impressions": 1, "clicks": 1, "ctr": 1, "cpc": 1, "conversions": 1, "revenue": 1, "conv_rate": 1, "cost_conversion": 1, "searchTerms": 1, "region": 0, "comment": 1 } }, "meta": { "label": "Meta", "charts": { "impressions": 1, "clicks": 1, "ctr": 1, "cpc": 1, "conversions": 1, "revenue": 1, "conv_rate": 1, "cost_conversion": 1, "searchTerms": 1, "region": 0, "comment": 1 } }, "bing": { "label": "Bing", "charts": { "impressions": 1, "clicks": 1, "ctr": 1, "cpc": 1, "conversions": 1, "revenue": 1, "conv_rate": 1, "cost_conversion": 1, "searchTerms": 1, "region": 0, "comment": 1 } }, "stackadapt": { "label": "Stackadapt", "charts": { "impressions": 1, "clicks": 1, "ctr": 1, "cpc": 1, "conversions": 1, "revenue": 1, "conv_rate": 1, "cost_conversion": 1, "searchTerms": 1, "region": 0, "comment": 1 } } } }

                    setProfile(profile);
                    setSettings(profile.settings);
                    setSettingsOpen(true);
                }



                const img = new Image();
                img.crossOrigin = 'Anonymous'; // This is important for loading images from external URLs
                img.src = appVars.api_url + '/imageFiles/' + profileID + '.jpeg'; // Replace with your image's URL
                img.onload = () => {
                    const colorThief = new ColorThief();
                    let palette = colorThief.getPalette(img, 6);
                    //  console.log(palette);
                    setColors(palette); // Logs the color palette
                };

                setLoading({ status: false, progress: 45 });


            })
            .catch((err) => {
                console.log(err);
            });






    }


    useEffect(() => {
        fetchData();
        return () => {

        };
    }, []);


    return (
        <div>

            {(profile ?
                <Formik
                    enableReinitialize
                    initialValues={client}
                    onSubmit={async (values) => {
                        setLoading({ status: true, progress: 15, color: 'success' });
                        await new Promise((r) => setTimeout(r, 500));
                        var url = appVars.api_url + "/gtm/update/";
                        if (values.pkRecId === "0") {
                            url = appVars.api_url + "/gtm/add/";
                            setLoading({ status: true, progress: 35, color: 'success' });
                        }
                        setLoading({ status: true, progress: 35, color: 'success' });
                        console.log(JSON.stringify(values));
                        setSaveMsg({ status: true, msg: 'Data has been saved!', severity: 'success' });
                        setLoading({ status: true, progress: 55, color: 'warning' });
                        //  return;
                        const response = fetch(url, {
                            method: 'POST', // *GET, POST, PUT, DELETE, etc.
                            body: JSON.stringify(values), // body data type must match "Content-Type" header,
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        });
                        response
                            .then(users => {
                                setLoading({ status: false, progress: 35, color: 'success' });
                                // window.location.href='/clientview/'+clientData.client;
                                setSaveMsg({ status: true, msg: 'Data has been saved!', severity: 'success' });
                                ////console.log(users.body);
                                fetchData();
                            }).err(err => {
                                setLoading({ status: true, progress: 35, color: 'error' });
                                setSaveMsg({ status: true, msg: err, severity: 'error' });

                            })

                    }}>
                    <Form id="clientForm">
                       <ClientToolbar client={client} fetchData={fetchData} />
                        {(loadingData.status === true ? <LinearProgress style={{ height: theme.spacing(2) }} title='Loading...' variant="buffer" valueBuffer={loadingData.progress + (loadingData.progress * .50)} value={loadingData.progress} color={loadingData.color} /> : '')}
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={tab} onChange={handleChange} variant="standard" aria-label="basic tabs example">
                                    <Tab label="General" {...a11yProps(0)} />
                                    <Tab label="Technology" {...a11yProps(1)} />
                                    <Tab label="Agency" {...a11yProps(2)} />
                                    <Tab label="Reports" {...a11yProps(3)} />
                                </Tabs>

                            </Box>
                            <CustomTabPanel theme={theme} value={tab} index={0}>
                                <Box mb={2} p={1} borderBottom="1px solid #c4c4c4" display="flex" alignContent="center" alignItems="center" justifyContent="flex-end"><Settings color="info" sx={{ mr: .5, p: 0 }} /> <Typography variant="h5">General Settings</Typography></Box>

                                <Grid container flexDirection="row-reverse" spacing={5}  >
                                    <Grid size={{ xs: 12, md: 3 }}>
                                        <Box boxShadow={'0px 0px 25px -15px '+ theme.palette.secondary.main} borderRadius={2} border={"1px solid #c7c7c7" } p={2} mb={2}>
                                            <Field disabled={(admintoken.token >= 8 ? false : true)} variant="outlined" size="small" id="status" name="status" label="Active?" helperText="Is the client active?" fullWidth component={MySelectInput} />


                                        </Box>
                                    </Grid>
                                    <Grid size={{ xs: 12, md: 9 }}>
                                        <Box boxShadow={'0px 0px 25px -15px '+ theme.palette.secondary.main} borderRadius={2} border={"1px solid #c7c7c7"} p={2} mb={2} >
                                            <Field size="small" id="name" name="name" placeholder="Display Name" component={MyInput} label="Display Name" fullWidth helperText="Enter Display Name" />
                                            <Field InputProps={{
                                                endAdornment: (
                                                    (admintoken.token >= 10 ?
                                                        <IconButton title='Click to rescan the domains tags' size='small' color="primary" onClick={snagTheDomain}>
                                                            <TbAdjustmentsCancel />
                                                        </IconButton> : null)
                                                )
                                            }} size="small" id="domains" name="domains" placeholder="example.com,commerce.example.com" label="Domains to Track" fullWidth component={MyInput} helperText="Enter Domains to track" />
                                            <Field InputProps={{
                                                startAdornment: (
                                                    <IconButton size='small' color="primary" onClick={(e) => {
                                                        window.open(document.getElementById('siteUrl').value, '_BLANK');
                                                    }}>
                                                        <GrView />
                                                    </IconButton>
                                                ),
                                                endAdornment: (
                                                    <IconButton size='small' color="primary" onClick={(e) => {
                                                        window.open(document.getElementById('siteUrl').value, '_BLANK');
                                                    }}>
                                                        <img
                                                            style={{ maxHeight: 50, padding: 1 }}
                                                            crossOrigin="use-credentials"
                                                            src={appVars.api_url + '/imageFiles/' + client.sitetype + '.png'} // Update with your website thumbnail path
                                                            alt="Website Thumbnail"

                                                        />
                                                    </IconButton>
                                                )
                                            }} size="small" id="siteUrl" name="siteUrl" placeholder="example.com" label="Website URL" fullWidth component={MyInput} helperText="Enter Website URL" />
                                            <Field disabled={(admintoken.token >= 10 ? false : true)} size="small" id="registrar" name="registrar" placeholder="example.com,commerce.example.com" label="Domain Registrar" fullWidth component={MyInput} helperText="Where is the domain Registered?" />
                                        </Box>
                                    </Grid>

                                </Grid>


                            </CustomTabPanel>
                            <CustomTabPanel theme={theme} value={tab} index={1} data-title="Technology">
                                <Box mb={2} p={1} borderBottom="1px solid #c4c4c4" display="flex" alignContent="center" alignItems="center" justifyContent="flex-end"><Settings color="info" sx={{ mr: .5, p: 0 }} /> <Typography variant="h5">Technology Settings</Typography></Box>

                                <Grid container spacing={1}  >
                                    <Grid size={{ sm: 6 }} alignContent="center" >
                                        <Box boxShadow={'0px 0px 25px -15px '+ theme.palette.secondary.main} borderRadius={2} border={"1px solid #c7c7c7"} p={2} mb={2} >
                                            <Field fullWidth size="small" id="sitetype" name="sitetype" placeholder="" label="Site platform" component={PlatformSearch} helperText="What CMS is used?" />

                                            <Field fullWidth size="small" id="donationtype" name="donationtype" placeholder="" label="Donation platform" component={PlatformSearch} helperText="What DonationPlatform is used?" />
                                            <Field fullWidth size="small" id="scriptName" name="scriptName" placeholder="" label="Script Name" component={MyInput} helperText="What Script is used for tracking?" />
                                            <Field InputProps={{
                                                startAdornment: (
                                                    (admintoken.token >= 10 && bdisplay ?
                                                        <IconButton size='small' color="primary" onClick={(e) => {
                                                            var _d = {
                                                                "method": "SitesManager.addSite",
                                                                "module": "API",
                                                                "ecommerce": 1,
                                                                "siteSearch": 1,
                                                                "currency": "USD",
                                                                "format": "JSON",
                                                                "token_auth": appVars.matomo_token,
                                                                "siteName": client.name,
                                                                "urls": client.siteUrl
                                                            };

                                                            const burl = appVars.matomo_url + "/index.php";

                                                            const bresponse = fetch(burl, {
                                                                method: 'POST', // Change to POST
                                                                headers: {
                                                                    'Content-Type': 'application/x-www-form-urlencoded' // Set appropriate content type
                                                                },
                                                                body: new URLSearchParams(_d).toString() // Convert the data to URL-encoded format
                                                            });

                                                            bresponse
                                                                .then(response => response.json()) // Parse the JSON response
                                                                .then(data => {
                                                                    // Handle the response data
                                                                    console.log(data);
                                                                    // For example: client.beaconId = data.value;
                                                                    // setClient(client);
                                                                    setBeaconDisplay(true);
                                                                })
                                                                .catch(err => console.error(err)); // Handle any errors





                                                        }}>
                                                            <MdAddCircle />
                                                        </IconButton> : null)
                                                )
                                            }}
                                                size="small" id="beaconId" name="beaconId" placeholder="" label="Assigned Beacon" fullWidth component={MyMamoto} client={client} sites helperText="Enter Assigned Beacon" />
                                        </Box>
                                    </Grid>

                                </Grid>
                            </CustomTabPanel>
                            <CustomTabPanel theme={theme} value={tab} index={2} data-title="Agency">
                                <Box display="flex" gap={2}>
                                    <Box width={'50%'} >
                                        <Box  mb={2} p={1} borderBottom="1px solid #c4c4c4" display="flex" alignContent="center" alignItems="center" justifyContent="flex-end"><Settings color="info" sx={{ mr: .5, p: 0 }} /> <Typography variant="h5">Agency Settings</Typography></Box>
                                        <Box boxShadow={'0px 0px 25px -15px '+ theme.palette.secondary.main} borderRadius={2} border={"1px solid #c7c7c7"} p={2} mb={2} >
                                            <Field size="small" id="client" name="client" placeholder="" label="Assigned Agency" fullWidth component={AgencyListing} setClient={UpdateClient} helperText="Enter Assigned Agency" />
                                            <Field size="small" id="vertical" name="vertical" label="Vertical" fullWidth component={VerticalSearch} helperText="Vertical" />
                                        </Box>
                                    </Box>
                                    <Box width={'50%'}>
                                        <Box mb={2} p={1} borderBottom="1px solid #c4c4c4" display="flex" alignContent="center" alignItems="center" justifyContent="flex-end"><Settings color="info" sx={{ mr: .5, p: 0 }} /> <Typography variant="h5">User Settings</Typography></Box>

                                        {(admintoken.token >= 8 ?

                                            <Box boxShadow={'0px 0px 25px -15px '+ theme.palette.secondary.main} borderRadius={2} border={"1px solid #c7c7c7"} p={2} mb={2} >

                                                <Field size="small" id="owner" name="owner" placeholder=" " label="Assigned Owner"
                                                    onChange={(e) => {
                                                        setOwner(e.target.value);
                                                        //console.log(e.target.value);
                                                    }} fullWidth component={UserSearch} client={client.client} helperText="Enter Assigned Client owner" />


                                                <Field size="small" id="support" name="support" placeholder=" " label="Assigned Support User"
                                                    onChange={(e) => {
                                                        setSupport(e.target.value);
                                                        //console.log(e.target.value);
                                                    }} fullWidth component={UserSearch} client={client.client} helperText="Enter Assigned Client Support User" />

                                            </Box>

                                            : <Box p={2} borderRadius={2} bgcolor={'lightgrey'} border={'1px solid red'} ><Typography color='error' variant='body2'>Admin Access Needed for User Assigments</Typography></Box>)}
                                    </Box>
                                </Box>
                            </CustomTabPanel>
                            <CustomTabPanel theme={theme} value={tab} index={3} data-title="Reports">
                                <Box mb={2} p={1} borderBottom="1px solid #c4c4c4" display="flex" alignContent="center" alignItems="center" justifyContent="flex-end"><Settings color="info" sx={{ mr: .5, p: 0 }} /> <Typography variant="h5">Report Settings</Typography></Box>
                                {(profile ?
                                    <>
                                        <Grid container spacing={1}>

                                            <Grid item size={{ sm: 3 }}>
                                                <Box boxShadow={'0px 0px 25px -15px '+ theme.palette.secondary.main} borderRadius={2} border={"1px solid #c7c7c7"} p={2} mb={2} >
                                                    <Typography color="textPrimary" gutterBottom variant="h6" >Logo File</Typography>
                                                    <img id="logoFile" onError={(e => {
                                                        e.target.src = 'https://placehold.co/200x100/' + profile.color.primary.replace('#') + '/png?text=No+Logo';
                                                    })} src={appVars.api_url + '/imageFiles/' + profileID + '.png'} style={{ backgroundColor: "#f7f7f7", width: 'auto', padding: 15, maxWidth: 350, height: 'auto' }} alt={client.name} />

                                                    <FileUploads
                                                        showButton={true}
                                                        text="Upload Logo"
                                                        suggest="200px x 100px PNG ONLY"
                                                        admintoken={admintoken}
                                                        themeColor={profile.color}
                                                        newid={id}
                                                        childToParent={(e) => {
                                                            //    console.log(e);
                                                            updateLogoFile(appVars.api_url + '/imageFiles/' + id + '.png');
                                                        }} />
                                                </Box>
                                                <Box boxShadow={'0px 0px 25px -15px '+ theme.palette.secondary.main} borderRadius={2} border={"1px solid #c7c7c7"} p={2} mb={2} >
                                                    <Typography color="textPrimary" gutterBottom variant="h6" >Colors</Typography>
                                                    <MuiColorInput
                                                        type="text"
                                                        variant="outlined"
                                                        format="hex"
                                                        fullWidth
                                                        size='small'
                                                        label="Primary"
                                                        defaultValue={profile.color.primary}
                                                        value={profile.color.primary}

                                                        InputProps={{
                                                            style: {
                                                                border: '1px solid ' + profile.color.secondary, borderRadius: '4px',
                                                                background: profile.color.primary, color: profile.color.secondary
                                                            }
                                                        }}
                                                        onChange={(e) => {

                                                            var k = profile.color;
                                                            k.primary = e;
                                                            // console.log(k);
                                                            // Create a new profile object with the updated color
                                                            const updatedProfile = {
                                                                ...profile,
                                                                color: k,
                                                            };

                                                            // Update the profile state with the new object
                                                            setProfile(updatedProfile);
                                                            //  console.log(updatedProfile);
                                                            // Assuming setColor is a setter function for another state variable that tracks the selected color
                                                            setColor(k);
                                                        }}
                                                        style={{ marginBottom: theme.spacing(2) }}
                                                    />
                                                    <MuiColorInput
                                                        type="text"
                                                        variant="outlined"
                                                        format="hex"
                                                        fullWidth
                                                        size='small'
                                                        label="Secondary"
                                                        defaultValue={profile.color.secondary}
                                                        value={profile.color.secondary}
                                                        InputProps={{
                                                            style: {
                                                                border: '1px solid ' + profile.color.secondary, borderRadius: '4px',
                                                                background: profile.color.primary, color: profile.color.secondary
                                                            }
                                                        }}
                                                        onChange={(e) => {

                                                            var k = profile.color;
                                                            k.secondary = e;
                                                            //console.log(k);
                                                            // Create a new profile object with the updated color
                                                            const updatedProfile = {
                                                                ...profile,
                                                                color: k,
                                                            };

                                                            // Update the profile state with the new object
                                                            setProfile(updatedProfile);
                                                            console.log(updatedProfile);
                                                            // Assuming setColor is a setter function for another state variable that tracks the selected color
                                                            setColor(k);
                                                        }}
                                                        style={{ marginBottom: theme.spacing(1) }}
                                                    />
                                                </Box>
                                            </Grid>

                                            <Grid item size={{ sm: 9 }}>

                                                {
                                                    Object.entries(settings.ad_sets).map((entry, i) => {

                                                        var f;


                                                        switch (entry[0]) {
                                                            case 'google_paid':
                                                                f = <GoogleAccounts clientid={client.client} token={(entry[1].token ? entry[1].token : ['2620480222'])} admintoken={admintoken.token} themeColor={color} onSelectionChange={handleChange} size="small" tab={"Google Paid"} id={entry[0]} name={entry[0]} placeholder={"Google Grant"} label={"Google Paid"} defaultValue={(entry[1] ? entry[1].id : '')} fullWidth />
                                                                break;

                                                            case 'google_grant':
                                                                f = <GoogleAccounts token={(entry[1].token ? entry[1].token : ['2620480222'])} admintoken={admintoken.token} themeColor={color} onSelectionChange={handleChange} size="small" tab={"Google Grant"} id={entry[0]} name={entry[0]} placeholder={"Google Grant"} label={"Google Grant"} defaultValue={(entry[1] ? entry[1].id : '')} fullWidth />
                                                                break;

                                                            case 'bing':
                                                                f = <AdAccounts admintoken={admintoken.token} themeColor={color} onSelectionChange={handleChange} size="small" tab={"Bing"} id={entry[0]} name={entry[0]} placeholder={"Bing"} label={"Bing"} defaultValue={(entry[1] ? entry[1].id : '')} fullWidth />
                                                                break;

                                                            case 'meta':
                                                                f = <AdAccounts admintoken={admintoken.token} themeColor={color} onSelectionChange={handleChange} size="small" tab={"Meta"} id={entry[0]} name={entry[0]} placeholder={"Meta"} label={"Meta"} defaultValue={(entry[1] ? entry[1].id : '')} fullWidth />
                                                                break;

                                                            case 'stackadapt':
                                                                f = <StackAdaptTokens
                                                                    setSelectedApiKey={setSelectedApiKey}
                                                                    saKey={profile.saKey}
                                                                    admintoken={admintoken.token}
                                                                    themeColor={color}
                                                                    size="small"
                                                                    style={{ marginBottom: theme.spacing(1) }}
                                                                    tab={entry[0]}
                                                                    id={entry[0]}
                                                                    name={entry[0]}
                                                                    placeholder={"StackAdapt"}
                                                                    label={"StackAdapt"}
                                                                    defaultValue={(entry[1] ? entry[1].id : '')}
                                                                    fullWidth
                                                                />
                                                                break;

                                                            default:
                                                                f = <Typography>{entry[0]}</Typography>;
                                                                break;
                                                        }


                                                        //console.log('entry', entry);
                                                        return (
                                                            <Box boxShadow={'0px 0px 25px -15px #000000'} borderRadius={2} border={"1px solid #c7c7c7"} p={2} mb={2} >{f}</Box>
                                                        )
                                                    })
                                                }

                                            </Grid>
                                        </Grid>

                                    </>

                                    : '')}

                            </CustomTabPanel>

                        </Box>

                    </Form>

                </Formik>

                : '')}


            <Snackbar anchorOrigin={{ horizontal: "right", vertical: "bottom" }} open={saveMsg.status} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={saveMsg.severity}>
                    {saveMsg.msg} <br></br>
                </Alert>
            </Snackbar>

        </div>
    );
};

ClientSettings.propTypes = {

};

export default ClientSettings;
