import React, { useState, useEffect } from "react";
import { AppBar, Toolbar, Typography, CssBaseline, Box, Card, CardContent, CardHeader, CardActions, Button } from "@mui/material";
import { styled } from "styled-components";
import { FileCopy, OpenInBrowser } from "@mui/icons-material";
import appVars from "../../../appVars.json";
import Prism from "prismjs";
import Logo from "../../../images/gtm-icon.png";


const getLastItem = (thePath) => thePath.substring(thePath.lastIndexOf("/") + 1);
const clientID = getLastItem(window.location.pathname);

//console.log('clientID', clientID);

const ContentBox = styled(Box)`
  flex-grow: 1;
  padding: 16px;
`;

const CardStyled = styled(Card)`
  margin-top: 16px;
`;

const CardHeaderStyled = styled(CardHeader)`
  font-size: 1.25em;
  background-color: #cbc9ca;
  min-height: 80px;
`;

const ContentBG = styled(CardContent)`
  background-color: #000;
  min-height: 135px;
`;

const GtmComponent = () => {
  const [gtm, setGTM] = useState({ name: "Loading...", script: "" });
  const [script, setScript] = useState({ script: "Loading..." });




  useEffect(() => {
    async function fetchData() {
      try {
        const res = await fetch(`${appVars.api_url}/tagmanagers/${clientID}`);
        const data = await res.json();
        setGTM(data);
        appVars.gtmScript = appVars.gtmScript.replace("%%gtmid%%", data.tag);
        appVars.gtmImage = appVars.gtmImage.replace("%%gtmid%%", data.tag);
        setScript({ script: appVars.gtmScript });
        Prism.highlightAll();
        console.log('appVars',script.script);
      } catch (error) {
        console.error("Error fetching GTM data", error);
      }
    }
    fetchData();
  }, []);

  return (
    <Box display="flex" flexDirection="column" alignItems="left" p={2}>
      <Box display={'flex'} alignItems={'center'} >
        <Box pr={3}>
          <img src={Logo} alt="" />
        </Box>
        <Box >
          <Typography variant="h5" noWrap>
            Google Tag Manager Install Instructions
          </Typography>
        </Box>
      </Box>

      <ContentBox display={'flex'} flexDirection={'row'} >
        <Box>
          <Typography variant="h4">ID: {gtm.tag}</Typography>
          <Typography sx={{ paddingBottom: '8px' }} gutterBottom variant="body1">Google Tag Manager (GTM) simplifies the process of managing various tracking codes, or "tags," on a website. The GTM container snippet, a concise piece of both JavaScript and non-JavaScript code, is integrated into your web pages. This snippet empowers Tag Manager to trigger specific tags dynamically.</Typography>
          <Typography sx={{ paddingBottom: '8px' }} gutterBottom variant="body1">By inserting the gtm.js script into the page or utilizing an iframe when JavaScript is unavailable, GTM provides a centralized platform for efficiently deploying and managing tags. These tags may include analytics scripts, conversion tracking, or other snippets essential for tracking user interactions and gathering valuable data.</Typography>
          <Typography sx={{ paddingBottom: '8px' }} gutterBottom variant="body1">In essence, Google Tag Manager streamlines the deployment and management of diverse tracking codes, offering a more organized and flexible approach to implementing and updating website tags. This not only simplifies the task of tracking various metrics but also enhances the overall efficiency and maintainability of your website's analytics infrastructure.</Typography>
        </Box>
      </ContentBox>
      <ContentBox display={'flex'} flexDirection={'row'} >
        <CardStyled>
          <CardHeaderStyled subheader="Copy the following JavaScript and paste it as close to the opening <head> tag as possible on every page of your website." />
          <ContentBG>
            <code className="language-html">{script.script}</code>
          </ContentBG>
          <CardActions>
            <Button size="small" variant="outlined" endIcon={<FileCopy />} color="primary">
              Copy to Clipboard
            </Button>
            <Button size="small" variant="outlined" endIcon={<OpenInBrowser />} color="primary" target="_blank" href={gtm.cms_link}>
              {gtm.cms_name} Instructions
            </Button>
            <Button size="small" variant="outlined" endIcon={<OpenInBrowser />} color="primary" target="_blank" href={gtm.link}>
              {gtm.site} Instructions
            </Button>
          </CardActions>
        </CardStyled>
      </ContentBox>
    </Box>
  );
};

export default GtmComponent;
