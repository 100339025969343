import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Typography,
    Button,
    Snackbar,
    Avatar,
    Divider,
    MenuItem,
    TextField,
    Alert,
    Badge,
} from "@mui/material";
import { Box, styled } from "@mui/system";
import Link from "@mui/material/Link";
import ClipboardJS from "clipboard";
import appVars from "../../appVars.json";
import { BsEye, BsMeta, BsBing, BsDisplay } from "react-icons/bs";
import { FaGoogle, FaLongArrowAltRight } from "react-icons/fa";
import _ from "lodash";
import moment from "moment";
import CookieReader from "../cookie-reader";
import { useToken } from '../../Login/TokenProvider';
import md5 from "md5";
import ConversionToolbar from "../conversions/toolbar";
import { render } from "@testing-library/react";


const StyledPaper = styled(Box)(({ theme, themeColor }) => ({
    padding: theme.spacing(1),
    background: theme.palette.secondary.main,
}));

const Registrations = (props) => {

    const _d = new Date();
    _d.setDate(_d.getDate() - 7);

    const [selectedDateS, setSelectedDateS] = React.useState(moment(_d).format("YYYY-MM-DD"));
    const [selectedDateE, setSelectedDateE] = React.useState(moment(new Date()).format("YYYY-MM-DD"));



    const handleRangeSelect = (rangeKey) => {
        setSelectedDateS(rangeKey.startDate.toISOString().split('T')[0]);
        setSelectedDateE(rangeKey.endDate.toISOString().split('T')[0]);
    };





    const [data, setData] = useState(props);
    const [hasError, setErrors] = useState(false);
    const [copySuccessShow, setCopySuccessShow] = useState(false);
    const [users, setUsers] = useState([]);
    const [pageSize, setPageSize] = useState(props.pageSize);
    const [user, setUser] = useState([]);
    const [apiStats, setApiStats] = useState({
        client_id: "",
        total_records: 0,
        name_percentage: 0,
        city_percentage: 0,
        state_percentage: 0,
        zip_percentage: 0,
        phone_percentage: 0,
        email_percentage: 0,
        fb_percentage: 0,
        fbc_percentage: 0,
        ga_percentage: 0,
        sa_percentage: 0,
        adwords_percentage: 0,
        bing_percentage: 0,
    });
    const [open, setOpen] = useState(false);

    const [loading, setLoading] = useState(true);
    const [copySuccess, setCopySuccess] = useState("");
    const token = useToken();
    const admintoken = token.token.token;
    const id = sessionStorage.getItem('cid');

    //console.log('AT',admintoken)


    const columns = (admintoken >= 10 ? [
        {
            title: "Entry Date", field: "createdAt", type: "datetime", render: e =>
                <Typography style={{ minWidth: 175 }}>{moment(e.createdAt).format("MM-DD-YYYY hh:mm:s")}</Typography>
        },
        { title: "pkRecID", field: "pkRecId", hidden: true, searchable: true },
        {
            title: "", field: 'pkRecId', render: (e) => {
                return (<Badge color={(e.fbtraceid ? 'success' : 'error')} overlap="circular" badgeContent={(e.fbtraceid ? 's' : 'f')}  ><Avatar
                    variant="square"
                    src={(e.email ? 'https://www.gravatar.com/avatar/' + md5(e.email.trim().toLowerCase()) + '?s=200&d=mp' : <Avatar />)}
                    alt=""
                    width='100%'
                    style={{ borderRadius: 8 }}
                /></Badge>)
            }
        },
        { title: "First Name", field: "first" },
        { title: "Last Name", field: "last" },
        { title: "Email Address", field: "email" },
        { title: "Phone", field: "phone" },

        { title: "$", field: "revenue" },
        { hidden: true, title: "Source", field: "source" },
        {
            title: "User", field: "url", render: e => {
                var k = JSON.parse(e.cookie);
                if (k !== null) {
                    return (
                        <div style={{ minWidth: 125, display: 'inline-flex', alignItems: 'center' }}>
                            {k._fbp && (
                                <>
                                    <BsMeta

                                        title={k._fbc ? 'Meta Conversion' : 'Meta User'}
                                        style={{ color: k._fbc ? 'green' : '' }}
                                    />
                                    <FaLongArrowAltRight />
                                </>
                            )}
                            {k._ga && (
                                <>
                                    <FaGoogle />
                                    <FaLongArrowAltRight />
                                </>
                            )}
                            {k._gclid && (
                                <>
                                    <FaGoogle />
                                    <FaLongArrowAltRight />
                                </>
                            )}
                            {k._uetsid && (
                                <>
                                    <BsBing

                                        style={k._uetsid ? {} : { filter: 'grayscale(100%)' }}
                                    />
                                    <FaLongArrowAltRight />
                                </>
                            )}
                            {k['sa-user-id'] && (
                                <BsDisplay

                                    style={k['sa-user-id'] ? {} : { filter: 'grayscale(100%)' }}
                                />
                            )}
                        </div>
                    );
                }

            }
        },
        {
            title: "view user", field: "createdAt", type: "datetime", render: rowData =>
                <Button variant="outlined" color="primary" startIcon={<BsEye></BsEye>} onClick={(e) => {
                    setUser(rowData);
                    setOpen(true);
                }}>View</Button>
        },
        {
            title: "Location", field: "url", render: e =>
                <Typography><Link target="_blank" href={e.url}>Location</Link></Typography>
        }


    ] :
        [
            {
                title: "Entry Date", field: "createdAt", type: "datetime", render: e =>
                    <Typography style={{ minWidth: 175 }}>{moment(e.createdAt).format("MM-DD-YYYY hh:mm:s")}</Typography>
            },
            { title: "$", field: "revenue" },
            { hidden: true, title: "Source", field: "source" },
            {
                title: "User", field: "url", render: e => {
                    var k = JSON.parse(e.cookie);
                    if (k !== null) {
                        // console.log(k);
                        return <div style={{ minWidth: 125 }}>
                            {(k._fbp !== "" && k._fbp !== undefined ? <BsMeta title={(k._fbc !== '' && k._fbc !== undefined ? 'Meta Conversion' : 'Meta User')} style={{ color: (k._fbc !== '' && k._fbc !== undefined ? 'green' : '') }}></BsMeta> : '')}
                            {(k._ga !== '' && k._ga !== undefined ? <FaGoogle ></FaGoogle> : '')}{(k._gclid !== '' && k._gclid !== undefined ? <FaGoogle /> : '')}
                            {(k._uetsid !== "" && k._uetsid !== undefined ? <BsBing style={(k._uetsid !== '' && k._uetsid !== undefined ? {} : { filter: 'grayscale(100%)' })} /> : '')}
                            {(k['sa-user-id'] !== "" && k['sa-user-id'] !== undefined ? <BsDisplay style={(k['sa-user-id'] !== '' && k['sa-user-id'] !== undefined ? {} : { filter: 'grayscale(100%)' })} /> : '')}

                        </div>
                    }
                }
            },
            {
                title: "Entry Date", field: "createdAt", type: "datetime", render: rowData =>
                    <Button variant="outlined" color="secondary" startIcon={<BsEye></BsEye>} onClick={(e) => {
                        setUser(rowData);
                        setOpen(true);
                    }}>View</Button>
            },
            {
                title: "Location", field: "url", render: e =>
                    <Typography><Link target="_blank" href={e.url}>Location</Link></Typography>
            }


        ]);

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setErrors(false);
        setCopySuccessShow(false);
    };

    async function fetchData() {

        try {
            const res = await fetch(
                `${appVars.lightwerks}/contact/`,
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        "cid": id,
                        "lim": pageSize,
                        "sDate": moment(selectedDateS).format("YYYY-MM-DD") + ' 00:00:00',
                        "eDate": moment(selectedDateE).format("YYYY-MM-DD") + ' 23:59:59'
                    }),
                    redirect: "follow"
                }
            );
            const result = await res.json();
            setUsers(result);
            setLoading(false);

            //console.log('Sdate', selectedDateS);

            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ cid: id }),
            };
            const res2 = await fetch(
                `${appVars.lightwerks}/apistats`,
                requestOptions
            );
            const apiData = await res2.json();
            setApiStats(apiData);
            setLoading(false);
        } catch (error) {
            setErrors(true);
        }
    }

    useEffect(() => {
        fetchData();
        setData(props);

        const clipboard = new ClipboardJS(".copy");
        clipboard.on("success", (e) => {
            setCopySuccess(`${e.action}: ${e.text}`);
            setCopySuccessShow(true);
            e.clearSelection();
        });
    }, [selectedDateS, selectedDateE, pageSize]);

    return (
        <Box>
            <ConversionToolbar setSelectedDateS={setSelectedDateS} setSelectedDateE={setSelectedDateE} pageSize={pageSize} setPageSize={setPageSize} />
            <Box>
                <Box p={1} display="flex" flexDirection="row" gap={1}>
                    <Box width='100%' >
                        <MaterialTable
                            key={users.length}
                            isLoading={loading}
                            columns={columns}
                            data={users}
                            options={{
                                rowStyle: { fontSize: "13px", padding: 0, margin: 0 },
                                headerStyle: { fontSize: "13px" },
                                grouping: true,
                                exportButton: true,
                                exportAllData: true,
                                exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                pageSize: pageSize,
                                pageSizeOptions: [10, 25, 50, 75, 100, 200, 500, 1000],
                                showTitle: false,
                            }}
                        />
                    </Box>

                </Box>
            </Box>

            <Dialog
                fullWidth
                maxWidth="xs"
                aria-labelledby="confirmation-dialog-title"
                open={open}
            >
                <DialogTitle id="confirmation-dialog-title">
                    <Box justifyItems={'flex-end'} >
                        <CookieReader cookie={user.cookie} />
                    </Box>
                </DialogTitle>
                <DialogContent dividers>
                    <Box display="flex" flexDirection="row" gap={1}>
                        <Box sx={{
                            width: { md: '20%' },

                        }} >
                            <Avatar
                                variant="rounded"
                                src={
                                    user.email
                                        ? `https://www.gravatar.com/avatar/${md5(
                                            user.email.trim().toLowerCase()
                                        )}?s=75&d=mp`
                                        : <Avatar />
                                }
                            />
                        </Box>
                        <Box sx={{
                            p: 1,
                            width: { md: '80%' },
                            boxShadow: '0px 0px 24px -15px #000000'
                        }}>
                            <Typography>{user.first} {user.last}</Typography>
                            <Typography>{user.email}</Typography>
                            <Typography>{user.city} {user.state}</Typography>
                            <Typography>{user.zip} {user.phone}</Typography>
                            {/* Other user info */}
                        </Box>
                    </Box>
                    <Divider />


                </DialogContent>
                <DialogActions>
                    <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        onClick={() => setOpen(false)}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={hasError} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    The data cannot be pulled at this time.
                    <Button color="secondary" onClick={() => window.location.reload()}>
                        Refresh
                    </Button>
                </Alert>
            </Snackbar>

            <Snackbar
                open={copySuccessShow}
                autoHideDuration={3000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity="success">
                    {copySuccess}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default Registrations;
