import React, { useState, useEffect } from 'react';
import { Box, Button, IconButton, TextField } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { useLocation } from 'react-router-dom';
import { FcCalendar } from 'react-icons/fc';
import moment from 'moment';

const DateRangeSelector = ({ sDate, eDate, icon, childToParent }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sdateParam = searchParams.get('sdate');
  const edateParam = searchParams.get('edate');

  const normalizeDate = (dateStr, fallback) => {
    const date = dateStr ? new Date(dateStr) : new Date(fallback);
    return moment(date);
  };

  const [value, setValue] = useState([
    normalizeDate(sdateParam, sDate),
    normalizeDate(edateParam, eDate),
  ]);

  useEffect(() => {
    if (childToParent) {
      childToParent({ startDate: value[0].toDate(), endDate: value[1].toDate() });
    }
  }, [value, childToParent]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {icon ? (
          <IconButton onClick={() => {}}>
            <FcCalendar size={30} />
          </IconButton>
        ) : (
          <DateRangePicker
            startText="Start"
            endText="End"
            value={value}
            onChange={(newValue) => setValue(newValue)}
            renderInput={(startProps, endProps) => (
              <>
                <TextField {...startProps} size="small" sx={{ mr: 2 }} />
                <TextField {...endProps} size="small" />
              </>
            )}
          />
        )}
      </Box>
    </LocalizationProvider>
  );
};

export default DateRangeSelector;