import { useState, useEffect } from "react";
import {
    Box,
    Link,

    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,
    DialogActions,
    Typography,
    Divider,
    MenuItem
} from '@mui/material';
import { Add, Cancel, Check } from '@mui/icons-material';
import { styled, useTheme } from '@mui/system';
import Prism from 'prismjs';
import appVars from '../../../appVars.json';



function isWithinLast24Hours(dateInput) {
    let givenDate;

    // Ensure the input is parsed as a UTC date.
    if (typeof dateInput === 'string') {
        // Append "Z" if there's no timezone information
        const utcString = /[Z\\]/.test(dateInput) ? dateInput : dateInput + 'Z';
        givenDate = new Date(utcString);
    } else {
        givenDate = dateInput;
    }

    // Get the current date in UTC.
    const now = new Date();
    // console.log('Input', dateInput);
    // console.log('Now', now);
    // console.log('Given', givenDate);

    // Compare the year, month, and day using UTC values.
    return (
        now.getUTCFullYear() === givenDate.getUTCFullYear() &&
        now.getUTCMonth() === givenDate.getUTCMonth() &&
        now.getUTCDate() === givenDate.getUTCDate()
    );
}

function daysSince(dateInput) {
    let givenDate;

    // Ensure the input is parsed as a UTC date.
    if (typeof dateInput === 'string') {
        // Append "Z" if there's no timezone information to treat it as UTC.
        const utcString = /[Z\\]/.test(dateInput) ? dateInput : dateInput + 'Z';
        givenDate = new Date(utcString);
    } else {
        givenDate = dateInput;
    }

    // Get the current date/time.
    const now = new Date();

    // Calculate the difference in milliseconds.
    const diffMs = now - givenDate;

    // Convert milliseconds to days (1 day = 24 * 60 * 60 * 1000 ms).
    const daysPassed = diffMs / (24 * 60 * 60 * 1000);

    // Return the whole number of days that have passed.
    return Math.floor(daysPassed);
}



const SiteTypes = ({ type }) => {
    const theme = useTheme();
    const [ecom, seteCom] = useState([]);
    const [open, setOpen] = useState(false);


    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = () => {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");

        const payload = JSON.stringify({
            site: document.getElementById('site').value,
            trackable: document.getElementById('trackable').value,
            link: document.getElementById('link').value,
            payload: {
                ct: "",
                em: "",
                fn: "",
                ln: "",
                ph: "",
                st: "",
                zp: ""
            },
            type: type === 'ecommerce' ? "donation" : appVars.api_url + "website"
        });

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: payload,
            redirect: 'follow'
        };

        const url = type === 'ecommerce' ? `${appVars.api_url}/sites/donation/add` : `${appVars.api_url}/sites/website/add`;

        fetch(url, requestOptions)
            .then(response => response.text())
            .then(() => fetchData())
            .catch(error => console.error('Error:', error));

        setOpen(false);
    };

    const fetchData = async () => {
        const url = type === 'ecommerce' ? `${appVars.api_url}/sites/donation` : `${appVars.api_url}/sites/website`;
        try {
            const response = await fetch(url);
            const data = await response.json();
            console.log('Ecom Sites',data)
            seteCom(data);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
        Prism.highlightAll();
    }, []);

    return (
        <Box sx={{ p: 2 }}>
            <Button onClick={(e) => {
                e.preventDefault();
                setOpen(true);
            }} >Add</Button>
            <Divider sx={{ m: 1 }} />
            <Box sx={{
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                bgcolor: theme.palette.secondary.light,
                color: theme.palette.primary.contrastText,
                alignItems: 'flex-end',
                p: .5
            }}>
                <Box sx={{

                    width: { xs: '50%', md: '50%' }
                }} >
                    <Typography variant="h6" align='left' gutterBottom>System</Typography></Box>
                <Box sx={{
                    width: { xs: '50%', md: '20%' },
                    display: { md: 'block', xs: 'none' }
                }} >
                    <Typography variant="h6" sx={{
                        textAlign: { md: 'center' }
                    }} gutterBottom>Days Since</Typography></Box>
                <Box sx={{
                    width: { xs: '50%', md: '20%' },
                    display: { md: 'block', xs: 'none' }
                }} >
                    <Typography variant="h6" sx={{
                        textAlign: { md: 'center' }
                    }} gutterBottom>Last fire</Typography></Box>
                <Box sx={{
                    width: { xs: '50%', md: '20%' }
                }}  >
                    <Typography variant="h6" align='right' gutterBottom>Last 24 hours?</Typography></Box>
            </Box>
            {ecom.map((_, index) => {
                // console.log('Ecoms', _);
                return (
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        bgcolor: index % 2 === 1 ? '#c7c7c7' : '#fff',
                        p: { xs: 1, md: .5 },
                        border: '1px solid #c7c7c7'
                    }}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                gap:3
                            }}
                            width={'50%'} >
                            <Typography variant="body1" align='left' width={'100%'} gutterBottom><Link href={'/control/sites/' + _.pkRecID} >{_.site}</Link></Typography>
                            <div
                                style={{
                                    width: '100%',
                                    background: 'url(' + appVars.api_url + '/imageFiles/' + _.pkRecID + '.png' + ')',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'contain',
                                    height: '100%'
                                }}
                                onError={(e) => {
                                    e.target.src = "https://placehold.co/500x300";
                                }} alt='' />
                                 <Typography variant="body1" align='center' width={'100%'} gutterBottom>{_.version}</Typography>
                                </Box>
                        <Box width={'20%'} > <Typography variant="body1" align='center' gutterBottom>{(daysSince(_.lasttrack) > 0 ? daysSince(_.lasttrack) + ' day(s)' : 'Today')}</Typography></Box>
                        <Box width={'20%'} sx={{ display: { md: 'block', xs: 'none' } }} > <Typography variant="body2" align='center' gutterBottom>{new Date(_.lasttrack).toLocaleString("en-US", { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit", hour12: false }).replace(",", "")}</Typography></Box>
                        <Box width={'20%'} > <Typography variant="body2" align='right' gutterBottom>{(isWithinLast24Hours(_.lasttrack) ? <Check size={20} htmlColor='green' /> : <Cancel htmlColor="red" size={20} />)}</Typography></Box>
                    </Box>
                )
            })}


            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    Add new {type === 'ecommerce' ? "Ecommerce" : "Website"} Platform
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To add a new platform, enter the required information below.
                    </DialogContentText>
                    <TextField
                        required
                        autoFocus
                        margin="dense"
                        id="site"
                        label="Platform Name"
                        type="text"
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        id="link"
                        label="Documentation Address"
                        type="text"
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        select
                        margin="dense"
                        id="platform"
                        label="Platform Type"
                        fullWidth
                        value={type === 'ecommerce' ? "donation" : appVars.api_url + "website"}
                        variant="outlined"
                    >
                        <MenuItem value="website">Website Platform</MenuItem>
                        <MenuItem value="donation">Ecommerce Platform</MenuItem>
                    </TextField>
                    <TextField
                        select
                        margin="dense"
                        id="trackable"
                        label="Trackable?"
                        fullWidth
                        variant="outlined"
                    >
                        <MenuItem value="1">Yes</MenuItem>
                        <MenuItem value="0">No</MenuItem>
                    </TextField>
                    <TextField
                        defaultValue={JSON.stringify({
                            ct: "",
                            em: "",
                            fn: "",
                            ln: "",
                            ph: "",
                            st: "",
                            zp: "",
                            revenueLive: ""
                        })}
                        margin="dense"
                        id="payload"
                        label="Default Payload"
                        fullWidth
                        disabled
                        variant="outlined"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="default" variant="contained">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} variant="contained" color="secondary" startIcon={<Add />}>
                        Add New
                    </Button>
                </DialogActions>
            </Dialog>

        </Box>
    );
};

export default SiteTypes;
