import { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Snackbar,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  DialogTitle,
  TextField,
  MenuItem,
  Link,
  Typography,
  Avatar
} from '@mui/material';
import Grid from '@mui/material/Grid';
import Alert from '@mui/lab/Alert';
import Skeleton from '@mui/lab/Skeleton';
import { Edit } from "@mui/icons-material";
import { RiUser2Fill } from 'react-icons/ri';
import appVars from '../appVars.json';



// Styled components
const Root = styled.div`
  min-width: 275px;
`;

const StyledCardMedia = styled(CardMedia)`
  height: 50px;
  background-position: top top;
  background-size: contain;
  background-repeat: no-repeat;
  padding: ${({ theme }) => theme.spacing(0, 2)};
`;

const StyledGridContainer = styled(Grid)`
  padding: ${({ theme }) => theme.spacing(1)};
`;

const StyledDivider = styled(Divider)`
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  margin-left: ${({ theme }) => theme.spacing(1)};
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

// Main UserTable component
const UserTable = (props) => {
  const [users, setUsers] = useState(false);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false); // Add user dialog
  const [edit, setEditOpen] = useState(false); // Edit user dialog
  const [editUser, setEditUser] = useState(null); // User selected for editing
  const [message, setMessage] = useState({ show: false, message: '', severity: 'success' });


  const [clientTypes, setClientType] = useState([
    { value: 1, label: 'Not Set' }
  ]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setOpen(false);
    setEditOpen(false);
    setMessage({ show: false, message: '' });
  };

  const handleEdit = (e) => {
    const user = JSON.parse(e.currentTarget.getAttribute('user'));
    setEditUser(user);
    setEditOpen(true);
  };

  async function fetchData() {
    const url = props.clientid ? `${appVars.api_url}/users/c/${props.clientid}` : `${appVars.api_url}/users`;
    const res = await fetch(url);
    res.json()
      .then(res => {
        setUsers(res);
        setLoading(false);
      })
      .catch(err => setMessage({ show: true, message: err, severity: 'error' }));

    const clientRes = await fetch(`${appVars.api_url}/clients`);
    clientRes.json()
      .then(setClientType)
      .catch(err => setMessage({ show: true, message: err, severity: 'error' }));
  }

  useEffect(() => {
    if (!users) fetchData();
  }, [users]);

  return (
    <Root>

      <StyledGridContainer container>
        <Grid item xl={1} md={2}>
          <Button
            size="small"
            fullWidth
            variant="outlined"
            color="primary"
            startIcon={<RiUser2Fill />}
            onClick={() => setOpen(true)}
          >
            Add User
          </Button>
        </Grid>
      </StyledGridContainer>

      <StyledDivider />

      <StyledGridContainer container spacing={2}>
        {loading ? (
          Array.from({ length: 12 }).map((_, index) => (
            <Grid item xl={2} lg={3} md={4} sm={6} xs={12} key={index}>
              <Card>
                <CardHeader title={<Skeleton animation="wave" width="40%" />} />
                <StyledCardMedia><Skeleton animation="wave" variant="rect" /></StyledCardMedia>
                <CardContent>
                  <Skeleton animation="wave" width="65%" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          users.map((card, index) => (
            <Grid item md={4} sm={6} xs={12} key={index}>
              <Card>
                <CardHeader
                  avatar={<Avatar src={`${appVars.api_url}/imageFiles/${card.pkRecId}.png`} />}
                  title={<Typography variant="body2" color="textPrimary">{card.name}</Typography>}
                />
                <CardContent>
                  <Typography variant="body2" color="primary">
                    <Link href={`mailto:${card.email}`}>{card.email}</Link>
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    user={JSON.stringify(card)}
                    href={'/user/'+card.pkRecId}
                    variant="outlined"
                    startIcon={<Edit />}
                    color="secondary"
                  >
                    Edit
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))
        )}
      </StyledGridContainer>

      {/* Add User Dialog */}
      <Dialog maxWidth="sm" open={open} onClose={handleClose}>
        <DialogTitle>Add New User</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item md={6}><TextField autoFocus size="small" margin="dense" id="name" label="Name" variant="outlined" fullWidth /></Grid>
            <Grid item md={6}><TextField size="small" margin="dense" id="email" label="Email Address" type="email" variant="outlined" fullWidth /></Grid>
            <Grid item md={12}>
              <TextField select margin="dense" id="clientid" label="Select Client" variant="outlined" fullWidth>
                {clientTypes.map(entry => (<MenuItem key={entry.clientId} value={entry.clientId}>{entry.clientname}</MenuItem>))}
              </TextField>
            </Grid>
            <Grid item md={6}><TextField margin="dense" id="passwd" label="Password" type="text" variant="outlined" fullWidth /></Grid>
            <Grid item md={6}><TextField margin="dense" id="confirm" label="Confirm Password" type="text" variant="outlined" fullWidth /></Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button size="small" variant="outlined" color="primary" onClick={() => setOpen(false)}>Cancel</Button>
          <Button size="small" startIcon={<RiUser2Fill />} color="secondary" variant="contained">Add User</Button>
        </DialogActions>
      </Dialog>

      {/* Edit User Dialog */}
      <Dialog maxWidth="sm" open={edit} onClose={handleClose}>
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent dividers>
          {editUser && (
            <Grid container spacing={3}>
              <Grid item md={6}><TextField size="small" margin="dense" id="edit-name" label="Name" variant="outlined" fullWidth defaultValue={editUser.name} /></Grid>
              <Grid item md={6}><TextField size="small" margin="dense" id="edit-email" label="Email Address" type="email" variant="outlined" fullWidth defaultValue={editUser.email} /></Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button size="small" variant="outlined" color="primary" onClick={() => setEditOpen(false)}>Cancel</Button>
          <Button size="small" startIcon={<Edit />} color="secondary" variant="contained">Save Changes</Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={message.show} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={message.severity}>{message.message}</Alert>
      </Snackbar>
    </Root>
  );
};

export default UserTable;
