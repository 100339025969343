import React, { useState } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import themeFile from '../../theme.json';
import { setCookie } from '../../dwi-functions';
import logo from '../../logo.png';
import MuiAlert from '@mui/lab/Alert';
import Snackbar from '@mui/material/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import { Backdrop } from '@mui/material';
import appVars from '../../appVars.json';
import md5 from "md5";


import images from '../../pexels-thisisengineering-3862132.jpg';
import { FcGoogle } from 'react-icons/fc';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            <Link color="inherit" href={appVars.companyUrl}>
                {appVars.companyName}
            </Link> <br />Copyright<sup>{'© '}</sup>
            {' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const GridRoot = styled(Grid)(({ theme }) => ({
    height: "100vh",
    width: "100vw",
}));

const GridImage = styled(Grid)(({ theme }) => ({
    backgroundImage: "url(" + images + ")",
    backgroundRepeat: "no-repeat",

    backgroundColor:
        theme.palette.type === "light"
            ? theme.palette.grey[50]
            : theme.palette.grey[900],

    backgroundSize: "cover",
    backgroundPosition: "center",
}));

const DivPaper = styled("div")(({ theme }) => ({
    margin: theme.spacing(10),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
}));

const StyledForm = styled("form")(({ theme }) => ({
    // Fix IE 11 issue.
    width: "100%",

    marginTop: theme.spacing(1),
}));

const ButtonSubmit = styled(Button)(({ theme }) => ({
    margin: theme.spacing(0),
    maxWidth: "97%",
}));



const ImgLogo = styled("img")(({ theme }) => ({
    maxWidth: "300px",
}));

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
}));

const GridRightside = styled(Grid)(({ theme }) => ({
    background: themeFile.palette.appBar.backgroundSign,
    paddingTop: theme.spacing(0),

    [theme.breakpoints.down("lg")]: {
        paddingTop: "10%",
    },
}));

export default function SignInSide() {

    const theme = createTheme(themeFile);
    const [hasError, setErrors] = useState(false);
    const [open, setOpen] = useState(false);

    async function postData(url, data) {
        const response = await fetch(url, {
            method: 'POST',
            body: data,
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.json();
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        setErrors(false);
    };


    const handleGoogleLogin = () => {
        const clientId = appVars.google_client_id;
        const redirectUri = `${appVars.api_url}/oauth2callback`;
        const scope = 'openid profile email https://www.googleapis.com/auth/tagmanager.edit.containers';

        const oauth2Url = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}&access_type=offline`;

        window.location.href = oauth2Url;
    };






    const handleGoogleFailure = (response) => {
        console.error('Google Sign-In was unsuccessful. Try again later.');
    };

    return (
        <GoogleOAuthProvider clientId={appVars.google_client_id}>
            <ThemeProvider theme={theme}>
                <GridRoot container component="main">
                    <CssBaseline />
                    <GridImage item xs={false} sm={false} md={8} xl={7} />
                    <GridRightside
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        xl={5}
                        justifyContent="center"
                        alignContent="center"
                        alignItems="center"
                        component={Paper}
                        elevation={6}
                        square
                    >
                        <DivPaper>
                            <ImgLogo src={logo} alt="DigitalWerks Logo" />
                            <StyledForm noValidate>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                />
                                <Box display="flex" width="100%" textAlign="center" mt={0}>
                                    <Box width="50%" textAlign="left" mt={1}>
                                        <ButtonSubmit
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                setOpen(true);
                                                const formData = {
                                                    passwd: document.getElementById("password").value,
                                                    email: document.getElementById("email").value,
                                                };
                                                postData(
                                                    appVars.api_url + "/validate",
                                                    JSON.stringify(formData)
                                                ).then((data) => {
                                                    // console.log(data);
                                                    if (data.status === 200 && data.uid.length === 36) {
                                                        setCookie('_aup', JSON.stringify({
                                                            user: {
                                                                name: data.name,
                                                                email: data.email,
                                                                image: "https://www.gravatar.com/avatar/" + md5(data.email.trim().toLowerCase()) + "?d=mp",
                                                            },
                                                        })
                                                            , 7
                                                        )
                                                        localStorage.setItem("_au", data.uid);
                                                        setCookie("_au", data.uid, 1);
                                                        localStorage.setItem("_au", data.uid);
                                                        localStorage.setItem("gravatar", data.gravatar);
                                                        setCookie("gravatar", data.gravatar, 1);
                                                        localStorage.setItem("_auta", data.admin.access);
                                                        setCookie("_auta", data.admin.access, 1);
                                                        localStorage.setItem("_auta_s", data.settings);
                                                        setCookie("_auta_s", data.settings, 1);
                                                        setTimeout(() => {
                                                            setOpen(false);
                                                            window.location = data.settings.redirect;
                                                        }, 500);
                                                    } else {
                                                        setErrors(true);
                                                    }
                                                });
                                            }}
                                        >
                                            Sign In
                                        </ButtonSubmit>
                                    </Box>
                                    <Box width="auto" alignContent="center" textAlign="right" mt={0}>
                                        <Typography variant="body1" align="center">
                                            or
                                        </Typography>
                                    </Box>
                                    <Box width="50%" textAlign="right" mt={1}>
                                        <ButtonSubmit
                                            fullWidth
                                            variant="outlined"
                                            startIcon={<FcGoogle />}
                                            onError={handleGoogleFailure}
                                            onClick={handleGoogleLogin}
                                            color="primary"
                                        >
                                            Login with Google
                                        </ButtonSubmit>
                                    </Box>
                                </Box>
                                <Box textAlign="center" mt={2}>
                                    <Link href="/password-reset" variant="body2">
                                        Forgot password?
                                    </Link>

                                    <Link
                                        style={{ display: "none" }}
                                        href="/register"
                                        variant="body2"
                                    >
                                        {"Don't have an account? Sign Up"}
                                    </Link>
                                </Box>
                                <Box mt={5}>
                                    <Copyright />
                                </Box>
                            </StyledForm>
                        </DivPaper>
                    </GridRightside>
                </GridRoot>
                <Snackbar open={hasError} autoHideDuration={3000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error">
                        Your email or password is incorrect, I can't let you in. 403: Access
                        Denied!
                    </Alert>
                </Snackbar>
                <StyledBackdrop open={open}>
                    <CircularProgress size={48} />
                </StyledBackdrop>
            </ThemeProvider>
        </GoogleOAuthProvider>
    );
}
