import { useState, useEffect, useRef } from "react";
import {
    Box,
    Button,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Snackbar,
    Alert,
    ButtonGroup,
    useTheme,
} from "@mui/material";
import Grid from "@mui/material/Grid2"
import { Add, Delete, CloudDownload } from "@mui/icons-material";
import { styled } from "@mui/system";
import ClipboardJS from "clipboard";
import { GrStatusGood } from "react-icons/gr";
import { ImCancelCircle } from "react-icons/im";
import appVars from "../../appVars.json";
import MaterialTable from 'material-table';
import _ from "lodash";
import { lightenHexColor } from "../../dwi-functions";
import TagsToolbar from "./toolbar";
import Trackers from "../trackers";

const StyledBox = styled(Box)(({ theme }) => ({
    padding: theme.spacing(0),
}));


const id = sessionStorage.getItem('cid');

const Tags = (props) => {
    const [tag, setTag] = useState({
        pkRecID: "1",
        clientid: id,
        type: "",
        settings: "{}",
        tagProfile: "",
    });
    const [tags, setTags] = useState([]);
    const [tagProfiles, setTagProfiles] = useState(false);
    const [open, setOpen] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteTag, setdeleteTag] = useState(false);
    const [copySuccessShow, setCopySuccessShow] = useState(false);
    const [copySuccess, setCopySuccess] = useState("");
    const theme = useTheme();
    const reportRef = useRef();

    const handleClose = () => {
        setOpen(false);
        setOpenDelete(false);
        setCopySuccessShow(false);
    };

    const openNew = () => {
        setOpen(true);
    };

    const fetchData = async () => {
        const requestOptions = { method: "GET", redirect: "follow" };

        try {
            const res1 = await fetch(appVars.api_url + "/tag_profiles", requestOptions);
            const tagProfilesData = await res1.json();
            setTagProfiles(_.sortBy(tagProfilesData, "name"));

            const res2 = await fetch(appVars.api_url + "/tags/" + id, requestOptions);
            const tagsData = await res2.json();
            setTags(_.sortBy(tagsData, "type"));
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };



    useEffect(() => {
        if (!tagProfiles) fetchData();
        const clipboard = new ClipboardJS(".copy");
        clipboard.on("success", (e) => {
            setCopySuccess(e.text);
            setCopySuccessShow(true);
            e.clearSelection();
        });
    }, [tagProfiles]);





    return (
        <StyledBox sx={{ padding: (props.auditview ? 0 : theme.spacing(0)), marginTop: theme.spacing(0) }} >
            {(props.auditview ?
                <Box pt={0}>

                    <MaterialTable
                        title="Configured Tags"
                        columns={[
                            { title: 'Tag Name', field: 'transactions', render: (rowData) => <Typography sx={{ fontSize: '.75rem', minWidth: 150, textWrap: 'nowrap' }} variant="subtitle2">{rowData.type}</Typography> },
                            { title: 'ID', field: 'transactions', render: (rowData) => <Typography variant="subtitle1" sx={{ fontSize: '.75rem', textWrap: 'nowrap' }}>{(JSON.parse(rowData.settings).id ? JSON.parse(rowData.settings).id : 'N/A')}</Typography> },
                            { title: 'Conversion', field: 'transactions', render: (rowData) => <Typography variant="subtitle1" sx={{ fontSize: '.75rem', textWrap: 'nowrap' }}>{(JSON.parse(rowData.settings).conversion ? JSON.parse(rowData.settings).conversion : '--')}</Typography> },
                            { title: 'Firing?', field: 'transactions', render: (rowData) => <Typography variant="body2">{rowData.status === 1 ? <GrStatusGood title={rowData.lastFire} size={15} color='green' /> : <ImCancelCircle title={rowData.lastFire} size={15} color="red" />}</Typography> },
                        ]}

                        data={tags}
                        options={{
                            headerStyle: {
                                background: theme.palette.secondary.light,
                            },
                            rowStyle: (rowData, index) => ({
                                backgroundColor: index % 2 === 1 ? lightenHexColor('#000000', 90) : '#fff'
                            }),
                            pageSize: tags.length,
                            search: false,
                            paging: false,
                            sorting: false,
                            toolbar: false,
                        }}
                    />
                </Box> :
                ''
            )}


            {tags && !props.auditview ? (
                <Box ref={reportRef}>

                    <TagsToolbar setTag={setTag} setOpen={openNew} client={props.client} />



                    <Box sx={{ p: 1 }}>
                        <Box sx={{
                            display: { md: 'flex' },
                            gap: 1,
                            flexDirection: 'row'
                        }} >
                            <Box
                                sx={{ width: { md: '100%' } }}
                            >
                                <Box sx={{
                                    width: '100%',
                                    display: { md: 'flex' },
                                    background: theme.palette.secondary.light,
                                    alignItems: 'center',
                                    p: 1,
                                    borderTopRightRadius: 8,
                                    borderTopLeftRadius: 8,
                                    color: "white"
                                }} >

                                    <Box sx={{ width: { md: "5%" } }} ><Typography>Firing?</Typography></Box>
                                    <Box sx={{ width: { md: "25%" } }}><Typography>Tag</Typography></Box>
                                    <Box sx={{ width: { md: "25%" } }}><Typography>ID</Typography></Box>
                                    <Box sx={{ width: { md: "25%" } }}><Typography>Conversion</Typography></Box>
                                    <Box sx={{ width: { md: "20%" } }}></Box>
                                </Box>

                                {tags.map((entry, index) => {
                                    // console.log("Tag", entry);
                                    var a = JSON.parse(entry.settings);
                                    return (

                                        <Box sx={{
                                            width: '100%',
                                            display: { md: 'flex' },
                                            p: 1,
                                            alignItems: 'center',
                                            background: index % 2 === 0 ? '#c7c7c7' : "#ffffff",
                                            border: '1px solid #c7c7c7'
                                        }} >

                                            <Box sx={{ width: { md: "5%" } }}>{(entry.status === 1 ? <GrStatusGood title={entry.lastFire} color='green' /> : <ImCancelCircle color="red" />)}</Box>
                                            <Box sx={{ width: { md: "25%" } }}><Typography>{entry.type}</Typography></Box>
                                            <Box sx={{ width: { md: "25%" } }}><Typography>{a.id}</Typography></Box>
                                            <Box sx={{ width: { md: "25%" } }}><Typography>{a.conversion}</Typography></Box>
                                            <Box sx={{ width: { md: "20%" }, display: 'flex', justifyContent: 'flex-end' }}>
                                                <ButtonGroup>
                                                    <Button color="secondary">
                                                        <Delete

                                                            onClick={(e) => {
                                                                setdeleteTag(entry.pkRecID);
                                                                setOpenDelete(true)
                                                            }}
                                                        />
                                                    </Button>
                                                    <Button color="primary" href={`${appVars.api_url}/generate-gtm-tag/${entry.tagProfile}/${entry.clientid}`}>
                                                        <CloudDownload />
                                                    </Button>
                                                </ButtonGroup></Box>
                                        </Box>

                                    )
                                })}
                            </Box>
                            <Box >
                                <Box
                                    sx={{
                                        width: '100%',
                                        p: 1,
                                        pt: 2,
                                        boxShadow: '0px 0px 24px -15px #000000',
                                        border: '1px solid #c7c7c7',
                                        borderRadius: 1
                                    }}
                                >

                                    <Trackers />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            ) : (
                ''
            )}



            <Snackbar open={copySuccessShow} autoHideDuration={3000} onClose={handleClose}>
                <Alert severity="success">{copySuccess}</Alert>
            </Snackbar>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle sx={{ color: "#fff" }} id="form-dialog-title">Add New Tag
                    <img src={appVars.api_url + '/imageFiles/' + tag.tagProfile + '.png'} style={{ maxWidth: 100, float: 'right' }} id="logoHolder" alt='' />
                </DialogTitle>
                <DialogContent style={{ padding: theme.spacing(3), borderBottom: "1px solid #cfcfcf" }}>
                    <DialogContentText gutterBottom>
                        Select Tag Type below to set up a new tag for TAG manager
                    </DialogContentText>

                    <Grid container spacing={2}>

                        <Grid >
                            <TextField
                                id="tagProfile"
                                type="hidden"
                                sx={{ display: 'none' }}
                            />
                            <TextField

                                margin="dense"
                                id="name"
                                label="Name Tag"
                                type="text"
                                sx={{ display: 'none' }}
                                disabled={true}
                                value={tag.type}
                                fullWidth
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                    tag.type = e.target.value;
                                    setTag(tag);
                                    console.log(tag);
                                }}
                            />

                        </Grid>



                        {Object.entries(tag.settings).map((entry2) => (

                            <Grid size={{ md: 12 }} >

                                <TextField

                                    margin="dense"
                                    id={entry2[0]}
                                    label={entry2[0].toUpperCase()}
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => {
                                        tag.settings[entry2[0]] = e.target.value;
                                        setTag(tag);
                                        console.log(tag);
                                    }}
                                />

                            </Grid>

                        ))}


                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button color="primary" variant="contained" startIcon={<Add></Add>} onClick={async (e) => {

                        var myHeaders = new Headers();
                        myHeaders.append("Content-Type", "application/json");

                        var requestOptions = {
                            method: 'POST',
                            redirect: 'follow',
                            headers: myHeaders,
                            body: JSON.stringify(tag)
                        };

                        const res = await fetch(appVars.api_url + "/tags/a", requestOptions);
                        res.json()
                            .then((response) => {
                                fetchData();
                                handleClose();
                            })
                            .then(result => console.log(result))
                            .catch(error => console.log('error', error));



                    }}>
                        Add New
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog open={openDelete} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle sx={{ color: "#fff" }} id="form-dialog-title">Are You Sure?
                    <img src={appVars.api_url + '/imageFiles/' + tag.tagProfile + '.png'} style={{ maxWidth: 100, float: 'right' }} id="logoHolder" alt='' />
                </DialogTitle>

                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        No
                    </Button>
                    <Button color="primary" variant="contained" startIcon={<Delete />} onClick={async (e) => {

                        const myHeaders = new Headers();
                        myHeaders.append("Content-Type", "application/json");

                        const raw = JSON.stringify({
                            "pkRecID": deleteTag
                        });

                        const requestOptions = {
                            method: "POST",
                            headers: myHeaders,
                            body: raw,
                            redirect: "follow"
                        };

                        fetch(appVars.api_url + "/tags/d", requestOptions)
                            .then((response) => response.text())
                            .then((result) => fetchData())
                            .then((result) => handleClose())
                            .catch((error) => console.error(error));



                    }}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>


        </StyledBox>
    );
};

export default Tags;
