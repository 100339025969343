import  { useState, useEffect } from 'react';
import appVars from '../../../appVars.json';
import TextField from '@mui/material/TextField';
import { Avatar, Box, MenuItem, ListItemIcon, ListItemText } from '@mui/material';



const UserSearch = (props) => {
    const [accountTeams, setaccountTeams] = useState([]);

   // console.log(props);

    useEffect(() => {
        if(accountTeams.length===0){
        fetchData();
        }
    }, []);


    async function fetchData() {

        var url = (props.client ? appVars.api_url + "/users/c/"+props.client : appVars.api_url + "/users/")

        const atres = await fetch(url);
        atres
            .json()
            .then((resss) => {
                setaccountTeams(resss);
            });

    }


    return (
        <Box className="hidden-xs-down" pb={2} >
            <TextField variant='outlined' shrink select {...props} {...props.field}>
                {accountTeams.map((entry) => (
                    <MenuItem key={entry.userid} value={entry.userid}>
                        <Box display="flex" alignItems="center">
                            <ListItemIcon>
                                <Avatar style={{ height: 30, width: 30 }} variant="circular" alt={entry.name} src={appVars.api_url + '/imageFiles/' + entry.userid + '.png'} />
                            </ListItemIcon>
                            <ListItemText primary={entry.name} />
                        </Box>
                    </MenuItem>

                ))}
            </TextField>
        </Box>
    )
};

UserSearch.propTypes = {

};

export default UserSearch;
