import React, { useState, useEffect } from 'react';
import {
    AppBar,
    Box,
    Tab,
    Tabs,

    Typography,
    LinearProgress,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { styled, useTheme } from '@mui/system';
import moment from 'moment';
import LightGrid from '../LightGrid';
import PropTypes from 'prop-types';
import appVars from '../../appVars.json';
import _ from 'lodash';

// Styled components
const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column'
});

const ToolbarSpacer = styled('div')(({ theme }) => ({
    ...theme.mixins.toolbar,
}));



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {children}
        </Box>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const LightDashBoard = () => {
    const [hasError, setErrors] = useState(false);
    const [cards, setCards] = useState([]);
    const [value, setValue] = useState(0);
    const [incards, setInCards] = useState([]);
    const [upcards, setUpCards] = useState([]);
    const [loading, setLoading] = useState(true);
    const theme = useTheme();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const fetchData = async (id) => {
        let url = `${appVars.api_url}/lightboxes/${id}`;
        if (id === 'lightbox') {
            url = `${appVars.api_url}/lightboxes/all`;
        }

        try {
            const res = await fetch(url);
            const data = await res.json();

            const running = _.filter(data, (o) => moment().isBetween(o.startTime, o.endTime));
            const inactive = _.filter(data, (o) => moment().isAfter(o.endTime));
            const upcoming = _.filter(data, (o) => moment().isBefore(o.endTime) && moment().isBefore(o.startTime));



            setInCards(_.orderBy(inactive,['startTime','DESC']));
            setUpCards(_.orderBy(upcoming,['startTime','DESC']));
            setCards(_.orderBy(running,['startTime','DESC']));
            setLoading(false);
        } catch (err) {
            setErrors(true);
        }
    };

    useEffect(() => {

        const id = sessionStorage.getItem('cid');
        fetchData(id);
    }, []);

    return (
        <Root>


            <AppBar style={{ background: 'linear-gradient(90deg, rgba(74,151,220,1) 0%, rgba(0,0,0,1) 97%)' }} position="static" color="secondary">
                <Tabs
                    variant="standard"
                    value={value}
                    textColor="#ffffff"
                    onChange={handleChange}
                    aria-label="simple tabs example"
                    indicatorColor="secondary"
                >
                    <Tab label="Currently Running" {...a11yProps(0)} />
                    <Tab label="Upcoming Lightboxes" {...a11yProps(1)} />
                    <Tab label="Inactive Lightboxes" {...a11yProps(2)} />
                </Tabs>
            </AppBar>


            {!loading ? (
                <Box>
                    <TabPanel value={value} index={0}>
                        <LightGrid title="Currently Running" cards={cards} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <LightGrid title="Upcoming Lightboxes" cards={upcards} />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <LightGrid title="Inactive Lightboxes" cards={incards} />
                    </TabPanel>
                </Box>
            ) : (
                <LinearProgress style={{ margin: '20% 25%' }} color="secondary" />
            )}


        </Root>
    );
};

LightDashBoard.propTypes = {};

export default LightDashBoard;
