'use client';
import * as React from 'react';
import Stack from '@mui/material/Stack';
import MenuList from '@mui/material/MenuList';
import Divider from '@mui/material/Divider';
import {
    Account,
    AccountPreview,
    AccountPopoverFooter,
    AccountPopoverHeader,
    SignOutButton,
} from '@toolpad/core/Account';
import { Link, MenuItem, ListItemText, ListItemIcon } from '@mui/material/';
import {FcBusinessman} from 'react-icons/fc';
import { getCookie } from '../../dwi-functions';



function AccountSidebarPreview(props) {
    const { handleClick, open, mini } = props;
    return (
        <Stack direction="column" p={0} overflow="hidden">
            <Divider />
            <AccountPreview
                variant={mini ? 'condensed' : 'expanded'}
                slotProps={{ avatarIconButton: { sx: mini ? { border: '10' } : {} } }}
                handleClick={handleClick}
                open={open}
            />
        </Stack>
    );
}

function SidebarFooterAccountPopover({ mini }) {
    const id = getCookie("_au");
    return (
        <Stack direction="column">

            <AccountPreview variant="expanded" />
            
            <MenuList>
                <MenuItem>
                    <ListItemIcon><FcBusinessman /></ListItemIcon>
                    <ListItemText > <Link href={"/user/"+id} >Profile Settings</Link></ListItemText>
                </MenuItem>
            </MenuList>
            <Divider />
            <AccountPopoverFooter>
                <SignOutButton />
            </AccountPopoverFooter>
        </Stack>
    );
}

const createPreviewComponent = (mini) => {
    function PreviewComponent(props) {
        return <AccountSidebarPreview {...props} mini={mini} />;
    }
    return PreviewComponent;
};

const createPopoverComponent = (mini) => {
    function PopoverComponent() {
        return <SidebarFooterAccountPopover mini={mini} />;
    }
    return PopoverComponent;
};

export default function SidebarFooterAccount({ mini }) {
    const PreviewComponent = React.useMemo(() => createPreviewComponent(mini), [mini]);
    const PopoverComponent = React.useMemo(() => createPopoverComponent(mini), [mini]);

    return (
        <Account
            slots={{
                preview: PreviewComponent,
                popoverContent: PopoverComponent,
            }}
            slotProps={{
                popover: {
                    transformOrigin: { horizontal: 'left', vertical: 'top' },
                    anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
                    slotProps: {
                        paper: {
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: (theme) =>
                                    `drop-shadow(0px 2px 8px ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.10)' : 'rgba(0,0,0,0.32)'
                                    })`,
                                mt: 1,
                                '&::before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    bottom: 10,
                                    left: 0,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translate(-50%, -50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        },
                    },
                },
            }}
        />
    );
}

export function ToolbarAccountOverride() {
    return null;
}
