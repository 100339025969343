import React, { useState, useEffect } from 'react';
import { Avatar, TextField, MenuItem, Box, useTheme } from '@mui/material'
import { Typography, Snackbar, Paper, Link } from "@mui/material";
import Button from "@mui/material/Button";
import appVars from "../../appVars.json";
import LinearProgress from '@mui/material/LinearProgress';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { useLocation } from 'react-router-dom';

import PlatformSearch from '../../system/controls/platform-search';
import { BsMeta, BsBing, BsGoogle, BsArrow90DegRight, BsCheck2Square, BsSearch } from "react-icons/bs";
import VerticalSearch from '../../system/controls/vertical-search';






const { v4: uuidv4 } = require('uuid');
const uuid = uuidv4();





function getSteps() {
    return [
        <StepLabel StepIconComponent={BsArrow90DegRight}><Typography variant="body">Get Started</Typography></StepLabel>,
        <StepLabel StepIconComponent={BsSearch}><Typography variant="body">Domain Scan</Typography></StepLabel>,
        <StepLabel StepIconComponent={BsGoogle}><Typography variant="body">Google</Typography></StepLabel>,
        <StepLabel StepIconComponent={BsMeta}><Typography variant="body">Meta</Typography></StepLabel>,
        <StepLabel StepIconComponent={BsBing}><Typography variant="body">BING</Typography></StepLabel>,
        <StepLabel StepIconComponent={BsCheck2Square}><Typography variant="body">Confirm Settings</Typography></StepLabel>
    ];
}




const Newclient = (props) => {

    // eslint-disable-next-line


    const clientid = props.clientid;
    const ownerid = props.auth;

    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();
    const [screenshot, setscreenshot] = useState('');
    const [clientData, setClient] = useState({
        ga4Array: [],
        fbpixelArray: [],
        ga_paid_idArray: [{ id: '', label: '' }, { id: '', label: '' }],
        ga_grant_idArray: [{ id: '', label: '' }, { id: '', label: '' }],
        donationUrl: []
    })
    const [owner, setOwner] = useState((!ownerid ? '' : ownerid));
    const [scan, setScan] = useState(true);
    const [tags, setTags] = useState([]);
    const [clientID, setClientID] = useState((!clientid ? '' : clientid));
    // eslint-disable-next-line
    const [disabled, setDisable] = useState((!clientid ? false : true));
    // eslint-disable-next-line
    const [disabledO, setODisable] = useState((!ownerid ? false : false));
    const [loadingData, setLoading] = useState(true);
    const [fbid, setfbID] = useState("");
    const [clientTypes, setClientType] = useState(
        [{
            value: 1,
            label: 'Not Set'
        }]
    );
    const [userTypes, setUserType] = useState(
        [{
            value: 1,
            label: 'Not Set'
        }]
    );
    const [errors, setErrors] = useState({ status: false, "msg": 'No Error' });
    const [load, setLoad] = useState(false);

    const theme = useTheme();






    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleChange = (event) => {

        clientData[event.target.id] = event.target.value;
        setClient(clientData);
        //  console.log(clientData);
    };



    const rgbToHex = (r, g, b) => '#' + [r, g, b].map(x => {
        const hex = x.toString(16)
        return hex.length === 1 ? '0' + hex : hex
    }).join('');




    async function snagTheDomain() {

        setLoading(true);
        var d = document.getElementById('domain').value.replace('https://', '');

        var url = "http://localhost:7325/check-domain/" + btoa(d);


        const res = await fetch(url,
            {
                method: 'GET'
            }
        );
        res
            .json()
            .then((domainres) => {

                domainres.domains = d;
                domainres.client = owner;

                //console.log('Scan Result', domainres);
                //  console.log(clientData.tagArray[0].tag);
                setClient(domainres);
                setLoading(false);

                setLoad(true);
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                setscreenshot(domainres.screenshot);
            }).catch((err) => {
                setErrors({ status: true, "msg": err });
                //console.log(err);
            });

    }


    async function fetchStats(id) {

        const res = await fetch(appVars.api_url + "/clients");
        res
            .json()
            .then((res) => {


                setClientType(res);
                //  console.log(clientTypes);
                // console.log(res);
            }).catch((err) => {
                setErrors(true);
                console.log(err);
            });
        var url = (!clientid ? appVars.api_url + "/users" : appVars.api_url + "/users/c/" + clientid);
        //console.log(url);
        const ures = await fetch(url);
        ures
            .json()
            .then((users) => {
                setUserType(users);
                //console.log(clientTypes);
                //console.log(v);
            }).catch((err) => {
                setErrors(true);
                console.log(err);
            });


        setLoading(false);


    }

    useEffect(() => {
        fetchStats();
        // eslint-disable-next-line
    }, []);



    return (

        <Box style={{ padding: theme.spacing(1) }} >

            <Box sx={{
                display: { md: 'flex', xs: 'none' },
                gap: 2
            }}>

                <Box
                    sx={{
                        p: 2,
                        display: { md: 'flex', xs: 'none' },
                        border: "1px solid #c7c7c7",
                        borderRadius: 2,
                    }}
                >

                    <Stepper variant="outlined" style={{ border: 'none' }} activeStep={activeStep} orientation="vertical" >
                        {steps.map((label) => (
                            <Step key={label} sx={{
                                '& .MuiStepLabel-root .Mui-completed': {
                                    color: 'secondary.dark', // circle color (COMPLETED)
                                },
                                '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                                {
                                    color: 'grey.500', // Just text label (COMPLETED)
                                },
                                '& .MuiStepLabel-root .Mui-active': {
                                    color: 'primary.main', // circle color (ACTIVE)
                                },
                                '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                                {
                                    color: 'common.white', // Just text label (ACTIVE)
                                },
                                '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                                    fill: 'black', // circle's number (ACTIVE)
                                },
                            }} >
                                {label}
                            </Step>
                        ))}
                    </Stepper>



                </Box>

                <Box
                    sx={{
                        p: 2,
                        flex: 1,
                        border: "1px solid #c7c7c7",
                        borderRadius: 2,
                        alignContent: 'space-between'
                    }}>

                    <form>

                        <Box id="step1" step="Get Started" sx={{ display: (activeStep === 0 ? 'flex' : 'none'), flexDirection: 'column', alignContent: 'space-between' }} >

                            <Box>
                                <TextField
                                    type="hidden"
                                    select
                                    value={clientID}
                                    disabled={disabled}
                                    sx={{
                                        pb: 2
                                    }}
                                    variant="outlined"
                                    fullWidth
                                    size='small'
                                    label="Agency"
                                    name="client"
                                    id="client"
                                    onChange={(e) => {
                                        setClientID(e.target.value);
                                        console.log(e.target.value)
                                    }}

                                >
                                    {clientTypes.map((entry) => (
                                        <MenuItem key={entry.clientId} value={entry.clientId}>{entry.clientname}</MenuItem>

                                    ))}
                                </TextField>
                            </Box>

                            <TextField
                                type="text"
                                sx={{
                                    pb: 2
                                }}
                                variant="outlined"
                                fullWidth

                                label="Client Name"
                                name="name"
                                id="name"
                                size='small'

                                onBlur={(e) => {

                                    //  console.log(e.target.value)
                                }}
                            ></TextField>







                            <Box style={{ display: (disabledO ? 'none' : 'flex') }}>
                                <TextField
                                    select
                                    value={owner}
                                    disabled={disabledO}

                                    variant="outlined"
                                    fullWidth
                                    size='small'
                                    sx={{
                                        pb: 2
                                    }}
                                    label="Account Owner"

                                    name="owner"
                                    id="owner"
                                    onChange={(e) => {
                                        setOwner(e.target.value);

                                    }}

                                >
                                    {userTypes.map((entry) => (
                                        <MenuItem key={entry.pkRecId} value={entry.pkRecId}>{entry.name}</MenuItem>

                                    ))}
                                </TextField>
                            </Box>
                            <PlatformSearch fullWidth sitetype='donation' value={clientData.donationtype} title="Donation Platform"
                                onChange={(e, ne) => {
                                    clientData.donationtype = ne.pkRecID;
                                    setClient(clientData);
                                }}
                            />

                            <TextField
                                type="text"
                                size='small'
                                sx={{
                                    pb: 2
                                }}

                                variant="outlined"
                                fullWidth

                                label="Domain name: example.com"
                                name="domain"
                                id="domain"
                                //onChange={snagTheDomain}
                                onBlur={(e) => {
                                    if (e.currentTarget.value !== "") {
                                        setScan(false);
                                    }

                                }}
                            ></TextField>

                            <Box sx={{
                                gap: 1,
                                mt: 2,
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }} >
                                <Button disabled={scan} size='small' startIcon={<BsSearch />} variant="contained" color="secondary" onClick={snagTheDomain}>Scan Domain</Button>
                                <Button size='small' onClick={props.toggleDialog} color="error" variant="contained">
                                    cancel
                                </Button>
                            </Box>



                            {(loadingData ? <LinearProgress /> : '')}
                        </Box>

                        <Box id="step2" step="Domain Scan" sx={{ display: (activeStep === 1 ? 'flex' : 'none'), flexDirection: 'column', alignContent: 'space-between' }} >
                            <Box sx={{ display: 'flex', gap: 2 }}>
                                <Box sx={{
                                    width: { md: '100%' }
                                }}>
                                    <Box sx={{ p: 1, border: '1px solid #c7c7c7', mb: 4, borderRadius: 1, boxShadow: '0px 0px 25px -15px #000000' }} >
                                        <img src={(clientData.logo ? clientData.logo[0] : '')} style={{ width: '100%' }} alt="Logo" /></Box>
                                    <TextField
                                        type="text"
                                        sx={{
                                            pb: 2
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        value={clientData.siteUrl}
                                        label="Website URL"
                                        name="site_url"
                                        id="siteUrl"
                                        size='small'
                                        onChange={(e) => {
                                            clientData.siteUrl = e.target.value;
                                            setClient(clientData);
                                        }}

                                    ></TextField>
                                    <TextField
                                        type="text"
                                        sx={{
                                            pb: 2
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        value={clientData.donationUrl[0]}
                                        label="Donation URL"
                                        name="donationUrl"
                                        id="donationUrl"
                                        size='small'
                                        onChange={handleChange}
                                    ></TextField>


                                    <TextField
                                        type="text"
                                        sx={{
                                            pb: 2
                                        }}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        fullWidth
                                        value={clientData.registrar}
                                        label="Domain Registrar"
                                        name="registrar"
                                        size='small'
                                        id="registrar"
                                        onBlur={(e) => {
                                            clientData.registrar = e.target.value;
                                        }}
                                    //onChange={snagTheDomain}
                                    ></TextField>
                                    <VerticalSearch fullWidth value={clientData.vertical} title="Vertical"
                                        onChange={(e, ne) => {

                                            var k = clientData;

                                            k.vertical = e.target.value;
                                            //    console.log(k);
                                            setClient(k);
                                        }} />
                                </Box>
                                <Box>
                                    {(clientData.siteUrl ? <img style={{ width: '100%' }} src={screenshot} alt="Screenshot" onError={(e) => { setscreenshot('https://via.placeholder.com/300x300.png/d3d3d3?text=Not+Available'); }} /> : '')}
                                </Box>
                                <Box item sm={2}>

                                    {

                                        (clientData.colors ?
                                            clientData.colors.map((entry, i) => (
                                                <Box item sm={12} ><Typography style={{ padding: '5px', margin: '3px', background: rgbToHex(entry[0], entry[1], entry[2]) }} key={i} value={i}>{rgbToHex(entry[0], entry[1], entry[2])}</Typography></Box>
                                            ))
                                            : '')
                                    }


                                </Box>
                            </Box>
                            <Box sx={{
                                gap: 1,
                                mt: 2,
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }} >
                                <Button size='small' hidden={load} variant="contained" color="secondary" onClick={handleBack} >Back</Button>
                                <Button size='small' hidden={load} variant="contained" color="secondary" onClick={handleNext} >Next</Button>
                                <Button size='small' onClick={props.toggleDialog} color="error" variant="contained">
                                    cancel
                                </Button>
                            </Box>
                        </Box>

                        <Box id="step3" step="Google" sx={{ display: (activeStep === 2 ? 'flex' : 'none'), flexDirection: 'column', alignContent: 'space-between' }} >

                            <Box container spacing={2}>
                                <Typography variant='h6' gutterBottom >Analytics</Typography>
                                <Box sx={{ display: 'flex', gap: 2 }}>
                                    <Box sx={{ width: '50%' }}>


                                        {
                                            (clientData.tagArray ?

                                                <TextField
                                                    type="text"
                                                    sx={{
                                                        pb: 2
                                                    }}
                                                    select
                                                    variant="outlined"
                                                    fullWidth
                                                    label="GTM Account"
                                                    name="tag"
                                                    size='small'
                                                    defaultValue={clientData.tagArray ? clientData.tagArray[0].tag : ''}
                                                    id="tag"
                                                    onChange={(e) => {
                                                        var a = {
                                                            clientid: uuid,
                                                            tagProfile: '52e1364f-71d1-11ee-bfcd-16725369f13b',
                                                            type: 'Google Tag Manager Container',
                                                            settings: {
                                                                "id": e.target.value,
                                                                "account": "",
                                                                "container": ""
                                                            }
                                                        };
                                                        tags.push(a);
                                                        setTags(tags);
                                                        clientData.tag = e.target.value;
                                                        setClient(clientData);

                                                    }}
                                                //onChange={snagTheDomain}

                                                >
                                                    {clientData.tagArray.map((entry) => (
                                                        <MenuItem key={entry.tag} value={entry.tag}>{entry.tag}</MenuItem>
                                                    ))}


                                                </TextField>
                                                : '')
                                        }
                                    </Box>






                                    <Box sx={{ width: '50%' }}>

                                        {
                                            (clientData.ga4Array ?

                                                <TextField
                                                    select
                                                    type="text"
                                                    size='small'
                                                    sx={{
                                                        pb: 2
                                                    }}
                                                    defaultValue={clientData.tagArray ? clientData.tagArray[0].tag : ''}
                                                    variant="outlined"
                                                    fullWidth
                                                    value={clientData.measurementID}
                                                    label="Analytics 4 Measurement ID"
                                                    name="measurementID"
                                                    id="measurementID"
                                                    onChange={(e) => {
                                                        var a = {
                                                            clientid: uuid,
                                                            tagProfile: '35d4eef1-71d1-11ee-bfcd-16725369f13b',
                                                            type: 'Google Analytics 4',
                                                            settings: {
                                                                "id": e.target.value
                                                            }
                                                        };

                                                        tags.push(a);
                                                        setTags(tags);
                                                        clientData.measurementID = e.target.value;
                                                        setClient(clientData);
                                                        //   console.log(tags);

                                                    }}
                                                //onChange={snagTheDomain}

                                                >

                                                    {clientData.ga4Array.map((entry) => (
                                                        <MenuItem key={entry} value={entry}>{entry}</MenuItem>

                                                    ))}



                                                </TextField> : '')
                                        }
                                    </Box>
                                </Box>
                                <Typography variant='h6' gutterBottom>Paid Account</Typography>
                                <Box sx={{ display: 'flex', gap: 2 }}>
                                    <Box sx={{ width: '50%' }}>


                                        <TextField


                                            type="text"
                                            size='small'
                                            sx={{
                                                pb: 2
                                            }}

                                            variant="outlined"
                                            fullWidth

                                            label="Adwords Paid Account ID"
                                            name="awpid"
                                            id="awpid"
                                        //onChange={snagTheDomain}

                                        >

                                        </TextField>
                                    </Box>
                                    <Box sx={{ width: '50%' }}>


                                        <TextField


                                            type="text"
                                            size='small'
                                            sx={{
                                                pb: 2
                                            }}

                                            variant="outlined"
                                            fullWidth

                                            label="Adwords Paid Account Label"
                                            name="awplabel"
                                            id="awplabel"
                                        //onChange={snagTheDomain}

                                        >

                                        </TextField>
                                    </Box>
                                </Box>
                                <Typography sx={{ flex: 1 }} variant='h6' gutterBottom>Grant Account</Typography>
                                <Box sx={{ display: 'flex', gap: 2 }}>

                                    <Box sx={{ width: '50%' }}>


                                        <TextField


                                            type="text"
                                            size='small'
                                            sx={{
                                                pb: 2
                                            }}

                                            variant="outlined"
                                            fullWidth

                                            label="Adwords Grant Account ID"
                                            name="awgid"
                                            id="awgid"
                                        //onChange={snagTheDomain}

                                        >

                                        </TextField>
                                    </Box>
                                    <Box sx={{ width: '50%' }}>


                                        <TextField


                                            type="text"
                                            size='small'
                                            sx={{
                                                pb: 2
                                            }}

                                            variant="outlined"
                                            fullWidth

                                            label="Adwords Grant Account Label"
                                            name="awglabel"
                                            id="awglabel"
                                        //onChange={snagTheDomain}

                                        >

                                        </TextField>
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{
                                gap: 1,
                                mt: 2,
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }} >
                                <Button size='small' hidden={load} variant="contained" color="secondary" onClick={handleBack} >Back</Button>
                                <Button size='small' hidden={load} variant="contained" color="secondary" onClick={(e) => {
                                    var a = {};

                                    if (document.getElementById('awpid').value !== '') {
                                        a = {
                                            clientid: uuid,
                                            tagProfile: '5e493ae6-d035-11ee-9f6d-0edddfac9b03',
                                            type: 'Google Adwords Paid - EC',
                                            settings: {
                                                "id": document.getElementById('awpid').value,
                                                "conversion": document.getElementById('awplabel').value
                                            }
                                        };

                                        tags.push(a);

                                    }

                                    if (document.getElementById('awgid').value !== '') {


                                        a = {
                                            clientid: uuid,
                                            tagProfile: '3cc8b472-d035-11ee-9f6d-0edddfac9b03',
                                            type: 'Google Adwords Grant - EC',
                                            settings: {
                                                "id": document.getElementById('awgid').value,
                                                "conversion": document.getElementById('awglabel').value
                                            }
                                        };
                                        tags.push(a);
                                    }

                                    setTags(tags);
                                    //  console.log(tags);
                                    handleNext(e);

                                }} >Next</Button>
                                <Button size='small' onClick={props.toggleDialog} color="error" variant="contained">
                                    cancel
                                </Button>
                            </Box>
                        </Box>

                        <Box id="step4" step="META" sx={{ display: (activeStep === 3 ? 'flex' : 'none'), flexDirection: 'column', alignContent: 'space-between' }}>



                            <Box sx={{ height: 300 }}>
                                <TextField
                                    type="text"
                                    size='small'
                                    sx={{
                                        pb: 2
                                    }}
                                    select
                                    variant="outlined"
                                    fullWidth
                                    defaultValue={clientData.fbpixel}
                                    label="Meta Pixel ID:"
                                    name="fbPixel"
                                    id="fbPixel"
                                    onChange={(e) => {
                                        clientData.fbpixel = e.target.value;
                                    }}
                                //onChange={snagTheDomain}

                                >
                                    {clientData.fbpixelArray.map((entry) => (
                                        <MenuItem key={entry} value={entry}>{entry}</MenuItem>

                                    ))}
                                </TextField>

                                <TextField type="text"
                                    size='small'
                                    sx={{
                                        pb: 2
                                    }}
                                    variant="outlined"
                                    fullWidth

                                    label="Facebook API Token"
                                    name="token"
                                    id="token"
                                    onBlur={(e) => {
                                        setfbID(e.target.value);
                                    }}>
                                </TextField>


                                <TextField type="text"
                                    size='small'
                                    sx={{
                                        pb: 2
                                    }}
                                    variant="outlined"
                                    fullWidth

                                    label="Facebook Verify ID"
                                    name="fbid"
                                    id="fbid"
                                    onBlur={(e) => {
                                        setfbID(e.target.value);
                                    }}>
                                </TextField>
                            </Box>
                            <Box sx={{
                                gap: 1,
                                mt: 2,
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }} >

                                <Button size='small' hidden={load} variant="contained" color="secondary" onClick={handleBack} >Back</Button>
                                <Button size='small' hidden={load} variant="contained" color="secondary" onClick={(e) => {
                                    e.preventDefault();

                                    var a = {};

                                    if (document.getElementById('fbPixel').value !== '') {
                                        a = {
                                            clientid: uuid,
                                            tagProfile: '6d20c78b-71cf-11ee-bfcd-16725369f13b',
                                            type: 'Meta Conversion',
                                            settings: {
                                                "id": document.getElementById('fbPixel').value,
                                                "fbapitoken": document.getElementById('token').value
                                            }
                                        };

                                        tags.push(a);

                                    }
                                    //  console.log(tags);
                                    handleNext();

                                }} >Next</Button>
                                <Button size='small' onClick={props.toggleDialog} color="error" variant="contained">
                                    cancel
                                </Button>
                            </Box>

                        </Box>

                        <Box id="step5" step="BING" sx={{
                            display: (activeStep === 4 ? 'flex' : 'none'),
                            flexDirection: 'column',
                            alignContent: 'space-between'
                        }}  >



                            <Box height={300} >
                                {(!clientData.bingArray ?
                                    <TextField
                                        type="text"
                                        size='small'
                                        sx={{
                                            pb: 2
                                        }}

                                        variant="outlined"
                                        fullWidth
                                        defaultValue={clientData.bingId}
                                        label="BING UET ID"
                                        name="bingId"
                                        id="bingId"
                                        onChange={(e) => {


                                        }}
                                    //onChange={snagTheDomain}

                                    >

                                    </TextField> :



                                    <TextField
                                        type="text"
                                        size='small'
                                        sx={{
                                            pb: 2
                                        }}
                                        select
                                        variant="outlined"
                                        fullWidth
                                        defaultValue={clientData.bingId}
                                        label="BING UET ID"
                                        name="bingId"
                                        id="bingId"
                                        onChange={(e) => {


                                        }}
                                    //onChange={snagTheDomain}

                                    >
                                        {clientData.bingArray.map((entry) => (
                                            <MenuItem key={entry} value={entry}>{entry}</MenuItem>

                                        ))}
                                    </TextField>
                                )}

                            </Box>
                            <Box sx={{
                                gap: 1,
                                mt: 2,
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }} >

                                <Button size='small' hidden={load} variant="contained" color="secondary" onClick={handleBack} >Back</Button>
                                <Button size='small' hidden={load} variant="contained" color="secondary" onClick={(e) => {
                                    e.preventDefault();

                                    var a = {};

                                    if (document.getElementById('bingId').value !== '') {
                                        a = {
                                            clientid: uuid,
                                            tagProfile: '553bdfd6-6ddf-11ee-b746-16725369f13b',
                                            type: 'BING Conversion',
                                            settings: {
                                                "id": document.getElementById('bingId').value
                                            }
                                        };
                                        clientData.bingId = document.getElementById('bingId').value;
                                        tags.push(a);

                                    }
                                    // console.log(tags);
                                    handleNext();

                                }} >Next</Button>
                                <Button size='small' onClick={props.toggleDialog} color="error" variant="contained">
                                    cancel
                                </Button>
                            </Box>
                        </Box>

                        <Box id="step6" step="Confirm" sx={{ display: (activeStep === 5 ? 'flex' : 'none'), flexDirection: 'column', alignContent: 'space-between' }} >


                            <Box sx={{ display: 'flex', gap: 2 }}>

                                <Box sx={{ width: '50%' }}>

                                    <Box >
                                        <Box sx={{ p: 1, border: '1px solid #c7c7c7', mb: 4, borderRadius: 1, boxShadow: '0px 0px 25px -15px #000000' }} >
                                            <img src={(clientData.logo ? clientData.logo[0] : '')} style={{ width: '100%' }} alt="Logo" /></Box>
                                        {(clientData.siteUrl ? <img style={{ width: '100%' }} src={screenshot} onError={(e) => { setscreenshot('https://via.placeholder.com/300x300.png/d3d3d3?text=Not+Available'); }} alt="Screenshot" /> : '')}
                                        {

                                            (clientData.colors ?
                                                clientData.colors.map((entry, i) => (
                                                    <Box item sm={12} ><Typography style={{ padding: '5px', margin: '3px', background: rgbToHex(entry[0], entry[1], entry[2]) }} key={i} value={i}>{rgbToHex(entry[0], entry[1], entry[2])}</Typography></Box>
                                                ))
                                                : '')
                                        }

                                    </Box>
                                </Box>


                                <Box sx={{ width: '50%' }}>

                                    <Box sx={{ p: 2, mb: 2, border: 1, borderColor: '#c7c7c7', borderRadius: 2 }}>
                                        <Typography variant="h6" color="secondary" style={{ borderBottom: "1px solid", display: 'block' }} gutterBottom>TAGS to be added</Typography>
                                        {tags.map((entry) => (
                                            <Typography>{entry.type}</Typography>
                                        ))}
                                    </Box>
                                    <Box sx={{ p: 2, mb: 2, border: 1, borderColor: '#c7c7c7', borderRadius: 2 }}>
                                        <Typography color='primary' style={{ borderBottom: "1px solid", display: 'block' }} gutterBottom variant='h6'>Owner Info</Typography>
                                        <Typography>Client: <Avatar alt={owner} variant="rounded" sx={{ width: '100%', maxWidth: '150px', height: '100%', background: 'lightgrey', p: 1 }} title={owner} src={appVars.api_url + '/imageFiles/' + clientID + '.png'} /></Typography>
                                        <Typography>Owner:    <Avatar alt={owner} variant="rounded" sx={{ width: '100%', maxWidth: '150px', height: '100%', background: 'lightgrey', p: 1 }} title={owner} src={appVars.api_url + '/imageFiles/' + owner + '.png'} /></Typography>
                                    </Box>
                                </Box>

                            </Box>

                            <Box sx={{
                                gap: 1,
                                mt: 2,
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }} >
                                <Button size='small' hidden={load} variant="contained" color="secondary" onClick={handleBack} >Back</Button>
                                <Button size='small' color="secondary" variant="contained" type="button"
                                    onClick={(e) => {


                                        var url = appVars.api_url + "/gtm/add/";
                                        //  console.log('Adding Client');


                                        var reportProfile = {
                                            "clientid": uuid,
                                            "client": clientID,
                                            "color": JSON.stringify({
                                                "primary": "#01ade0",
                                                "secondary": "#e9e8e6"
                                            }),
                                            "settings": JSON.stringify({
                                                "ad_sets": {
                                                    "google_paid": { "id": "" },
                                                    "google_grant": { "id": "" },
                                                    "meta": { "id": 0 },
                                                    "bing": { "id": "0" },
                                                    "stackadapt": { "id": "0" }
                                                }
                                            }),
                                            "tabs": JSON.stringify({ "tabs": { "google_paid": { "label": "Google Paid", "charts": { "impressions": 1, "clicks": 1, "ctr": 1, "cpc": 1, "conversions": 1, "revenue": 1, "conv_rate": 1, "cost_conversion": 1, "searchTerms": 1, "region": 0, "comment": 1 } }, "google_grant": { "label": "Google Grant", "charts": { "impressions": 1, "clicks": 1, "ctr": 1, "cpc": 1, "conversions": 1, "revenue": 1, "conv_rate": 1, "cost_conversion": 1, "searchTerms": 1, "region": 0, "comment": 1 } }, "meta": { "label": "Meta", "charts": { "impressions": 1, "clicks": 1, "ctr": 1, "cpc": 1, "conversions": 1, "revenue": 1, "conv_rate": 1, "cost_conversion": 1, "searchTerms": 1, "region": 0, "comment": 1 } }, "bing": { "label": "Bing", "charts": { "impressions": 1, "clicks": 1, "ctr": 1, "cpc": 1, "conversions": 1, "revenue": 1, "conv_rate": 1, "cost_conversion": 1, "searchTerms": 1, "region": 0, "comment": 1 } }, "stackadapt": { "label": "Stackadapt", "charts": { "impressions": 1, "clicks": 1, "ctr": 1, "cpc": 1, "conversions": 1, "revenue": 1, "conv_rate": 1, "cost_conversion": 1, "searchTerms": 1, "region": 0, "comment": 1 } } } })
                                        }



                                        var data = {
                                            "tag": clientData.tag,
                                            "ua": clientData.ua,
                                            "fbpixel": clientData.fbpixel,
                                            "registrar": clientData.registrar,
                                            "siteUrl": clientData.siteUrl,
                                            "donationUrl": clientData.donationUrl[0],
                                            "sitetype": "6259937c-84df-11ee-bf38-16725369f13b",
                                            "donationtype": clientData.donationtype,
                                            "name": document.getElementById('name').value,
                                            "client": clientID,
                                            "fbtoken": document.getElementById('fbtoken'),
                                            "owner": owner,
                                            "fbVerification": fbid,
                                            "pkRecId": uuid,
                                            "measurementID": clientData.measurementID,
                                            "status": 1,
                                            "domains": clientData.domains,
                                            "vertical": clientData.vertical
                                        };

                                    //    console.log(clientData); return false;
                                        const response3 = fetch(appVars.api_url + "/reportprofile", {
                                            method: 'POST', // *GET, POST, PUT, DELETE, etc.
                                            body: JSON.stringify(reportProfile), // body data type must match "Content-Type" header,
                                            headers: {
                                                'Content-Type': 'application/json'
                                            }
                                        });
                                        response3.catch((e) => {
                                            console.log(e);
                                        })



                                        const response = fetch(url, {
                                            method: 'POST', // *GET, POST, PUT, DELETE, etc.
                                            body: JSON.stringify(data), // body data type must match "Content-Type" header,
                                            headers: {
                                                'Content-Type': 'application/json'
                                            }
                                        });
                                        response
                                            .then(users => {
                                                url = appVars.api_url + "/tags/a";
                                                const response2 = fetch(url, {
                                                    method: 'POST', // *GET, POST, PUT, DELETE, etc.
                                                    body: JSON.stringify(tags), // body data type must match "Content-Type" header,
                                                    headers: {
                                                        'Content-Type': 'application/json'
                                                    }
                                                });

                                                response2
                                                    .then(users => {
                                                        setErrors({ "status": true, "msg": 'New Client Added' });
                                                        handleClose();
                                                        props.toggleDialog();
                                                    })

                                            })
                                        // console.log('save date', clientData);
                                        //console.log(JSON.stringify(clientData));

                                    }}
                                >
                                    Finish
                                </Button>
                                <Button size='small' onClick={props.toggleDialog} color="error" variant="contained">
                                    cancel
                                </Button>
                            </Box>
                        </Box>
                    </form>
                </Box>
            </Box>


            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={errors.status}
                autoHideDuration={1500}
                onClose={handleClose}
                message={errors.msg.toString()}
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />


        </Box>
    );
};

Newclient.propTypes = {

};

export default Newclient;
