import { useState, useEffect, useRef } from 'react';
import { Typography, Box, Link, Avatar, AvatarGroup } from '@mui/material';
import Grid from '@mui/material/Grid2'; // New Grid2 component in MUI v6
import ReactDOMServer from 'react-dom/server';
import dwLogo from '../../logo.png';
import styled, { ThemeProvider as StyledThemeProvider } from 'styled-components';
import appVars from '../../appVars.json';
import VerticalSearch from '../../system/controls/vertical-search';
import UserSearch from '../../system/controls/user-search';
import themeFile from '../../theme.json';
import { useToken } from '../../Login/TokenProvider';
import { createTheme } from '@mui/material/styles';
import { lightenHexColor } from '../../dwi-functions';
import Tags from '../../views/tags';
import ClientToolbar from '../client-toolbar';
import Screenshot from '../../system/controls/screenshot';
import Chart from 'react-apexcharts';
import _ from 'lodash';
import { NumericFormat } from 'react-number-format';
var CurrencyFormat = require('react-currency-format');

const theme = createTheme(themeFile);


// Styled Components
const RootContainer = styled(Box)`
  width: 100%;
  min-height: calc(100vh - 20px);
  margin: auto;
  background: 'linear-gradient(315deg, ${theme.palette.secondary.light} -5%, rgba(255,255,255,1) 15%)',minHeight: 'calc(100vh - 180px)'}}
`;

const CompanyInfo = styled(Box)`
  padding: ${({ theme }) => theme.spacing(2)};
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: ${({ theme }) => theme.spacing(1)};
  height: 100%;
`;


const Footer = styled(Box)`
  display: ${({ showFooter }) => (showFooter ? 'flex' : 'none')};
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(2)};
  margin-top: ${({ theme }) => theme.spacing(2)};
  border-top: 2px solid ${({ theme }) => theme.palette.divider};
`;



const FooterImages = styled.img`
  width: 100%;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    max-width: 150px;
  }
  height: auto;
`;





function formatToLocalShortDateTime(utcDateString) {
    const utcDate = new Date(utcDateString); // Parse UTC date string
    const formatter = new Intl.DateTimeFormat(navigator.language, {
        dateStyle: 'short',
        timeStyle: 'short',
    });
    return formatter.format(utcDate); // Format to local short date & time
}

// Component
const Invoice = (props) => {
    const id = sessionStorage.getItem('cid');
    const token = useToken();
    const admintoken = parseInt(token.token.token);

    const tableOptions = {
        headerStyle: {
            background: theme.palette.secondary.light,
        },
        rowStyle: (rowData, index) => ({
            backgroundColor: index % 2 === 1 ? lightenHexColor('#000000', 90) : '#fff',
        }),
        search: false,
        paging: false,
        sorting: false,
        toolbar: false,
    };

    const [client, setClient] = useState();
    const [last5, setLast5] = useState();
    const [last5Dates, setLast5Dates] = useState();


    const [chartData, setChartData] = useState();




    const [loading, setLoading] = useState({
        tags: true,
        last5: true,
        last5Dates: true
    });

    const printRef = useRef();



    async function fetchData() {
        try {

            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");


            const [tagManagerRes, conversionsRes, last5daysRes] = await Promise.all([
                fetch(`${appVars.api_url}/tagmanager/${id}`),
                //fetch(`${appVars.api_url}/tags/${id}`),
                fetch(`${appVars.api_url}/conversions/get/${id}`),
                //   fetch(`${appVars.api_url}/client-results/${id}`),
                // fetch(`${appVars.api_url}/apistats`, { method: 'POST', body: JSON.stringify({ cid: id, type: 'client' }), headers: { 'Content-Type': 'application/json' } }),
                // fetch(`${appVars.api_url}/client/errors/`, { method: "POST", headers: myHeaders, body: JSON.stringify({ "clientid": id }), redirect: "follow" }),
                fetch(`${appVars.api_url}/report-summary`, { method: 'POST', body: JSON.stringify({ cid: id, type: 'client' }), headers: { 'Content-Type': 'application/json' } }),
            ]);

            const [tagManagerData, conversionsData, last5daysData] = await Promise.all([
                tagManagerRes.json(),

                conversionsRes.json(),
                //   clientResultsRes.json(),
                //   apiStatsRes.json(),

                last5daysRes.json()
            ]);

            tagManagerData.pkRecId = id;
            setClient(tagManagerData);
            document.title = `${tagManagerData.clientname}: ${tagManagerData.name}`;


            //  console.log('Tags', last5daysData);
            setLast5(conversionsData);
            // setApiStats(apiStatsData);
            //   setClientResults(clientResultsData);


            const transformedData = _.map(last5daysData, (item) => ({
                date: item.DATE,
                x: new Date(_.get(item, 'DATE')).getTime(),
                y: parseFloat(_.get(item, 'Revenue', '0')),
                max: parseFloat(_.get(item, 'MAX_Revenue', '0')),
                min: parseFloat(_.get(item, 'MIN_Revenue', '0')),
                avg: parseFloat(_.get(item, 'AVG_Revenue', '0')),
                transactions: parseInt(_.get(item, 'transactions', '0')),
            }));




            setChartData({
                series: [
                    {
                        name: 'Revenue By date',
                        data: transformedData,
                    },
                ],
                options: {
                    chart: {
                        type: 'area',
                        zoom: { allowMouseWheelZoom: false },
                        toolbar: {
                            show: false,
                        }
                    },
                    title: {
                        text: "Last 28 Days",
                    },
                    yaxis: {
                        label: {
                            formatter: function (val, index) {
                                return val.toFixed(2);
                            }
                        }
                    },
                    xaxis: {
                        type: 'datetime',
                    },
                    colors: [theme.palette.secondary.main],
                    fill: {
                        type: "gradient",
                        gradient: {
                            shadeIntensity: 1,
                            opacityTo: 0.9,
                            stops: [0, 90, 100]
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    tooltip: {
                        x: { format: 'MM/dd/yyyy' },
                        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                            const dataPoint = w.config.series[seriesIndex].data[dataPointIndex];

                            // console.log("Daily Graph",w.config.series[seriesIndex]);
                            var a = <Box
                                    
                                sx={{
                                    p: 1
                                     }}>
                                    <NumericFormat value={dataPoint.transactions} displayType="text" prefix='Transactions: ' decimalScale={2} thousandSeparator=',' /><br/>
                                    <NumericFormat value={dataPoint.avg} displayType="text" prefix='Average: $' decimalScale={2} thousandSeparator=',' /><br/>
                                    <NumericFormat value={dataPoint.max} displayType="text" prefix='Highest: $' decimalScale={2} thousandSeparator=',' /><br/>
                                    <NumericFormat value={dataPoint.min} displayType="text" prefix='Lowest: $' decimalScale={2} thousandSeparator=',' /><br/>
                                    <NumericFormat value={dataPoint.y} displayType="text" prefix='Total: $' decimalScale={2} thousandSeparator=',' /><br/>
                                </Box>
                           
                            return ReactDOMServer.renderToString(a);
                        },
                    },
                    stroke: {
                        curve: 'smooth',
                        //colors: [theme.palette.primary.light]
                    },
                },

            })








            setLast5Dates(last5daysData);

            setLoading({ tags: false, last5: false, apiStats: false });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    useEffect(() => {
        fetchData();

    }, []);

    const currentYear = new Date().getFullYear();

    return (
        <StyledThemeProvider theme={theme}>
            <RootContainer elevation={props.elevation} variant="elevation">

                {client && (
                    <div>
                        <ClientToolbar client={client} fetchData={fetchData} />
                        <Box ref={printRef} p={theme.spacing(1)} >

                            <Grid container spacing={2} alignContent="flex-start"  >
                                <Grid size={{ xs: 12, md: 5 }} >
                                    <CompanyInfo display={'flex'} justifyContent={'space-between'} >
                                        <Box>
                                            <Typography variant="h5">{client.name}</Typography>
                                            <Typography gutterBottom>Agency: <Link href={client.agency} target="_blank">{client.clientname}</Link></Typography>
                                            <Typography gutterBottom>Website: <Link href={client.siteUrl} target="_blank">{client.siteUrl}</Link></Typography>
                                            <Typography gutterBottom>eCommerce: <Link href={client.donationUrl} target="_blank">{client.donationUrl}</Link></Typography>
                                            <Typography gutterBottom>ID: {client.pkRecId}</Typography>
                                        </Box>
                                        <Box  >
                                            <AvatarGroup max={3} spacing="medium">
                                                <Avatar sx={{ width: 48, height: 48 }} variant="circle" src={`${appVars.api_url}/imageFiles/${client.sitetype}.png`} title={client.sitetype} />
                                                <Avatar sx={{ width: 48, height: 48 }} variant="circle" src={`${appVars.api_url}/imageFiles/${client.donationtype}.png`} title={client.donationtype} />
                                            </AvatarGroup>

                                        </Box>
                                    </CompanyInfo>

                                </Grid>
                                <Grid sx={{ borderRadius: 2, border: '1px solid ' + theme.palette.divider, padding: theme.spacing(2), paddingBottom: 0 }} size={{ xs: 12, md: 4 }}>
                                    <VerticalSearch value={client.vertical} fullWidth disabled />
                                    <UserSearch label="Owner" size="small" fullWidth disabled value={client.owner} />
                                    <UserSearch label="Support" size="small" fullWidth disabled value={client.support} />
                                </Grid>
                                <Grid sx={{ borderRadius: 2, border: '1px solid ' + theme.palette.divider, padding: theme.spacing(1) }} size={{ xs: 12, md: 3 }}>

                                    <Screenshot hideHeader={true} hideFooter={true} admintoken={admintoken} item={client} url={client.siteUrl} clientname={client.name} />
                                </Grid>

                            </Grid>



                            <Grid container spacing={3} paddingTop={1} flexDirection={'row'} >
                                {/* Stats and Tables */}
                                <Grid size={{ xs: 12, md: 9 }}>
                                    <Box pt={1}>
                                        <Chart
                                            options={chartData.options}
                                            series={chartData.series}
                                            type="area"
                                            height={250}
                                        />
                                    </Box>

                                </Grid>
                                <Grid size={{ xs: 12, md: 3 }}>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            border: '1px solid #c7c7c7',
                                            borderRadius: 1,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                background: theme.palette.secondary.light,
                                                display: { md: 'flex' },
                                                justifyContent: 'space-between',
                                                p: 1,
                                                color: "white"
                                            }}
                                        >
                                            <Box>Time</Box>
                                            <Box>Revenue</Box>

                                        </Box>
                                        {last5.map((entry, index) => {
                                           // console.log('Entry', entry)
                                            return (
                                                <Box sx={{ p: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', background: index % 2 === 0 ? '#fff' : "#c7c7c7", }}>
                                                    <Typography color={(index % 2 === 0 ? theme.palette.secondary.main : "#ffffff")}  >{formatToLocalShortDateTime(entry.createdAt)}</Typography>
                                                    <NumericFormat value={entry.revenue} displayType="text" prefix='$' decimalScale={2} thousandSeparator=',' />
                                                </Box>
                                            );

                                        })}
                                    </Box>


                                </Grid>



                                <Grid size={{ xs: 12, md: 12 }}>
                                    <Box pt={1}>
                                        <Tags auditview={true} />
                                    </Box>
                                </Grid>



                            </Grid>


                            {/* Footer */}
                            <Footer showFooter={props.showFooter}>
                                <FooterImages src={`${appVars.api_url}/imageFiles/${client.id}.png`} alt={`Logo | ${client.name}`} />
                                <Typography>&copy; {currentYear} DigitalWerks Innovations.</Typography>
                                <FooterImages src={dwLogo} alt="Logo | DigitalWerks Innovations" />
                            </Footer>
                        </Box>
                    </div>
                )}
            </RootContainer>

        </StyledThemeProvider >
    );
};

export default Invoice;
