import React, { useState, useEffect } from 'react';
import { TextField, MenuItem, LinearProgress } from '@mui/material/';
import appVars from '../../../appVars.json';

const AgencyListing = ({ field, form, ...props }) => {

    const [clientTypes, setClientType] = useState();



    async function fetchData() {
        var url = appVars.api_url + "/clients";
        const res2 = await fetch(url);
        res2
            .json()
            .then((res2) => {
                setClientType(res2);
                //console.log(v);
            })
            .catch((err) => {
                //setErrors({ status: true, msg: err.message });
                //console.log(err);
            });
    }


    useEffect(() => {
        fetchData();
        return () => {

        };
    }, []);




    return (

        <div>
            {(clientTypes ?
                <TextField
                    style={{marginBottom:20}}
                    variant='outlined'
                    shrink
                    label="Agency"
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    color="primary"
                    fullWidth
                    select
                    onChange={props.setClient}
                    {...field} {...props}>
                    {
                        clientTypes.map((entry) => (
                            <MenuItem key={entry.clientId} value={entry.clientId}>{entry.clientname}</MenuItem>
                        ))}

                </TextField>
                : <LinearProgress />)}
        </div>

    );
};

AgencyListing.propTypes = {

};

export default AgencyListing;
