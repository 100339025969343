import React from 'react';
import FouRoFour from '../../images/404.gif';
import { Typography } from '@mui/material';

const ErrorBoundary = ({ error }) => {
    return (
        <div style={{ padding: '2rem', textAlign: 'center' }}>
            <Typography variant='h4' color="error" >Oops! Something went wrong.</Typography>
            <p>{error?.message || <img src={FouRoFour} alt="404" />}</p>
            
        </div>
    );
};

ErrorBoundary.propTypes = {

};

export default ErrorBoundary;
