import React, { useState, useEffect } from 'react';
import { Autocomplete } from '@mui/lab';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import appVars from '../../../appVars.json';
import styled from 'styled-components';
import _ from 'lodash';
import { LinearProgress, Typography } from '@mui/material';

// Styled components
const FormControlWrapper = styled.div`
  margin-bottom: ${15}px;
`;

const StyledChip = styled(Chip)`
  margin: 2px;
  overflow: hidden;
`;

const AdAccounts = (props) => {
  const [selectedOptions, setSelectedOptions] = useState(() => {
    // Check if defaultValue is a string and contains commas (CSV string)
    if (typeof props.defaultValue === 'string' && props.defaultValue.includes(',')) {
      return props.defaultValue.split(',').map(value => value.trim());
    }
    // Handle single numbers, single strings without commas, and non-empty strings
    else if (props.defaultValue || typeof props.defaultValue === 'number') {
      return [`${props.defaultValue}`]; // Convert to string for consistency
    }
    // Return an empty array if defaultValue is undefined, null, or empty
    return [];
  });

  const [options, setOptions] = useState();

  async function fetchData() {
    let type = '';
    let url = appVars.autogate;
    const requestOptions = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      body: JSON.stringify({ token: props.saKey })
    };

    switch (props.name) {
      case 'google_paid':
        url += "/google/accountids/get";
        type = 'Paid';
        break;
      case 'google_grant':
        url += "/google/accountids/get";
        type = 'Grant';
        break;
      case 'meta':
        url += "/meta/ids";
        break;
      case 'bing':
        url += "/bing/accounts";
        break;
      case 'stackadapt':
        url += "/stackadapt/api/ga";
        break;
      default:
        url = "";
        break;
    }

    if (url === "") return false;

    try {
      const response = await fetch(url, requestOptions);
      const data = await response.json();
      const filteredData = type ? _.filter(data, (row) => _.includes(row.ACCOUNT_NAME, type)) : data;
      setOptions(filteredData);
    } catch (error) {
      console.error('error', error);
    }
  }

  useEffect(() => {
    if (!options) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [props, options]);

  return (
    <FormControlWrapper>
      <Typography variant='h5' color='textPrimary' gutterBottom sx={{mb:3}}>{props.label} </Typography>
      {options ? (
        <Autocomplete
          multiple
          id="tags-standard"
          disabled={props.admintoken < 6}
          options={options}
          getOptionLabel={(option) => `${option.ACCOUNT_NAME} - ${option.ACCOUNT_ID}`}
          defaultValue={selectedOptions.map(
            (option) => options.find((o) => o.ACCOUNT_ID.toString() === option) || {}
          )}
          data-tab={props.tab}
          onChange={(e, newValue) => {
            const reportReturn = Object.keys(newValue).map((key, index) => newValue[index].ACCOUNT_ID);
            e.stopPropagation();
            setSelectedOptions(reportReturn);
            if (props.onSelectionChange) {
              props.onSelectionChange(reportReturn, props.tab, props.label);
            }
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <StyledChip
                data-tab={props.tab}
                label={option.ACCOUNT_NAME}
                {...getTagProps({ index })}
                color="primary"
                key={option.ACCOUNT_ID}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              data-tab={props.tab}
              {...params}
              variant="outlined"
              label={`Select ${props.label}`}
            />
          )}
        />
      ) : (
        <LinearProgress />
      )}
    </FormControlWrapper>
  );
};

export default AdAccounts;
