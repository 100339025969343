import React, { useState, useEffect } from 'react';
import appVars from '../../appVars.json';
import { useLocation } from 'react-router-dom';
import 'grapesjs/dist/css/grapes.min.css';
import grapesjs from 'grapesjs';




const GrapeEditor = (props) => {

    const location = useLocation();
    const id = location.pathname.split("/")[2];
    const [page, setPage] = useState();


    const editor = grapesjs.init({
        storageManager: {
          type: 'remote',
          stepsBeforeSave: 1,
          options: {
            remote: {
              urlStore: appVars.api_url + '/editor/save/' + id, // Endpoint URL where to store data project
              urlLoad: appVars.api_url + '/editor/load/' + id, // Endpoint URL where to load data project
              onLoad: result => result.settings,
              onStore: data => ({ pkRecID: id, settings: data }),
              fetchOptions: opts => (opts.method === 'POST'),
            }
          },
        },
        container: '#gjs',
        plugins: [
          'gjs-blocks-basic',
        /*  'grapesjs-plugin-forms',
          'grapesjs-plugin-export',
          'grapesjs-tabs',
          'grapesjs-custom-code',
          'grapesjs-touch',
          'grapesjs-parser-postcss',
          'grapesjs-tooltip',
          'grapesjs-style-bg',
          'grapesjs-preset-webpage',
          '@silexlabs/grapesjs-fonts',
          'grapesjs-script-editor',
          //  'grapesjs-tailwind',
          'grapesjs-component-code-editor',
          'grapesjs-style-border',
          // '@silexlabs/grapesjs-storage-rate-limit' */
        ],
        pluginsOpts: {
          '@silexlabs/grapesjs-fonts': {
            api_key: 'AIzaSyDgDM-bQsHN5KSVtW31sG1RUYZKXAz2Vcc',
          },
          'gjs-blocks-basic': {
            flexGrid: true
          }
        },
        'grapesjs-preset-webpage': {
          modalImportTitle: 'Import Template',
          modalImportLabel: '<div style="margin-bottom: 10px; font-size: 13px;">Paste here your HTML/CSS and click Import</div>',
          modalImportContent: function (editor) {
            return editor.getHtml() + '<style>' + editor.getCss() + '</style>'
          }
        },
        panels: {
          defaults: []
        }
      });





    return (
        <div>

            {page ?
                <div id='gjs'>
              
                </div>
                : ''}
        </div>
    );
};

GrapeEditor.propTypes = {

};

export default GrapeEditor;
