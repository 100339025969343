import React, { Fragment } from "react";
import { Box, styled } from "@mui/system";
import {
  Typography,
  Avatar,
} from "@mui/material";
import {
  BsEye,
  BsMeta,
  BsBing,
  BsDisplay,
  BsGoogle,
} from "react-icons/bs";
import {
  SiGoogleanalytics,
  SiGoogleads,
} from "react-icons/si";
import { GrAnalytics } from "react-icons/gr";
import { TbView360Number } from "react-icons/tb";

const StyledIcon = styled(Box)({
  maxWidth: 100,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const CookieReader = (props) => {
  const c = JSON.parse(props.cookie);
  return (
    <Box>
      <Box display="flex" flexWrap="wrap" alignItems="center" gap={2}>
        {Object.entries(c).map((entry) => {
          let iconComponent = null;
          switch (entry[0]) {
            case "_ga":
              iconComponent = (
                <StyledIcon>
                  <SiGoogleanalytics color={c["_uman"] ? "green" : "white"} />
                </StyledIcon>
              );
              break;
            case "_gid":
              iconComponent = (
                <StyledIcon>
                  <TbView360Number color={c["_uman"] ? "green" : "white"} />
                </StyledIcon>
              );
              break;
            case "_fbp":
              iconComponent = (
                <StyledIcon>
                  <BsMeta color={c["_fbc"] ? "green" : "white"} />
                </StyledIcon>
              );
              break;
            case "gclid":
              iconComponent = (
                <StyledIcon>
                  <SiGoogleads color={c["gclid"]?.length > 1 ? "green" : "white"} />
                </StyledIcon>
              );
              break;
            case "sa-user-id":
              iconComponent = (
                <StyledIcon>
                  <BsDisplay color={c["_saclid"] ? "green" : "white"} />
                </StyledIcon>
              );
              break;
            case "_uetuid":
              iconComponent = (
                <StyledIcon>
                  <BsBing color={c["_uetmsclkid"] ? "green" : "white"} />
                </StyledIcon>
              );
              break;
            default:
              if (entry[0].indexOf("_pk") >= 0) {
                iconComponent = (
                  <StyledIcon>
                    <GrAnalytics color={c["_pkid"] ? "green" : "white"} />
                  </StyledIcon>
                );
              } else if (entry[0].indexOf("Stackify") >= 0) {
                iconComponent = (
                  <StyledIcon>
                    <Avatar src="../../images/stackify.png" />
                  </StyledIcon>
                );
              }
              break;
          }
          return <Fragment key={entry[0]}>{iconComponent}</Fragment>;
        })}
      </Box>
    </Box>
  );
};

CookieReader.propTypes = {};

export default CookieReader;
