import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Box, MenuItem, Typography, Button, AppBar, Toolbar, ButtonGroup, TextField, InputBase } from '@mui/material';
import Grid from '@mui/material/Grid2';
import DateRangeSelector from '../../../system/controls/date-range-selector';
import { FcBullish, FcSearch, FcGoogle, FcTemplate, FcDocument } from "react-icons/fc";
import { SaveOutlined, CancelOutlined } from "@material-ui/icons";
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import appVars from '../../../appVars.json';
import { useToken } from '../../../Login/TokenProvider';

const StyledAppBar = styled(AppBar)`
  position: relative;
  color: transparent;
`;

const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
  background: linear-gradient(90deg, rgba(74,151,220,1) 0%, rgba(0,0,0,1) 97%);
`;

const StyledButtonGroup = styled(ButtonGroup)`
  margin-left: auto;
`;

const TitleTool = styled(Typography)`
  flex-grow: 1;
  color: ${(props) => props.theme.palette.text.white};
`;

const SaveButton = styled(Button)`
  color: white !important;
  background: limegreen !important;
`;

const CancelButton = styled(Button)`
  color: white !important;
  background: red !important;
  margin-right: ${(props) => props.spacing} !important;
`;

const StyledButton = styled(Button)`
 margin-right: ${(props) => props.spacing} !important;
`;

const ConversionToolbar = ({ field, form, ...props }) => {
  const token = useToken();
  const admintoken = token.token;

  const _d = new Date();
  _d.setDate(_d.getDate() - 7);

  const [selectedDateS, setSelectedDateS] = React.useState(moment(_d).format("YYYY-MM-DD"));
  const [selectedDateE, setSelectedDateE] = React.useState(moment(new Date()).format("YYYY-MM-DD"));

  const theme = useTheme();

  const handleRangeSelect = (rangeKey) => {
   /*
    console.log(rangeKey);
    console.log('report Start',rangeKey.startDate.toDateString().split('T')[0]);
    console.log('report End',rangeKey.endDate.toDateString().split('T')[0]);
*/
    setSelectedDateS(rangeKey.startDate.toDateString().split('T')[0]);
    setSelectedDateE(rangeKey.endDate.toDateString().split('T')[0]);
    props.setSelectedDateS(rangeKey.startDate.toDateString().split('T')[0]);
    props.setSelectedDateE(rangeKey.endDate.toDateString().split('T')[0]);


  };

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Grid item sm={12}>
          <StyledAppBar>
            <StyledToolbar disableGutters>
              <Box p={1} width="30%" >
                <TextField
                  style={{ color: 'white' }}
                  select
                  type="number"
                  size="small"
                  fullWidth
                  variant="filled"
                  label="Number of records to show"
                  value={props.pageSize}
                  onChange={(e) => props.setPageSize(Number(e.target.value))}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={15}>15</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                  <MenuItem value={500}>500</MenuItem>
                  <MenuItem value={1000}>1000</MenuItem>
                  <MenuItem value={100000000}>All</MenuItem>
                </TextField>
              </Box>
              <Box width="100%" flexGrow={1} >

              </Box>
              <Box pr={1} width="35%" display='flex' alignItems="center" flexDirection="row">
                <DateRangeSelector
                  childToParent={handleRangeSelect}
                  sDate={selectedDateS}
                  eDate={selectedDateE}
                  icon={true}
                />
                <TitleTool variant="body1" theme={theme}>
                  {new Intl.DateTimeFormat('en-US', { month: 'long', day: '2-digit', year: 'numeric', timeZone: "UTC" }).format(new Date(selectedDateS))} - {new Intl.DateTimeFormat('en-US', { month: 'long', day: '2-digit', year: 'numeric'}).format(new Date(selectedDateE))}
                </TitleTool>
              </Box>
            </StyledToolbar>
          </StyledAppBar>
        </Grid>
      </ThemeProvider>
    </div>
  );
};

ConversionToolbar.propTypes = {};

export default ConversionToolbar;
