import  { useState, useEffect } from 'react';
import { Link, Typography, Snackbar, CircularProgress } from '@mui/material';
import { Alert } from '@mui/lab';
import MaterialTable from 'material-table';
import { styled, ThemeProvider, useTheme } from '@mui/system';
import appVars from '../appVars.json';

// Styled container for layout
const Container = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  width: '100%',
}));

const ErrorView = (props) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [errorlog, setErrorLog] = useState();
  const [error, setError] = useState({ show: false, message: 'Test', severity: 'error' });
  const [progress, setProgress] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError({ show: false, message: '', severity: 'success' });
  };

  async function fetchData() {
    try {
      if (props.clientid === undefined) {
        const response = await fetch(appVars.api_url + '/errors');
        const data = await response.json();
        console.log(data);
        setErrorLog(data);
        setProgress(true);
        setLoading(false);
      } else {
        const raw = JSON.stringify({ clientid: props.clientid });
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        const response = await fetch(appVars.api_url + '/client/errors', {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow',
        });
        const data = await response.json();
        setErrorLog(data);
        setProgress(true);
        setLoading(false);
      }
    } catch (err) {
      setError({ show: true, message: err.message, severity: 'error' });
    }
  }

  useEffect(() => {
    fetchData();
  }, [props]);

  return (
    <ThemeProvider theme={theme}>
      <Container>
        {errorlog ? (
          <MaterialTable
            isLoading={loading}
            columns={[
              { title: '', type: 'datetime', field: 'createdAt', defaultSort: 'desc', cellStyle: { padding: '3px 10px' }, hidden: true },
              {
                title: 'Name',
                field: 'name',
                defaultSort: 'asc',
                cellStyle: { padding: '3px 10px' },
                render: (rowData) => (
                  <Typography style={{ fontSize: '13px' }}>
                    <Link href={'/ss/' + rowData.clientid} title={rowData.name}>
                      {(rowData.name.length > 50 ? rowData.name.substring(0, 50) : rowData.name)} ...
                    </Link>
                  </Typography>
                ),
              },
              {
                title: 'Error',
                field: 'error_message',
                defaultSort: 'desc',
                cellStyle: { padding: '3px 10px' },
                render: (rowData) => (
                  <Typography style={{ fontSize: '13px' }} title={rowData.error_code}>
                    {(rowData.error_message.length > 375 ? rowData.error_message.substring(0, 375) : rowData.error_message)}
                  </Typography>
                ),
              },
            ]}
            data={errorlog}
            options={{
              rowStyle: { fontSize: '13px', padding: 0, margin: 0 },
              headerStyle: { fontSize: '13px' },
              search: true,
              grouping: false,
              pageSize: 10,
              pageSizeOptions: [5, 10, 20, 25, 40, 50, 100],
              showTitle: true,
              paging: true,
              header: false,
              toolbar: false,
            }}
          />
        ) : (
          <CircularProgress hidden={progress} />
        )}

        <Snackbar open={error.show} autoHideDuration={3500} onClose={handleClose}>
          <Alert severity={error.severity} onClose={handleClose}>
            {error.message}
          </Alert>
        </Snackbar>
      </Container>
    </ThemeProvider>
  );
};

export default ErrorView;
