import { useEffect, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Box, MenuItem, Button, Toolbar, ButtonGroup, TextField, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import appVars from '../../../appVars.json';
import { FcPlus, FcPrint } from 'react-icons/fc';
import { generatePDF } from '../../../dwi-functions';
import _ from "lodash";


const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
  background: linear-gradient(90deg, rgba(74,151,220,1) 0%, rgba(0,0,0,1) 97%);
`;

const LandingPageToolbar = (pageprops) => {
  const [tagProfiles, setTagProfiles] = useState(false);
  const theme = useTheme();

  const fetchData = async () => {
    const requestOptions = { method: "GET", redirect: "follow" };

    try {
      const res1 = await fetch(appVars.api_url + "/tag_profiles", requestOptions);
      const tagProfilesData = await res1.json();
      setTagProfiles(_.sortBy(tagProfilesData, "name"));

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };



  useEffect(() => {
    if (!tagProfiles) fetchData();

  }, [tagProfiles]);


  return (
    <div>
      <ThemeProvider theme={theme}>


        <StyledToolbar >
          <Box
            sx={{
              flex: 1
            }}
          >

          </Box>
          <ButtonGroup size="small" color="secondary">
            <Button size="small" onClick={pageprops.addNew}>
              <FcPlus size={30} />
            </Button>
            <Button title="Print List of Landing Pages" onClick={generatePDF}>
              <FcPrint size={30} />
            </Button>
          </ButtonGroup>
        </StyledToolbar>


      </ThemeProvider>
    </div>
  );
};

LandingPageToolbar.propTypes = {};

export default LandingPageToolbar;
