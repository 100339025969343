import { Typography, Button, Snackbar, AppBar, Tab, Tabs, Box, Breadcrumbs, Link, Paper, ButtonGroup, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import appVars from '../../appVars.json';
import Alert from "@mui/lab/Alert";
import PropTypes from 'prop-types';
import themeFile from '../../theme.json';
import SubClientList from '../sub-client-list';
import { CancelOutlined, Save } from '@mui/icons-material';

import { Formik, Form, Field } from 'formik';
import { TextField } from '@mui/material/';
import Users from '../../UserTable';
import { MdCellTower } from "react-icons/md";
import FileUploads from '../../system/file-uploads';
import { useToken } from '../../Login/TokenProvider';
import { useLocation } from 'react-router-dom';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from 'styled-components';
import { Add, GridOffRounded, List } from "@mui/icons-material";
import Newclient from '../../clients/newclient';
import ErrorDetail from '../errors';
import ApigateStatus from '../../system/apigate-status';

// Create the Material-UI theme
const theme = createTheme(themeFile);


// Styled components
const Root = styled.div`
  display: flex;
   width:100%;
`;




const StyledAppBar = styled(AppBar)`
    background: linear-gradient(90deg,rgba(0,0,0,1) 0%, rgba(255,87,34,1) 97% );
   color: #fff;
  padding:  ${({ theme }) => theme.spacing(0)};
   
`;

const StyledButtonGroup = styled(ButtonGroup)`
  margin-left: auto;
  background: lightgray;
`;
const StyledButton = styled(Button)`
 margin-right: ${(props) => props.spacing} !important;
`;
const StyledPaper = styled(Paper)`
  padding: ${({ theme }) => theme.spacing(2)};
  margin-left: ${({ theme }) => theme.spacing(1)};
  margin-right: ${({ theme }) => theme.spacing(1)};
  box-shadow: 0px 0px 24px -15px #000000;
  
`;

const SidePaper = styled(Paper)`
  padding: ${({ theme }) => theme.spacing(1)};
    box-shadow: 0px 0px 24px -15px #000000;
`;

const ClientLogo = styled.img`
  max-width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
  padding:10px;
  background: #c7c7c7;
  box-shadow: 0px 10px 25px -10px ${({ theme }) => theme.palette.secondary.dark};
`;

const CustomButton = styled(Button)`
  margin-right: ${({ theme }) => theme.spacing(1)};
  padding-right: ${({ theme }) => theme.spacing(0)};
  min-width: 120px;
  width: calc(10%);
  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: calc(100%);
    margin-bottom: ${({ theme }) => theme.spacing(0)};
    padding-right: ${({ theme }) => theme.spacing(0)};
  }
`;

const InputField = styled(Field)`
  margin-bottom: ${({ theme }) => theme.spacing(2.5)};
  padding-right: ${({ theme }) => theme.spacing(2.5)};
  min-width: 120px;
  width: calc(100%);
  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-bottom: ${({ theme }) => theme.spacing(0)};
    padding-right: ${({ theme }) => theme.spacing(0)};
  }
`;

const StateField = styled(InputField)`
  width: calc(45%);
`;

const ZipField = styled(InputField)`
  width: calc(8%);
  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
  }
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const SingleClient = (props) => {
  const [hasError, setErrors] = useState(false);
  const [open, setOpen] = useState(false);
  const token = useToken();
  const admintoken = token.token;
  const location = useLocation();
  var id = location.pathname.split("/")[2];
  const searchParams = new URLSearchParams(location.search);
  const tab = (searchParams.get('tab') ? Number(searchParams.get('tab')) : 0)
  const [hideList, setHideList] = useState(true);
  const [hideGrid, setHideGrid] = useState(false);
  const [client, setClient] = useState([{ "clientname": 'Default Name' }]);
  const [clientData, setClientData] = useState([{ "clientname": 'Default Name' }]);
  const [value, setValue] = useState(tab);

  const MyInput = ({ field, form, ...props }) => (
    <TextField size="small" variant="outlined" shrink {...field} {...props} />
  );


  const toggleDialog = () => {

    setOpen(!open)
    fetchData();
  };

  const handleToggleView = (e) => {
    const view = e.currentTarget.getAttribute('toggle');
    setHideGrid(view === 'list');
    setHideList(view === 'grid');
    // console.log('view',view);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") return;
    setErrors(false);
  };

  const childToParent = (childdata) => {
    var f = appVars.api_url + '/imageFiles/' + id + '.png';
    const img = document.getElementById('avatar');
    img.src = f; console.log(f);
  };

  async function fetchData() {
    const res = await fetch(appVars.api_url + "/clients/" + id);
    res
      .json()
      .then(res => {
        setClient(res);
        setClientData(res[0]);
        // console.log("Client Results", res[0]);
      })
      .catch(err => setErrors(true));
  }

  useEffect(() => {
    fetchData();

  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Root>
        <Grid sx={{ width: '100%' }} container spacing={0}>
          <Grid size={{ md: 12 }}>
            <Box sx={{
              display: 'flex',
              aligns: 'center',
              background: 'linear-gradient(90deg, rgba(74,151,220,1) 0%, rgba(0,0,0,1) 97%)',
              p: {
                md: 1
              }
            }} >
              <Breadcrumbs style={{ flex: 1, color: "#fff", width: '100%', padding: theme.spacing(.5) }} aria-label="breadcrumb">
                <Link color="#ffffff" href="/">Dashboard</Link>
                <Link color="#ffffff" href={`/agency/${client[0].clientId}`}>{client[0].clientname}</Link>
                <Typography style={{ flex: 1 }} color="#000000">Active Clients</Typography>

              </Breadcrumbs>
              <StyledButtonGroup size="small" variant="text" color="secondary">

                <StyledButton title="Add New Client" onClick={toggleDialog} >
                  <Add size={30} />
                </StyledButton>
                <StyledButton toggle="grid" title="Toggle Grid View" onClick={handleToggleView} >
                  <GridOffRounded size={30} />
                </StyledButton>
                <StyledButton toggle="list" title="Toggle List View" onClick={handleToggleView}>
                  <List size={30} />
                </StyledButton>
                <StyledButton toggle="list" title="Client Platform Status" href={appVars.platformsUrl + '/status/' + client[0].clientId} target="_BLANK" >
                  <MdCellTower size={30} />
                </StyledButton>
              </StyledButtonGroup>
            </Box>

            <StyledAppBar position="static" fullWidth sx={{ marginTop: '0em', marginBottom: theme.spacing(0), width: '100%' }}>

              <Tabs indicatorColor="secondary" textColor="#ffffff" value={value} onChange={handleChange} variant="standard" aria-label="basic tabs example">
                <Tab label="Active Client List" />
                <Tab label="In-Active Client List" />
                <Tab label="Account Team" />
                <Tab label="Settings" />
              </Tabs>
            </StyledAppBar>

            <TabPanel value={value} index={0}>
              <Grid container spacing={0}>
                <Grid size={{ md: 9 }} >
                  <SubClientList open={open} hideGrid={hideGrid} hideList={hideList} client={client[0].id} logo={client[0].logo} status={true} />
                </Grid>
                <Grid sx={{ mt: 1 }} size={{ md: 3 }} >
                  <ErrorDetail agency={id} />
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel value={value} index={1}>
              <Grid container spacing={0}>
                <Grid size={12}>

                  <SubClientList open={open} hideGrid={hideGrid} hideList={hideList} client={client[0].id} logo={client[0].logo} status={false} />
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel value={value} index={2}>
              <Users clientid={client[0].clientId} />
            </TabPanel>

            <TabPanel value={value} index={3}>
              <Grid container spacing={1} sx={{ paddingTop: theme.spacing(1) }}  >
                <Grid size={{ md: 9 }}>
                  <StyledPaper>
                    <Formik
                      enableReinitialize
                      initialValues={clientData}
                      onSubmit={async (values) => {
                        const url = values.pkRecId === "0"
                          ? `${appVars.api_url}/gtm/add/`
                          : `${appVars.api_url}/gtm/update/`;


                        console.log("Written Data", values);


                        const response = await fetch(url, {
                          method: 'POST',
                          body: JSON.stringify(values),
                          headers: { 'Content-Type': 'application/json' },
                        });
                        response
                          .then(() => fetchData())
                          .catch(err => console.error(err));
                      }}
                    >
                      <Form>
                        <InputField component={MyInput} type="text" id="clientname" label="Display Name" fullWidth name="clientname" />
                        <InputField component={MyInput} type="text" id="address" label="Address" fullWidth name="address" />
                        <StateField component={MyInput} type="text" id="city" label="City" name="city" />
                        <StateField component={MyInput} type="text" id="state" label="State" name="state" />
                        <ZipField component={MyInput} type="text" id="zip" label="Zip" name="zip" />
                        <StateField component={MyInput} type="text" id="email" label="Email" name="email" />
                        <StateField component={MyInput} type="text" id="phone" label="Phone" name="phone" />
                        <InputField component={MyInput} type="text" id="apiPort" label="Reportwerks API Port" fullWidth name="apiPort" />
                        <CustomButton variant="contained" size="medium" color="secondary" type="submit" startIcon={<Save />}>Save</CustomButton>

                        <CustomButton variant="contained" size="medium" color="default" startIcon={<CancelOutlined />}>Cancel</CustomButton>
                      </Form>
                    </Formik>
                  </StyledPaper>
                </Grid>
                <Grid size={{ md: 3 }}>
                  <SidePaper>
                    <ClientLogo id='avatar' src={`${appVars.api_url}/imageFiles/${id}.png`} alt={client[0].clientId} />

                    <FileUploads
                      showButton={true}
                      text="Upload Logo"
                      suggest="430px x 80px PNG ONLY"
                      admintoken={admintoken}
                      themeColor={{ "primary": theme.palette.primary.main, "secondary": theme.palette.secondary.main }}
                      newid={id}
                      childToParent={childToParent} />
                    <ApigateStatus client={client} />
                  </SidePaper>
                </Grid>
              </Grid>
            </TabPanel>
          </Grid>
        </Grid>

        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth
          maxWidth={'lg'}
        >
          <DialogTitle>Add New Client</DialogTitle>
          <DialogContent>
            <Newclient toggleDialog={toggleDialog} clientid={id} handleClose={handleClose} />
          </DialogContent>
        </Dialog>
        <Snackbar open={hasError} autoHideDuration={3000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            The data can not be pulled at this time.
            <Button color="primary" onClick={() => window.location.reload()}>Refresh</Button>
          </Alert>
        </Snackbar>
      </Root>
    </ThemeProvider>
  );
};

SingleClient.propTypes = {};

export default SingleClient;
