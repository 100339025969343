import { Box, Button, ButtonGroup, Divider, ThemeProvider, Typography, Link, Avatar } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useState } from 'react';
import { lightenHexColor } from '../../dwi-functions';
import MaterialTable from 'material-table';
import { IoIosCloseCircleOutline } from "react-icons/io";
import { FcBusinessContact, FcEditImage, FcViewDetails } from "react-icons/fc";
import appVars from '../../appVars.json';
import { useTheme } from '@mui/material/styles';
import { NumericFormat } from 'react-number-format';
import themeFile from '../../theme.json';
import { useToken } from "../../Login/TokenProvider";



const ClientViewer = (props) => {
  const theme = useTheme();
  const [clCount, setCl] = useState(0);
  const token = useToken();
  const admintoken = token.token.token;
 // console.log(admintoken);
  return (
    <ThemeProvider theme={theme}>
      {props.clients && (
        <MaterialTable
          data={props.clients}
          totalCount={props.clients.length}
          options={{
            showTitle: false,
            pageSize: props.clients.length,
            paging: false,
            toolbar: false,
            header: false,
          }}
          columns={[
            {
              type: 'string',
              field: 'clientname',
              title: 'Client Name',
              render: (rowData) => <Link href={'/agency/' + rowData.clientId}>{rowData.clientname}</Link>,
            },
            {
              type: 'numeric',
              field: 'clientCount',
              title: 'Client Count',
            },
          ]}
          detailPanel={[
            {
              tooltip: 'Show Details',
              icon: () => <FcViewDetails />,
              openIcon: () => <IoIosCloseCircleOutline color="red" />,
              render: (rowData) => (
                <Grid container>
                  <Grid size={{ xl: 2, xs: 3 }} sx={{ background: themeFile.palette.appBar.tagmanager }}>
                    <Box color="#ffffff" p={1}>
                      <img
                        style={{
                          borderRadius: theme.shape.borderRadius,
                          padding: theme.spacing(1),
                          maxWidth: 100,
                          background: lightenHexColor('#000000', 80),
                        }}
                        alt=""
                        src={`${appVars.api_url}/imageFiles/${rowData.clientId}.png`}
                        onError={(e) => {
                          e.target.style.border = "1px dashed white";
                          e.target.style.borderRadius = '50%';
                          e.target.src = 'https://placehold.co/100x100/00a07e/FFFFFF.png?text=No+Logo&tex';
                        }}
                      />
                      <Typography variant="body1">
                        <Link color="textSecondary" href={'/agency/' + rowData.clientId}>
                          {rowData.clientname}
                        </Link>
                      </Typography>
                      <Typography variant="body2">{rowData.addess}</Typography>
                      <Typography variant="body2">
                        {rowData.city}, {rowData.state} {rowData.zip}
                      </Typography>
                      <Typography variant="body2">{rowData.phone}</Typography>
                      <Typography variant="body2">
                        <Link color="textSecondary" href={'/agency/' + rowData.email}>
                          {rowData.email}
                        </Link>
                      </Typography>
                      {admintoken >= 10 && (
                        <div>
                          <Divider style={{ marginTop: 1 }} />
                          <ButtonGroup size="small" variant="outlined">
                            <Button href={'/agency/' + rowData.clientId + '?tab=3'} variant="text" startIcon={<FcEditImage />}></Button>
                            <Button href={'/agency/' + rowData.clientId + '?tab=2'} variant="text" startIcon={<FcBusinessContact />}></Button>
                          </ButtonGroup>
                        </div>
                      )}
                    </Box>
                  </Grid>
                  <Grid size={{ xl: 10, xs: 9 }}>
                    <MaterialTable
                      title=""
                      data={(query) =>
                        new Promise(async (resolve, reject) => {
                          try {
                            const myHeaders = new Headers();
                            myHeaders.append("Content-Type", "application/json");

                            const raw = JSON.stringify({
                              aid: rowData.clientId,
                              type: "agency",
                              date: new Date().toISOString().split('T')[0],
                            });

                            const requestOptions = {
                              method: 'POST',
                              headers: myHeaders,
                              body: raw,
                              redirect: 'follow',
                            };

                            const res = await fetch(appVars.api_url + "/report-summary", requestOptions);
                            const creatives = await res.json();

                            setCl(creatives.length);
                            resolve({
                              data: creatives,
                              page: 0,
                              totalCount: creatives.length,
                            });
                          } catch (err) {
                            console.error(err);
                            reject(err);
                          }
                        })
                      }
                      options={{
                        rowStyle: (rowData, index) => ({
                          background: index % 2 === 0 ? '#fff' : lightenHexColor(theme.palette.secondary.main, 0),
                        }),
                        headerStyle: {
                          backgroundColor: lightenHexColor(theme.palette.primary.light, 10),
                          color: '#ffffff',
                          padding: 0,
                        },
                        cellStyle: {
                          padding: theme.spacing(1),
                        },
                        tableLayout: 'auto',
                        pageSize: clCount,
                        grouping: false,
                        searchFieldVariant: "outlined",
                        exportButton: true,
                        exportAllData: true,
                        search: false,
                        paging: false,
                        showTitle: false,
                        toolbar: false,
                        sort: false,
                      }}
                      columns={[
                        { title: "Client Name", field: "id", key: 'id', hidden: true },
                        {
                          title: "Today",
                          field: "id",
                          render: (rowData) => (
                            <Box 
                            sx={{
                            }}
                            >
                              <Avatar
                              variant="rounded"
                               sx={{ background: 'lightgrey', padding: 1, width: '100%', marginRight: 2, boxShadow: '10px 10px 24px -15px #000000' }}
                              alt=""
                              src={`${appVars.api_url}/imageFiles/${rowData.id}.png`}
                              onError={(e) => {
                                e.target.style.border = "1px dashed white";
                                e.target.src = 'https://placehold.co/160x90/00a07e/FFFFFF.png?text=No+Logo';
                              }}
                            />
                            </Box>
                          ),
                        },
                        {
                          title: "Client Name",
                          type: 'string',
                          field: "name",
                          render: (rowData) => <Link href={'/ss/' + rowData.id}>{rowData.name}</Link>,
                        },
                        {
                          title: "Transactions",
                          type: 'numeric',
                          field: "transactions",
                          render: (rowData) => (
                            <Typography align="center">
                              <NumericFormat decimalScale={0} thousandSeparator="," value={rowData.transactions} displayType="text" />
                            </Typography>
                          ),
                        },
                        {
                          title: "AVG",
                          type: 'currency',
                          field: "AVG_Revenue",
                          render: (rowData) => (
                            <NumericFormat prefix="$" decimalScale={2} fixedDecimalScale thousandSeparator="," value={rowData.AVG_Revenue} displayType="text" />
                          ),
                        },
                        {
                          title: "Max",
                          type: 'currency',
                          field: "MAX_Revenue",
                          render: (rowData) => (
                            <NumericFormat prefix="$" decimalScale={2} fixedDecimalScale thousandSeparator="," value={rowData.MAX_Revenue} displayType="text" />
                          ),
                        },
                        {
                          title: "Total",
                          type: 'currency',
                          field: "Revenue",
                          render: (rowData) => (
                            <NumericFormat prefix="$" decimalScale={2} fixedDecimalScale thousandSeparator="," value={rowData.Revenue} displayType="text" />
                          ),
                        },
                      ]}
                    />
                  </Grid>
                </Grid>
              ),
            },
          ]}
        />
      )}
    </ThemeProvider>
  );
};

ClientViewer.propTypes = {};

export default ClientViewer;
