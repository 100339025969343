import { React, useEffect, useState } from 'react';
import { MenuItem, TextField } from '@mui/material';

import appVars from '../../../appVars.json';

const MatomoSites = (props) => {

    const [sites, setSites] = useState([{ idsite: '10', name: "" }]);







    async function fetchData() {
        try {
            var data = {
                module: 'API',
                method: 'SitesManager.getAllSites',
                format: 'json', // Assuming 'json' should be lowercase
                token_auth: appVars.matomo_token
            };

            const response = await fetch(appVars.matomo_url + "/index.php", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams(data).toString()
            });

            // Check if the response status is OK
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const resJson = await response.json();
            setSites(resJson);
        } catch (error) {
            console.error('Fetching Matomo data failed:', error);
        }
    }







    useEffect(() => {
        fetchData();


    }, []);

    return (
        <div >
            <TextField
            style={{ marginBottom: 20,marginTop:20 }}

                variant="outlined"
                fullWidth
                select
                {...props}

            >
                <MenuItem value="0">
                    <em>Do Not Track</em>
                </MenuItem>
                {Object.entries(sites).map((prop, key) => {
                    //  console.log(prop[1]);
                    return <MenuItem value={prop[1].idsite}>{prop[1].idsite} - {prop[1].name}</MenuItem>
                })
                }
            </TextField>
        </div>
    );
};

MatomoSites.propTypes = {

};

export default MatomoSites;
