import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import {
    Box,
    Card,
    CardContent,
    Typography,
    Avatar,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Link,
    useTheme,
    LinearProgress,
    CardHeader,
    Button,
    Toolbar,
    ButtonGroup
} from "@mui/material";
import { useToken } from '../../Login/TokenProvider';
import { useLocation } from 'react-router-dom';
import appVars from '../../appVars.json';
import _ from "lodash";
import { lightenHexColor } from '../../dwi-functions';
import AgencyListing from '../../system/controls/agency-listing';
import VerticalSearch from '../../system/controls/vertical-search';


const ProfilePage = (props) => {

    const [owners, setowners] = useState();
    const [support, setsupport] = useState();
    const [user, setUser] = useState();
    const token = useToken();
    const admintoken = token.token;
    const location = useLocation();
    var id = location.pathname.split("/")[2];
    const theme = useTheme();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: user?.name || '',
            email: user?.email || '',
            client: user?.client || ''
        },
        onSubmit: (values) => {
            console.log("Updated User Values:", values);
            // Add API call here
        }
    });




    async function fecthData() {




        const requestOptions = {
            redirect: "follow"
        };

        const res2 = await fetch(appVars.api_url + "/users/g/" + id, requestOptions);
        res2.json()
            .then((response) => {
                setUser(response[0]);
                //  console.log(response);
            })
            .catch((error) => console.error(error));

        const res = await fetch(appVars.api_url + "/tagmanagers", requestOptions);
        res.json()
            .then((response) => {

                setowners(_.filter(response, { owner: id }));
                setsupport(_.filter(response, { support: id }));
                console.log(response);
            })
            .catch((error) => console.error(error));


    }

    useEffect(() => {
        fecthData();
        return () => {

        };
    }, []);

    return (
        <Box component="form" onSubmit={formik.handleSubmit}>
            <Toolbar sx={{ background: 'linear-gradient(90deg,rgba(0,0,0,1) 0%, rgba(255,87,34,1) 97% )' }}>
                <Typography color="white" variant="h6" component="div" sx={{ flexGrow: 1 }}>User Settings</Typography>
                <Button sx={{ mr: { md: 2 } }} variant="contained" color="success" type="submit">Save</Button>
                <Button variant="contained" color="error">Cacel</Button>

            </Toolbar>

            <Box sx={{ p: 1, gap: 1, display: 'flex' }}>

                <Box sx={{
                    width: {
                        md: '20%'
                    }
                }}>
                    <Card>
                        {(user ?
                            <CardContent sx={{ textAlign: "left" }}>
                                <Avatar
                                    src={appVars.api_url + '/imageFiles/' + user.userid + '.png'}
                                    sx={{ width: 100, height: 100 }}
                                />
                                <Typography variant="h5" sx={{ mt: 2 }}>
                                    <TextField size="small" margin="dense" id="name" label="Name" variant="outlined" fullWidth {...formik.getFieldProps('name')} />
                                </Typography>
                                <Typography color="text.secondary">
                                    <TextField size="small" margin="dense" id="email" label="Email" variant="outlined" fullWidth {...formik.getFieldProps('email')} />
                                </Typography>
                                <Typography variant="subtitle1" sx={{ mt: 1, fontWeight: "bold" }}>
                                    <TextField label="Agency" component={AgencyListing} fullWidth id="client" name='client' size="small" {...formik.getFieldProps('client')} />
                                </Typography>
                            </CardContent>
                            : '')}
                    </Card>
                </Box>
                <Box sx={{
                    width: {
                        md: '80%'
                    },
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2
                }}>

                    {/* owners Table */}
                    <Typography variant='h5'>Owned</Typography>
                    {(owners ?
                        <Box sx={{
                            gap: 1,
                            display: {
                                md: 'flex',
                                xs: 'block'
                            }
                        }} >
                            <Card sx={{ width: '50%', border: '1px solid #c4c4c4' }}>
                                <CardHeader title={"Active"} />
                                {_.filter(owners, { status: 1 }).map((entry, index) => (
                                    <Box sx={{ p: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', background: index % 2 === 1 ? '#fff' : lightenHexColor(theme.palette.secondary.main, 50) }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row' }} >
                                            <Link sx={{
                                                textDecoration: 'none'
                                            }} href={"/ss/" + entry.id} >
                                                <Typography color={(index % 2 === 0 ? "textDisabled" : "textDisabled")}  >{entry.name}</Typography>
                                            </Link>
                                        </Box>
                                        <Box sx={{ gap: 2, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >
                                            <img style={{ maxWidth: 100 }} src={appVars.api_url + '/imageFiles/' + entry.vertical + '.png'} alt='' />
                                        </Box>
                                    </Box>

                                ))}
                            </Card>

                            <Card sx={{ width: '50%', border: '1px solid #c4c4c4' }}>
                                <CardHeader title={"Inactive"} />
                                {_.filter(owners, { status: 0 }).map((entry, index) => (
                                    <Box sx={{ p: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', background: index % 2 === 1 ? '#fff' : lightenHexColor(theme.palette.secondary.main, 50) }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row' }} >
                                            <Link sx={{
                                                textDecoration: 'none'
                                            }} href={"/ss/" + entry.id} >
                                                <Typography color={(index % 2 === 0 ? "textDisabled" : "textDisabled")}  >{entry.name}</Typography>
                                            </Link>
                                        </Box>
                                        <Box sx={{ gap: 2, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >
                                            <img style={{ maxWidth: 100 }} src={appVars.api_url + '/imageFiles/' + entry.vertical + '.png'} alt='' />
                                        </Box>
                                    </Box>

                                ))}
                            </Card>
                        </Box>

                        : <LinearProgress />)}
                    <Typography variant='h5'>Supported</Typography>
                    {(support ?
                        <Box sx={{
                            gap: 1,
                            display: {
                                md: 'flex',
                                xs: 'block'
                            }
                        }} >
                            <Card sx={{ width: '50%', border: '1px solid #c4c4c4' }}>
                                <CardHeader title={"Active"} />
                                {_.filter(support, { status: 1 }).map((entry, index) => (
                                    <Box sx={{ p: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', background: index % 2 === 1 ? '#fff' : lightenHexColor(theme.palette.secondary.main, 50) }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row' }} >
                                            <Link sx={{
                                                textDecoration: 'none'
                                            }} href={"/ss/" + entry.id} >
                                                <Typography color={(index % 2 === 0 ? "textDisabled" : "textDisabled")}  >{entry.name}</Typography>
                                            </Link>
                                        </Box>
                                        <Box sx={{ gap: 2, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >
                                            <img style={{ maxWidth: 100 }} src={appVars.api_url + '/imageFiles/' + entry.vertical + '.png'} alt='' />
                                        </Box>
                                    </Box>

                                ))}
                            </Card>

                            <Card sx={{ width: '50%', border: '1px solid #c4c4c4' }}>
                                <CardHeader title={"Inactive"} />
                                {_.filter(support, { status: 0 }).map((entry, index) => (
                                    <Box sx={{ p: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', background: index % 2 === 1 ? '#fff' : lightenHexColor(theme.palette.secondary.main, 50) }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row' }} >
                                            <Link sx={{
                                                textDecoration: 'none'
                                            }} href={"/ss/" + entry.id} >
                                                <Typography color={(index % 2 === 0 ? "textDisabled" : "textDisabled")}  >{entry.name}</Typography>
                                            </Link>
                                        </Box>
                                        <Box sx={{ gap: 2, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >
                                            <img style={{ maxWidth: 100 }} src={appVars.api_url + '/imageFiles/' + entry.vertical + '.png'} alt='' />
                                        </Box>
                                    </Box>

                                ))}
                            </Card>
                        </Box>

                        : <LinearProgress />)}

                </Box>
            </Box>
        </Box>
    );
};

ProfilePage.propTypes = {

};

export default ProfilePage;
