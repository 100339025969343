import React, { useEffect, useState } from 'react';
import { Avatar, Button, CardActions, CardHeader, ButtonGroup, Card, CardActionArea, CardMedia, Typography, Box, Link } from '@mui/material';
import styled, { ThemeProvider } from 'styled-components';
import { createTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Loading from '../../../no-screenshot.png';
import { Assessment, Edit } from '@mui/icons-material';
import { lightenHexColor } from "../../../dwi-functions";
import { FaUsers } from "react-icons/fa6";
import themeFile from '../../../theme.json';
import { AvatarGroup } from '@mui/lab';
import appVars from '../../../appVars.json';

// Styled components
const StyledCard = styled(Card)`
  max-width: 100%;
`;

const StyledCardHeader = styled(CardHeader)`
  background: ${({ theme }) => theme.palette.secondary.light};
  color: #ffffff;
  display: ${({ hideHeader }) => (hideHeader ? 'none' : 'flex')};
`;

const MediaContainer = styled(CardMedia)`
  width: 100%;
  height: 200px;
`;

const StyledCardActions = styled(CardActions)`
  background: ${({ theme }) => lightenHexColor(theme.palette.secondary.light, 30)};
  display:flex;
`;

const StyledButtonGroup = styled(ButtonGroup)`
  flex: 1;
`;

const StyledButton = styled(Button)`
 background: ${({ theme }) => theme.palette.primary.light};
 color: #ffffff;
`;

const Screenshot = (props) => {
  const [imageName, setImageName] = useState(`${appVars.api_url}/imageFiles/${props.item.id}.jpeg`);
  const [hideHeader, setHideHeader] = useState(props.hideHeader);
  const [hideFooter, setHideFooter] = useState(props.hideFooter);

  const handleImageError = () => {
    setImageName(Loading);
  };

  const theme = createTheme(
    props.themeColor
      ? {
        palette: {
          primary: { main: props.themeColor.primary },
          secondary: { main: props.themeColor.secondary },
        },
      }
      : themeFile
  );

  const FetchData = () => {
    fetch(`${appVars.api_url3}/screenshot?d=${props.item.siteUrl}&id=${props.item.id}`)
      .then((response) => response.json())
      .then((data) => setImageName(data.file))
      .catch(() => console.log('Error loading image'));
  };

  useEffect(() => {
    setHideHeader(props.hideHeader);
    setHideFooter(props.hideFooter);
  }, [props.hideHeader, props.hideFooter]);

  return (
    <ThemeProvider theme={theme}>
      <StyledCard variant="outlined">
        <StyledCardHeader

          avatar={
            <AvatarGroup max={3} spacing="small">
              <Avatar variant="circular" src={`${appVars.api_url}/imageFiles/${props.item.owner}.png`} title={props.item.owner} />
              <Avatar variant="circular" src={`${appVars.api_url}/imageFiles/${props.item.support}.png`} title={props.item.support} />

            </AvatarGroup>
          }
          hideHeader={hideHeader}
          title={
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }} >
              <Typography variant="body1">{props.item.name}</Typography>

            </Box>
          }
        />
        <CardActionArea>
          <MediaContainer
            title={props.clientname}
            onClick={FetchData}
            component="img"
            alt={props.item.name}
            image={imageName}
            onError={handleImageError}
          />
        </CardActionArea>
        {!hideFooter && (
          <StyledCardActions>
            <Box style={{ flex: 1, justifyContent: 'space-between', flexDirection: 'row', display: 'flex' }}>
              <StyledButtonGroup color="secondary" variant="text">
                {props.admintoken.token >= 8 && (
                  <StyledButton target="_blank" href={`/ss/${props.item.id}`}>
                    <Edit />
                  </StyledButton>
                )}
                {props.admintoken.token >= 8 && (
                  <StyledButton target="_blank" href={`/cl/${props.item.client}`}>
                    <FaUsers />
                  </StyledButton>
                )}
                <StyledButton target="_blank" href={`/mr/${props.item.id}`}>
                  <Assessment />
                </StyledButton>
              </StyledButtonGroup>
              <AvatarGroup spacing="medium" max={2}>
                <Avatar sx={{ background: 'lightgrey' }} variant="rounded" src={`${appVars.api_url}/imageFiles/${props.item.donationtype}.png`} />
                <Avatar variant="rounded" src={`${appVars.api_url}/imageFiles/${props.item.sitetype}.png`} />
              </AvatarGroup>
            </Box>
          </StyledCardActions>
        )}
      </StyledCard>
    </ThemeProvider>
  );
};

Screenshot.propTypes = {
  data: PropTypes.array,
};

export default Screenshot;
