import { useState, useEffect } from "react";
import {
    Alert,
    Typography,
    Button,
    Snackbar,
    TextField,
    MenuItem,
    Box,
    IconButton,
    Divider,

} from "@mui/material";

import { Add, Delete } from "@mui/icons-material";
import appVars from '../../appVars.json';




const id = sessionStorage.getItem('cid')

const Trackers = (props) => {

    const [hasError, setErrors] = useState(false);
    const [copySuccessShow, setCopySuccessShow] = useState(false);
    const [users, setUsers] = useState([]);
    const [trackers, setTrackers] = useState([]);
    const [event, setEvent] = useState("");
    const [copySuccess, setCopySuccess] = useState("");





    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setErrors(false);
        setCopySuccessShow(false);
    };

    async function fetchData() {
        const res = await fetch(appVars.api_url + "/gettrackers/" + id);
        res
            .json()
            .then((res) => {
                setUsers(res);
                //  console.log(appVars.api_url + "/gettrackers/" + id);

            })
            .catch((err) => setErrors(true));

        const res2 = await fetch(appVars.api_url + "/gettrackers/all");
        res2
            .json()
            .then((res2) => {
                setTrackers(res2);
                //  console.log(appVars.api_url + "/gettrackers/" + id);

            })
            .catch((err) => setErrors(true));



    }



    useEffect(() => {

        fetchData();



        // Cleanup on unmount to avoid memory leaks
        return () => {

        };
    }, []);


    return (
        <div>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                pt: 2,
                gap: 3
            }} >



                <Box item xl={12} md={12}>
                    <Typography variant="h5">Active Events</Typography>
                    <Divider sx={{ mb: 2 }} />
                    {users.map((entry, index) => {
                        //console.log('Trackers', entry)
                        return (
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', background: index % 2 === 0 ? '#c7c7c7' : "#ffffff", pl: 1, pr: 1, borderRadius: 1 }}>
                                <Typography gutterBottom variant="body2">{entry.trackername}</Typography>
                                <IconButton color="error" onClick={(e) => {
                                    const myHeaders = new Headers();
                                    myHeaders.append("Content-Type", "application/json");
                               
                                    const raw = JSON.stringify({
                                        "pkRecId": entry.pkRecId,
                                        "cid": id
                                    });

                                    const requestOptions = {
                                        method: "POST",
                                        headers: myHeaders,
                                        body: raw,
                                        redirect: "follow"
                                    };

                                    //console.log('re',raw)
                                    //return false;
                                    fetch(appVars.api_url+"/tracker/d", requestOptions)
                                        .then((response) => response.text())
                                        .then((result) => fetchData())
                                        .catch((error) => console.error(error));


                                }} ><Delete /></IconButton>
                            </Box>
                        )
                    })}

                </Box>
                <Box sx={{
                    display: 'flex',
                    gap: 2
                }} >

                    <TextField
                        select
                        fullWidth
                        id="trackername"
                        name="trackername"
                        label="Event to Track"
                        variant="standard"
                        size="small"
                        InputLabelProps={{
                            shrink: true
                        }}
                        onChange={(e) => {
                            setEvent(e.target.value);
                        }}

                    >
                        {trackers.map((option) => (
                            <MenuItem value={option.pkRecId}>{option.trackername}</MenuItem>
                        ))}

                    </TextField>



                    <Button variant="outlined" size="small" color="primary" onClick={(e) => {
                        var data = {
                            "pkRecId": "1",
                            "clientid": id,
                            "trackname": event
                        };

                        const re3 = fetch(appVars.api_url + "/tracker/add",
                            {
                                method: "POST",
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify(data)
                            }
                        );
                        re3
                            .then((res) => {
                                // console.log(res);
                                fetchData();
                                setCopySuccess("Event Added");
                                setCopySuccessShow(true);
                            });



                    }}
                    ><Add /></Button>
                </Box>
            </Box>




            <Snackbar open={hasError} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    The data can not be pulled at this time.
                    <Button color="primary" onClick={window.location.reload}>
                        Refresh
                    </Button>
                </Alert>
            </Snackbar>

            <Snackbar
                open={copySuccessShow}
                autoHideDuration={3000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity="success">
                    {copySuccess}
                </Alert>
            </Snackbar>
        </div>

    );
};

Trackers.propTypes = {

};

export default Trackers;
