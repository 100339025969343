import { Box, Typography, Link, useTheme } from '@mui/material';
import React, { useState, useEffect } from 'react';
import appVars from '../../../../../appVars.json';


const ClientDetailEcommerce = (props) => {

    const [client, setClient] = useState();
    const theme = useTheme();

    //console.log('props',props);



    const fetchData = async () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "cid": props.cid,
            "sid": props.sid
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        const res = await fetch(appVars.api_url + "/sites/donation/client", requestOptions);
        res.json()
            .then((response) => {
                if (response[0]) {
                    //console.log(response[0]);
                    setClient(response);
                }

            })
            .then((result) => setClient(result[0]))
            .catch((error) => console.error(error));


    }



    useEffect(() => {
        fetchData();
        return () => {

        };
    }, []);



    return (

        (client ?

            <Box boxShadow={'0px 0px 25px -15px ' + theme.palette.secondary.main} borderRadius={2} border={"1px solid #c7c7c7"} sx={{

                p: { md: 2, xs: 1 },
                display: 'flex',
                flexDirection: 'column',
                mb: { md: 2 }
            }}>
                <Typography sx={{
                    borderBottom: '1px solid #c7c7c7'
                }} variant='h6' gutterBottom>{props.clientname}</Typography>
                {client.map((entry) => {
                    console.log('Entry', entry)
                    return (
                        <Box><Link href={"/ss/" + entry.id} ><Typography color={(entry.status ? "primary" : "textDisabled")} gutterBottom >{entry.name} {(entry.status ? "" : "(inactive)")}</Typography></Link></Box>
                    );

                })}
            </Box>
            : '')
    );
};

ClientDetailEcommerce.propTypes = {

};

export default ClientDetailEcommerce;
