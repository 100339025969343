import  { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    TextField,
    Typography,
    Breadcrumbs,
    Link,
    Box,
    MenuItem,
    FormControl,
    Snackbar,
    Toolbar, 
    IconButton,
    ButtonGroup
} from '@mui/material';

import Alert from '@mui/material/Alert';
import { styled, useTheme } from '@mui/system';
import appVars from '../../../../appVars.json';
import { Formik, Field, Form } from 'formik';
import {  Save } from '@mui/icons-material';
import ClipboardJS from "clipboard";
import { useToken } from '../../../../Login/TokenProvider';
import ClientDetailEcommerce from './client-detail-ecommerce';
import { FcDocument } from "react-icons/fc";
import { AiOutlineJavaScript } from "react-icons/ai";

// Styled components using Material-UI's `styled`
const Root = styled('div')(() => ({
    width: '100%',
}));

const Content = styled(Box)(({ theme }) => ({
    flexGrow: 1,
    width: '100%',
    top: 100,
    [theme.breakpoints.up('sm')]: {
        marginTop: 0,
    },
}));


const StyledBreadcrumbs = styled(Breadcrumbs)(() => ({
    flex: 1
}));



const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
  background: linear-gradient(90deg, rgba(74,151,220,1) 0%, rgba(0,0,0,1) 97%);
`;


const MyInput = ({ field, form, ...props }) => {
    return <TextField sx={{ mb: 0 }} variant="outlined" shrink {...field} {...props} />;
};

const MySelectDonationTypes = ({ field, form, ...props }) => {
    return (
        <TextField
            sx={{ mb: 0 }}
            select
            variant="outlined"
            size="small"
            label="Revenue Trackable"
            fullWidth
            {...field}
            {...props}
        >
            <MenuItem value="">Select One</MenuItem>
            <MenuItem value="donation">eCommerce/Donation</MenuItem>
            <MenuItem value="website">Website</MenuItem>
        </TextField>
    );
};

const MyRevenueSelect = ({ field, form, ...props }) => {
    return (
        <TextField
            select
            variant="outlined"
            size="small"
            label="Revenue Trackable"
            fullWidth
            {...field}
            {...props}
        >
            <MenuItem value="">Select One</MenuItem>
            <MenuItem value="true">Yes</MenuItem>
            <MenuItem value="false">No</MenuItem>
        </TextField>
    );
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};



const getLastItem = (thePath) => thePath.substring(thePath.lastIndexOf('/') + 1);
const id = getLastItem(window.location.href);

const Ecommerce = () => {
    const theme = useTheme();
    const token = useToken();
    const admintoken = token.token;

    const [copySuccessShow, setCopySuccessShow] = useState(false);
    const [clients, setClients] = useState([]);
    const [copySuccess, setCopySuccess] = useState('');
    const [saveOpen, setSaveOpen] = useState(false);
    const [saveMsg, setSaveMsg] = useState('');
    const [saveSeverity, setSeverity] = useState('success');
    const [hasError, setErrors] = useState({ status: false });
    const [site, setSite] = useState(false);
    const [clientUsers, setClientUsers] = useState([{ Client: 'Test', ClientID: '1' }]);
    const [payloadTemplate, setPayloadTemplate] = useState({});

    const handlePayloadChange = (event, setFieldValue) => {
        const updatedPayloadTemplate = {
            ...payloadTemplate,
            [event.target.id]: event.target.value,
        };

        setPayloadTemplate(updatedPayloadTemplate);

        // Update Formik's `payload` field with the stringified version
        setFieldValue('payload', updatedPayloadTemplate);
    };


    const handleClose = () => {
        setErrors({ status: false });
        setCopySuccessShow(false);
        setSaveOpen(false);
    };

    const fetchData = async () => {
        try {
            const res = await fetch(appVars.api_url + `/sites/donation/${id}`);
            const siteData = await res.json();
            setSite(siteData[0]);
            setPayloadTemplate(JSON.parse(siteData[0].payload));

            const res2 = await fetch(appVars.api_url + `/tagmanagers/ecommerce/${id}`);
            const clientUserData = await res2.json();
            setClientUsers(clientUserData);

            const clientResponse = await fetch(appVars.api_url + '/clients');
            const clientData = await clientResponse.json();
            setClients(clientData);


        } catch (error) {
            console.error('Error fetching data:', error);
        }






    };

    useEffect(() => {
        if (!site) {
            fetchData();

            const clipboard = new ClipboardJS('#gtmcopy, #fbcopy,#copy-script,#copy-event');
            clipboard.on('success', (e) => {
                setCopySuccess(e.action + ': ' + e.text);
                setCopySuccessShow(true);
                e.clearSelection();
            });
        }
    }, [site]);

    return (
        <Root>
            {site && (
                <Formik
                    initialValues={site}
                    onSubmit={async (values) => {
                        delete values.updatedAt;
                        delete values.createdAt;
                        const response = await fetch(appVars.api_url + '/sites/donation/update', {
                            method: 'PUT',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify(values),
                        });
                        const result = await response.json();
                        if (result.status === 'eSite Updated') {
                            setSaveOpen(true);
                            setSeverity('success');
                            setSaveMsg(`${site.site} Record Updated`);
                        } else {
                            setSaveOpen(true);
                            setSeverity('error');
                            setSaveMsg('');
                        }
                    }}
                    enableReinitialize
                >
                    <Form id="clientForm">

                        <Content>


                            <StyledToolbar>
                                <StyledBreadcrumbs aria-label="breadcrumb">
                                    <Link color="#ffffff" href="/">Dashboard</Link>
                                    <Link color="#ffffff" href="/control">Control Panel</Link>
                                    <Link color="#ffffff" href="/control/?tab=1">Site Settings</Link>
                                    <Typography color="textPrimary">{site.site}</Typography>
                                </StyledBreadcrumbs>
                                <Typography sx={{ mr: 2, display: { xs: 'none', md: 'block' } }} color='#ffffff' >{site.pkRecID}</Typography>
                                <ButtonGroup sx={{ background: 'lightgrey', mr: 2 }} variant="contained" >
                                    <IconButton size='small' >
                                        <FcDocument />
                                    </IconButton>
                                    <IconButton size={'small'}>
                                        <AiOutlineJavaScript />
                                    </IconButton>
                                </ButtonGroup>
                                <Box sx={{ gap: { md: 1 }, display: 'flex', alignItems: 'center' }} >
                                    <Button startIcon={<Save />} size='small' type="submit" variant="contained" color="success">Save</Button>
                                    <Button startIcon={<Save />} size='small' type="reset" variant="contained" color="error">Cancel</Button>
                                </Box>

                            </StyledToolbar>


                            <Box sx={{
                                display: { md: 'flex' },
                                gap: 2,
                                p: 2,
                                width: '100%'
                            }} >
                                <Box boxShadow={'0px 0px 25px -15px ' + theme.palette.secondary.main} borderRadius={2} border={"1px solid #c7c7c7"} sx={{ width: { md: '100%' }, display: { xs: 'flex' } }} p={2} gap={2} flexDirection={'column'} >
                                    <img style={{ width: '100%' }} src={appVars.api_url + '/imageFiles/' + site.pkRecID + '.png'} onError={(e) => {
                                        e.src = "https://placehold.co/500x300";
                                    }} alt='' />
                                    <FormControl sx={{ display: 'none' }} >
                                        <Field component={MyInput} variant="outlined" size="small" id="pkRecID" disabled name="pkRecID" label="Primary ID" fullWidth />
                                    </FormControl>

                                    <Field component={MyInput} variant="outlined" size="small" id="site" name="site" label="Site Name" fullWidth />

                                    <FormControl fullWidth>
                                        <Field component={MySelectDonationTypes} id="type" name="type" label="Site Type" fullWidth />
                                    </FormControl>


                                    <Box display={'flex'} gap={2} >
                                        <Field component={MyInput} variant="outlined" size="small" id="link" name="link" label="Link to Documentation" fullWidth />

                                    </Box>


                                    <Box display={'flex'} gap={2} >
                                        <Field component={MyInput} variant="outlined" size="small" id="scriptName" name="scriptName" label="Script Name" fullWidth />


                                    </Box>


                                    <FormControl fullWidth>
                                        <Field component={MyRevenueSelect} id="trackable" name="trackable" label="Is It Trackable" fullWidth />
                                        
                                    </FormControl>



                                </Box>
                                <Box sx={{ width: { md: '100%' }, display: { md: 'flex', xs: 'none' } }} p={2} boxShadow={'0px 0px 25px -15px ' + theme.palette.secondary.main} borderRadius={2} border={"1px solid #c7c7c7"} gap={2} flexDirection={'column'} >
                                    {Object.entries(payloadTemplate).map(([key, value]) => (
                                        <Field name={`payloadTemplate.${key}`}>
                                            {({ field, form }) => (
                                                <TextField
                                                    {...field}
                                                    id={key}
                                                    label={key}
                                                    value={value}
                                                    onChange={(e) => handlePayloadChange(e, form.setFieldValue)}
                                                    fullWidth
                                                />
                                            )}
                                        </Field>
                                    ))}
                                    <FormControl fullWidth sx={{
                                        display: 'none'
                                    }} >
                                        <Field component={MyInput} variant="outlined" size="small" id="payload" name="payload" label="Payload" fullWidth />
                                    </FormControl>
                                </Box>
                                <Box sx={{ width: { md: '50%' } }} display={'flex'} gap={0} flexDirection={'column'}>
                                    {clients.map((entry) => {
                                        // console.log('entry',entry)
                                        return (
                                            <Box>
                                                <ClientDetailEcommerce {...entry} sid={id} cid={entry.clientId} />
                                            </Box>
                                        )
                                    })}
                                </Box>
                            </Box>



                        </Content>
                    </Form>
                </Formik>
            )}
            <Snackbar open={hasError.status} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">Error: {hasError.msg}</Alert>
            </Snackbar>
            <Snackbar open={saveOpen} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={saveSeverity}>{saveMsg}</Alert>
            </Snackbar>
            <Snackbar open={copySuccessShow} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">{copySuccess}</Alert>
            </Snackbar>
        </Root>
    );
};

Ecommerce.propTypes = {};

export default Ecommerce;
